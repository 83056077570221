import React, { useState, useEffect, createContext } from 'react';
import InfoListTitle from '../../Components/InfoListComponents/InfoListTitle/InfoListTitle';
import InfoListNavigation from '../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import {
  FaUser,
  FaUserCheck,
  FaUserLargeSlash,
  FaCircleXmark,
  FaTrash,
  FaChevronDown,
  FaChevronUp,
  FaSquareCheck,
  FaSquareXmark
} from "react-icons/fa6";
import { FaCheckCircle, FaPencilAlt, FaCheck } from 'react-icons/fa';
import { IoMdSettings } from "react-icons/io";
import Loading from '../../Components/Loading/Loading';
import LoadingForBtn from '../../Components/Loading/LoadingForBtn/LoadingForBtn';
import axios from 'axios';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import ToastComp from '../../Components/Toast/ToastComp';
import { handleErrors } from '../../Axios/Axios';
import { CiRead } from "react-icons/ci";
import { CiUnread } from "react-icons/ci";
import AddnewsImg from "../../Assets/addnewsImg.png";
import { Height } from '@mui/icons-material';
import uploadImg from "../../Assets/uploadnewImg.svg";
import './News.css';
import { FaUpload } from 'react-icons/fa';
import { GiSettingsKnobs } from "react-icons/gi";
import { VscSettings } from "react-icons/vsc";
import descnewsImg from '../../Assets/mdi_progress-tick.svg';
import closeBtn from '../../Assets/Group (2).svg'
import { IoCloseCircleOutline } from "react-icons/io5";
import MyCarousal from '../../Components/Carousal/MyCarousal';
import { urlVariable } from '../../Axios/Axios';
import { RiAddCircleFill } from "react-icons/ri";
import { BiShow } from "react-icons/bi";
import { FaCity } from "react-icons/fa";
import { TbNews } from "react-icons/tb";

export default function News() {

  const [totPage, setTotPage] = useState()
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  // Toast State
  const [showToast, setShowToast] = useState(false)
  const [toastTitle, setToastTitle] = useState('')
  const [toastBody, setToastBody] = useState('')
  const [toastIcon, setToastIcon] = useState()
  const [toastColor, setToastColor] = useState('')

  const [loadingForBtn, setLoadingForBtn] = useState(false)
  const [imgLoading, setImgLoading] = useState(false)
  const [viewImg, setViewImg] = useState(false)

  // Modal States
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showDescModal, setShowDescModal] = useState(false)

  // Flag State
  const [flag, setFlag] = useState(false)

  // Get All Users
  const [allNews, setAllNews] = useState([]);
  const [allCities, setAllCities] = useState({});
  const [newsTitle, setNewsTitle] = useState();
  const [newsDesc, setNewsDesc] = useState("");
  const [newsImgId, setNewsImgId] = useState("");
  const [isNewsAdd, setIsNewsAdd] = useState(false);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return ' ';
    return Intl.DateTimeFormat('fa-IR').format(timestamp)
  };

  const [newsImgSource, setNewsImgSource] = useState("")


  function changeNewsImgHandler(e) {

    setImgLoading(true);

    const formData = new FormData()
    formData.append('attachedFile', e.target.files[0]);




    axios.post(`${urlVariable}/admin/uploadFile`,


      formData,

      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
        }
      }
    )
      .then(res => {

        setToastTitle('عملیات موفق')
        setToastBody('عکس شما با موفقیت بارگذاری شد')
        setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
        setToastColor('var(--color-1)')
        setShowToast(true)
        setNewsImgSource(`${urlVariable}/${res.data.data.source}`);
        if (res.status === 200) {
          setNewsImgId(res.data.data._id.$oid);
        }
        setImgLoading(false);

      })
      .catch(err => {

        handleErrors(err)
        setToastTitle('عملیات ناموفق')
        setToastBody(err.response.data.msg)
        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
        setToastColor('var(--danger-color)')
        setShowToast(true)
        setImgLoading(false);
      })
  }

  const [isConfirmed, setIsConfirmed] = useState(true)
  useEffect(() => {

    setLoading(true);
    setNewsDesc("")
    setNewsTitle("")
    axios.get(`${urlVariable}/admin/news/get_list`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
        },
        params: {
          "is-confirmed": isConfirmed,
          limit: 10,
          page: page,
        }
      }
    )
      .then(res => {
        if (res.status === 200) {
          setAllNews(res.data.data)
          console.log(res);
          setTotPage(res.data.total_pages);
        }
        setLoading(false);

      })
      .catch(err => {
        handleErrors(err)
        if (err.message === "Network Error") {
          setShowToast(true)
          setToastTitle('عملیات ناموفق')
          setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید...!')
          setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
          setToastColor('var(--danger-color)')
          setIsLoading(true);

        }
        else if (err.response && err.response.status <= 499) {
          setShowToast(true)
          setToastTitle("عملیات ناموفق")
          setToastBody(err.response.data.msg)
          setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
          setToastColor('var(--danger-color)')

        }
        else {
          if (err.response && err.response.status >= 500) {
            setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')


          } else {

            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastBody("خطا در بارگیری اطلاعات")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')

          }
        }
        setLoading(false);
        setLoadingForBtn(false);
      })

  }, [isNewsAdd, isConfirmed, flag, page])

  useEffect(() => {
    axios.get(`${urlVariable}/api/v1/groups/city`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
        }
      })
      .then(res => {
        setAllCities(res.data.data)

      })
      .catch(err => {
        handleErrors(err)
        if (err.message === "Network Error") {
          setShowToast(true)
          setToastTitle('عملیات ناموفق')
          setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید...!')
          setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
          setToastColor('var(--danger-color)')
          setIsLoading(true);

        }
        else if (err.response && err.response.status <= 499) {
          setShowToast(true)
          setToastTitle("عملیات ناموفق")
          setToastBody(err.response.data.msg)
          setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
          setToastColor('var(--danger-color)')

        }
        else {
          if (err.response && err.response.status >= 500) {
            setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')
          } else {

            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastBody("خطا در بارگیری اطلاعات")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')

          }
        }
        setLoading(false);
        setLoadingForBtn(false);
      })
  }, [])


  // Safely access allCities[0].name by checking if allCities[0] exists
  const [targetDesc, setTargetDesc] = useState({});
  const AddNews = () => {
    setNewsImgSource("");
    setCity("");

    setLoadingForBtn(true);
    const temp2 = {
      title: newsTitle,
      description: newsDesc,
      image: newsImgId ? newsImgId : null,
      city: cityID
    };
    console.log(temp2);

    axios.post(`${urlVariable}/admin/news/create`, temp2, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
      }
    }).then(res => {
      setShowAddModal(false);
      setIsNewsAdd(true);
      setToastTitle('عملیات موفق')
      setToastBody('خبر شما با موفقیت ثبت شد')
      setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
      setToastColor('var(--color-1)')
      setShowToast(true)
      setLoadingForBtn(false);
      setIsNewsAdd(!isNewsAdd);

    })
      .catch(err => {

        handleErrors(err)
        handleErrors(err)
        console.log(err);

        if (err.message === "Network Error") {

          setShowToast(true)
          setToastTitle('عملیات ناموفق')
          setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید...!')
          setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
          setToastColor('var(--danger-color)')

        }
        else if (err.response && err.response.status <= 499) {
          setShowToast(true)
          setToastTitle("عملیات ناموفق")
          setToastBody(err.response.data.msg)
          setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
          setToastColor('var(--danger-color)')

        } else {
          if (err.response && err.response.status >= 500) {
            setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')


          } else {
            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastBody("خطا در بارگیری اطلاعات")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')


          }
        }
        setLoadingForBtn(false);




      })
  }
  useEffect(() => {
    if (totPage < page && totPage > 0)
      setPage(totPage);
  }, [totPage, page])

  // Delete User
  const [userId, setUserId] = useState()
  function deleteHandle(deleted) {
    setShowDeleteModal(true);
    setUserId(deleted._id.$oid);
  }
  function deleteNews() {
    const temp3 = {
      news_id: userId,
    };
    setLoadingForBtn(true);


    axios.delete(`${urlVariable}/admin/news/delete`, {
      data: temp3,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
      },
    }

    )
      .then(res => {
        setShowDeleteModal(false);

        setIsNewsAdd(true);
        setToastTitle('عملیات موفق')
        setToastBody('خبر شما با موفقیت حذف شد')
        setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
        setToastColor('var(--color-1)')
        setShowToast(true)
        setLoadingForBtn(false)

        setIsNewsAdd(!isNewsAdd);
      }
      )
      .catch(err => {

        handleErrors(err)
        if (err.message === "Network Error") {

          setShowToast(true)
          setToastTitle('عملیات ناموفق')
          setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید...!')
          setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
          setToastColor('var(--danger-color)')
          setLoadingForBtn(false)

        }
        else if (err.response && err.response.status <= 499) {
          setShowToast(true)
          setToastTitle("عملیات ناموفق")
          setToastBody(err.response.data.msg)
          setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
          setToastColor('var(--danger-color)')

        } else {
          if (err.response && err.response.status >= 500) {
            setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')


          } else {
            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastBody("خطا در بارگیری اطلاعات")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')


          }
        }
      }
      )
  }

  // Get Detail
  const [newsId, setNewsId] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [confirmedState, setConfirmedState] = useState()
  const [authorNum, setAuthorNum] = useState('')
  const [authorName, setAuthorName] = useState('')
  const [authorRole, setAuthorRole] = useState('')
  const [newsDate, setNewsDate] = useState()

  const ShowDetails = (selectedNews) => {
    const temp = (selectedNews.image && selectedNews.image[0]) ? selectedNews.image[0].source : "";
    temp ? setNewsImgSource(`${urlVariable}/${temp}`) : setNewsImgSource("")
    // newsImgSource.push(`${urlVariable}/${selectedNews.image[0].source}`)
    setShowDescModal(true);
    setNewsId(selectedNews._id.$oid)
    setTitle(selectedNews.title)
    setDescription(selectedNews.description)
    setConfirmedState(selectedNews.is_confirmed)
    setAuthorNum(selectedNews.auther_detail?.phoneNumber ?? '-')
    setAuthorName(selectedNews.auther_detail?.name ?? '-')
    setAuthorRole(selectedNews.auther_detail?.role ?? selectedNews.auther_detail?.userType)
    setNewsDate(selectedNews.createdAt)
    setNewsImgId(selectedNews.image && selectedNews.image[0] ? selectedNews.image[0]._id.$oid : "");
    setCity(selectedNews.city && selectedNews.city[0] ? selectedNews.city[0].name : "-");
  }

  function editNewsApiHandler() {
    setLoadingForBtn(true);
    setNewsImgSource("");
    setCity("");
    const updatedNews = {
      _id: newsId,
      title: title,
      description: description,
      is_confirmed: confirmedState,
      image: newsImgId ? newsImgId : ""
    }

    console.log(updatedNews);

    axios.put(`${urlVariable}/admin/news/edit`,
      updatedNews,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
        }
      }
    )
      .then(res => {
        setToastTitle('عملیات موفق')
        setToastBody('خبر شما با موفقیت ویرایش شد')
        setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
        setToastColor('var(--color-1)')
        setShowToast(true)
        setFlag(prev => !prev)
        setLoadingForBtn(false);
        setShowDescModal(false);
        setNewsImgId("");

      })
      .catch(err => {

        handleErrors(err)
        handleErrors(err)
        if (err.message === "Network Error") {

          setShowToast(true)
          setToastTitle('عملیات ناموفق')
          setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید...!')
          setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
          setToastColor('var(--danger-color)')
        }
        else if (err.response && err.response.status <= 499) {
          setShowToast(true)
          setToastTitle("عملیات ناموفق")
          setToastBody(err.response.data.msg)
          setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
          setToastColor('var(--danger-color)')

        } else {
          if (err.response && err.response.status >= 500) {
            setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')


          } else {
            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastBody("خطا در بارگیری اطلاعات")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')


          }
        }
        setLoadingForBtn(false);

      })

  }

  function confirmNewHandler() {


    const updatedConfirmed = {
      _id: newsId,
      is_confirmed: true
    }

    axios.put(`${urlVariable}/admin/news/confirmation`,
      updatedConfirmed,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
        }
      }
    )
      .then(res => {
        setToastTitle('عملیات موفق')
        setToastBody('خبر شما با موفقیت تایید شد')
        setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
        setToastColor('var(--color-1)')
        setShowToast(true)
        setFlag(prev => !prev)
        setShowDescModal(false)
      })
      .catch(err => {
        console.log(err);

        handleErrors(err)
        setToastTitle('عملیات ناموفق')
        setToastBody('خطا در انجام عملیات')
        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
        setToastColor('var(--danger-color)')
        setShowToast(true)
      })
  }

  const [cityList, setCityList] = useState([])
  const [searchCityApi, setSearchCityApi] = useState("")
  const [searchCityShow, setSearchCityShow] = useState("")
  const [showDropdown, setShowDropdown] = useState(false)
  const [cityID, setCityID] = useState('')    // just use for send to api
  const [city, setCity] = useState('')
  const [loadingDropdown, setLoadingDropdown] = useState(false)

  function Dropdown(value) {
    if (showDropdown === value)
      setShowDropdown('')
    else setShowDropdown(value)
    setSearchCityApi("")
    setSearchCityShow("")
  }

  function selectCity(value) {
    setCityID(value._id.$oid)
    setCity(value.name)
    setShowDropdown('')
    setSearchCityApi("")
    setSearchCityShow("")
    console.log(city);

  }

  useEffect(() => {    // get city list api

    setLoadingDropdown(true);
    // setLoadingDropdown(true)
    axios.get(`${urlVariable}/api/v1/groups/city`, {
      params: {
        search: searchCityApi
      }
    })
      .then((res) => {
        setCityList(res.data.data);
        setLoadingDropdown(false)
      })
      .catch(err => {

        handleErrors(err)
        handleErrors(err)
        if (err.message === "Network Error") {

          setShowToast(true)
          setToastTitle('عملیات ناموفق')
          setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید...!')
          setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
          setToastColor('var(--danger-color)')
        }
        else if (err.response && err.response.status <= 499) {
          setShowToast(true)
          setToastTitle("عملیات ناموفق")
          setToastBody(err.response.data.msg)
          setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
          setToastColor('var(--danger-color)')

        } else {
          if (err.response && err.response.status >= 500) {
            setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')


          } else {
            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastBody("خطا در بارگیری اطلاعات")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')


          }
        }
        setLoadingDropdown(false);

      })

  }, [searchCityApi])

  useEffect(() => {

    const delayedSetData = setTimeout(() => {
      setSearchCityApi(searchCityShow)
    }, 800)

    return () => clearTimeout(delayedSetData);

  }, [searchCityShow]);

  if (allNews.length > 0)
    console.log();

  return (
    <>
      <div className="info-list ">
        <Breadcrumbs
          items={[{ name: 'اخبار', link: '/pannel/news' }]}
        />
        <InfoListNavigation
          btntitle='افزودن خبر جدید'
          onShowModal={setShowAddModal}
          setStatus={setIsConfirmed}
          status={isConfirmed}
          items={[
            ['خبرهای تایید شده', true, <FaSquareCheck className='icon' />],
            ['خبرهای تایید نشده', false, <FaSquareXmark className='icon' />]
          ]}
        />

        <div className="info-list-table-section">

          <table className='info-list-table'>
            <thead>
              <tr className='info-list-tr-head'>
                <th>
                  <TbNews style={{ marginLeft: '3px', fontSize: '13px' }} />
                  عنوان خبر
                </th>
                <th className='td-res-1'>
                  <FaCity style={{ marginLeft: '3px', fontSize: '13px' }} />


                  شهر های مربوط
                </th>
                <th className='td-res-1'>
                  <svg style={{ marginLeft: '3px' }} stroke="currentColor" fill="currentColor" strokeWidth=".3" viewBox="0 0 16 16" height="17px" width="17px" xmlns="http://www.w3.org/2000/svg"><path d="M6 9.5C6.93191 9.5 7.71496 10.1374 7.93699 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7455 13.8231 11.9496 13.5899 11.9919L13.5 12L7.93673 12.001C7.71435 12.8631 6.93155 13.5 6 13.5C5.06845 13.5 4.28565 12.8631 4.06327 12.001L2.5 12C2.22386 12 2 11.7761 2 11.5C2 11.2545 2.17688 11.0504 2.41012 11.0081L2.5 11H4.06301C4.28504 10.1374 5.06809 9.5 6 9.5ZM6 10.5C5.44772 10.5 5 10.9477 5 11.5C5 12.0523 5.44772 12.5 6 12.5C6.55228 12.5 7 12.0523 7 11.5C7 10.9477 6.55228 10.5 6 10.5ZM10 2.5C10.9319 2.5 11.715 3.13738 11.937 3.99998L13.5 4C13.7761 4 14 4.22386 14 4.5C14 4.74546 13.8231 4.94961 13.5899 4.99194L13.5 5L11.9367 5.00102C11.7144 5.86312 10.9316 6.5 10 6.5C9.06845 6.5 8.28565 5.86312 8.06327 5.00102L2.5 5C2.22386 5 2 4.77614 2 4.5C2 4.25454 2.17688 4.05039 2.41012 4.00806L2.5 4L8.06301 3.99998C8.28504 3.13738 9.06809 2.5 10 2.5ZM10 3.5C9.44772 3.5 9 3.94772 9 4.5C9 5.05228 9.44772 5.5 10 5.5C10.5523 5.5 11 5.05228 11 4.5C11 3.94772 10.5523 3.5 10 3.5Z"></path></svg>
                  تاریخ
                </th>
                <th>
                  <IoMdSettings style={{ marginLeft: '3px', fontSize: '17px' }} />
                  عملیات
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ?
                <tr>
                  <td colSpan='4'>
                    <Loading />

                  </td>
                </tr> :

                allNews.length ? allNews.map((value, index) => {
                  return (
                    <tr key={index} className='info-list-row'>
                      <td >{value.title}</td>
                      <td className='td-res-1'>{value.city[0] && value.city[0].name ? value.city[0].name : '-'}</td>
                      <td className='td-res-1'>{formatTimestamp(value.createdAt)}</td>

                      <td>
                        <span style={{ color: 'var(--color-1)', cursor: 'pointer' }} onClick={() => ShowDetails(value)}>جزئیات</span>
                        <span style={{ margin: '0 3px' }}>/</span>
                        <span style={{ color: 'var(--danger-color)', cursor: 'pointer' }} onClick={() => deleteHandle(value)}>حذف</span>
                      </td>
                    </tr>
                  )
                })
                  : isLoading ? <tr>
                    <td colSpan="4" style={{
                      textAlign: 'center',
                      padding: '10px 0',
                      backgroundColor: 'var(--bg-color-2)'
                    }}>در حال حاضر خبری نداریم!
                    </td>
                  </tr> : <tr>
                    <td colSpan='4'>
                      <Loading />

                    </td>
                  </tr>
              }
            </tbody>

          </table>


        </div>

        {/* Pagination Section */}
        <div className="pagination-section">
          <button disabled={page === 1} style={page === 1 ? { cursor: 'not-allowed' } : null} onClick={() => setPage(page - 1)} className='pagination-btn'>قبلی</button>
          <div>صفحه {page} از {totPage}</div>
          <button disabled={page >= totPage || !totPage} style={page >= totPage || !totPage ? { cursor: 'not-allowed' } : null} onClick={() => setPage(page + 1)} className='pagination-btn'>بعدی</button>
        </div>

      </div >

      {/* Toast */}
      < ToastComp
        title={toastTitle}
        body={toastBody}
        icon={toastIcon}
        onShowToast={setShowToast}
        showToastValue={showToast}
        toastColor={toastColor}

      />

      {/* Delete Modal */}
      <Modal Modal
        show={showDeleteModal}
        centered

      >
        <Modal.Header>
          <Modal.Title style={{ width: '100%' }}>
            <div className="modal-title-section">
              <span className="modal-title">حذف خبر</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          آیا از حذف خبر اطمینان دارید؟
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none' }}>
          <button className='modal-btn modal-cancle-btn' onClick={() => setShowDeleteModal(false)}>لغو</button>
          <button disabled={loadingForBtn === true} onClick={deleteNews} className='modal-btn modal-red-btn'>

            {loadingForBtn ? <LoadingForBtn /> : <><FaTrash className='icon' />
              حذف</>}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Add Modal */}
      <Modal
        show={showAddModal}
        centered
      >
        <Modal.Header>
          <Modal.Title style={{ width: '100%' }}>
            <p>افزودن خبر</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container style={{ textAlign: 'right', fontWeight: 'normal' }}>
            <Row style={{textAlign : 'center'}}>
              <Col xs={12}>
                <div style={{ display: 'inline-block', position : 'relative' }} className="Groups-details-profile">
                  <label htmlFor="fileInput" style={{
                    cursor: 'pointer',

                  }}>

                    {imgLoading ? <Loading /> : <img

                      src={newsImgSource ? newsImgSource : AddnewsImg}
                      width="90px"
                      height="90px"
                      alt="Profile"


                    />}


                  </label>
                  {!imgLoading ? <BiShow
                    onClick={(e) => {
                      setViewImg(true); // Open the image viewer
                    }}
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      fontSize: "25px",
                      fill: "white",
                      backgroundColor: "var(--color-1)",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  /> : null}
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={changeNewsImgHandler}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col xs={12}>
                <div className='modal-input-container'>

                  <input type="text" onChange={(e) => setNewsTitle(e.target.value)} placeholder='عنوان خبر' />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <div className="modal-dropdown-container">
                  <div className='modal-input-container'>
                    {/* <label>شهر</label> */}
                    <div className='modal-dropdown-input-container'
                      onClick={() => setShowDropdown(!showDropdown)}>
                      <input type="text" value={city} placeholder='شهر' />
                      {showDropdown ? (<FaChevronUp className='icon' />) :
                        (<FaChevronDown className='icon' />)}
                    </div>
                  </div>
                  {
                    showDropdown &&
                    <ul className="modal-dropdown-list" style={{ top: '30px' }}>
                      <input className='search-dropdown-input' placeholder="جست و جو ..." autoComplete='off' value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)} />
                      {loadingDropdown ?
                        <LoadingForBtn />
                        :
                        cityList.length ?
                          cityList.map((item, index) =>
                            <li key={index} onClick={() => selectCity(item)}>{item.name}</li>
                          ) :
                          <li>شهری یافت نشد!..</li>
                      }
                    </ul>
                  }
                </div>
              </Col>
            </Row>

            <Row >
              <Col xs={12}>
                <div className='modal-input-container'>

                  <textarea type="text" onChange={(e) => setNewsDesc(e.target.value)} placeholder='توضیحات خبر' />
                </div>
              </Col>
            </Row>

          </Container>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none' }}>
          <button className='modal-btn modal-cancle-btn' onClick={() => {
            setShowAddModal(false);
            setNewsImgSource("");
            setCity("");

          }}>بازگشت</button>
          <button disabled={loadingForBtn === true} onClick={AddNews} className='modal-btn modal-green-btn'>
            {loadingForBtn ? <LoadingForBtn /> : <><RiAddCircleFill className='icon' />
              افزودن</>}
          </button>
        </Modal.Footer>
      </Modal >

      {/*Description Modal*/}
      < Modal
        className='news'
        show={showDescModal}
        centered
      >
        <Modal.Header>
          <Modal.Title id='newsDesc' style={{ width: '100%', display: 'flex' }}>
            <img src={descnewsImg} />

            <p style={{ margin: '0' }}>{title}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container style={{ textAlign: 'right', fontWeight: 'normal' }}>

            <Row style={{ textAlign: "center" }}>
              <Col xs={12}>
                {/* Profile */}
                <div style={{ position: 'relative', display: 'inline-block' }} className="Groups-details-profile">
                  <label
                    htmlFor="fileInput"
                    style={{
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                  >
                    {imgLoading ? <Loading /> : <img
                      src={newsImgSource ? newsImgSource : AddnewsImg}
                      width="80px"
                      height="80px"
                      alt="Profile"
                      style={{ borderRadius: "5px" }}
                    />}


                  </label>
                  {!imgLoading ? <BiShow
                    onClick={(e) => {
                      setViewImg(true); // Open the image viewer
                    }}
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      fontSize: "25px",
                      fill: "white",
                      backgroundColor: "var(--color-1)",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  /> : null}
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={changeNewsImgHandler}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} className='mt-3'>
                <div className='modal-input-container'>
                  <p style={{ fontWeight: 'bold', margin: '10px' }}>عنوان خبر</p>
                  <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
              </Col>
            </Row>
            <Row>
              <p style={{ fontWeight: 'bold', margin: '10px' }}>متن خبر</p>
            </Row>
            <Row style={{ backgroundColor: '#EAEAEA', margin: '0', borderRadius: '10px' }} >
              <Col style={{ padding: '10px' }} xs={12}>
                <div className="scroll-container">
                  <textarea value={description} onChange={(e) => setDescription(e.target.value)}>
                  </textarea>
                </div>
              </Col>
            </Row>
            <Row className='descnewsInfo'>
              <p>
                گزارش دهنده : {authorName} <span style={{ color: '#009688' }}>({authorRole === 'driver' && 'راننده'}{authorRole === 'admin' && 'ادمین'})</span>
              </p>
              <p>
                شماره گزارش دهنده : {authorNum}
              </p>
              <p>
                شهرهای مربوط : <span style={{ color: '#009688' }}>{city}</span>
              </p>
              <p>
                تاریخ خبر : {formatTimestamp(newsDate)}
              </p>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none', justifyContent: 'space-between' }}>
          {
            !isConfirmed ?
              <button style={{ color: 'var(--color-1)', border: '2px solid var(--color-1)', backgroundColor: 'var(--bg-color-2)' }} className='modal-btn' onClick={confirmNewHandler}>
                <FaCheck className='icon' />
                تایید خبر
              </button>
              :
              <button style={{ filter: 'grayscale(90%)', cursor: "no-drop" }} disabled className='modal-btn modal-green-btn'>
                <FaCheck className='icon' />
                تایید شده
              </button>
          }
          <div style={{ display: 'flex' }} >
            <button disabled={loadingForBtn === true} style={{ marginLeft: '5px' }} className='modal-btn modal-green-btn' onClick={editNewsApiHandler}>
              {loadingForBtn ? <LoadingForBtn /> : <><FaPencilAlt className='icon' />
                ویرایش خبر</>}
            </button>
            <button className='modal-btn modal-cancle-btn' onClick={() => {
              setShowDescModal(false);
              setNewsImgSource("");
              setCity("");
              setNewsImgId("");



            }}>
              بازگشت
            </button>
          </div>
        </Modal.Footer>
      </Modal >

      <Modal
        className='news modal-img'
        show={viewImg}
        centered
      >
        <Modal.Header>

        </Modal.Header>
        <Modal.Body>
          <Container style={{ textAlign: 'right', fontWeight: 'normal' }}>
            <img className='fullImg' style={{ width: '100%', height: '400px' }} src={newsImgSource ? newsImgSource : AddnewsImg} alt="" />
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none', justifyContent: 'space-between' }}>
          <button className='modal-btn modal-cancle-btn' onClick={() => setViewImg(false)}>
            بازگشت
          </button>

        </Modal.Footer>
      </Modal>
    </>
  )
}