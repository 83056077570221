import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../../Components/Breadcrumbs/Breadcrumbs';
import InfoListNavigation from '../../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { FaCircleXmark, FaPencil, FaRegTrashCan, FaTrash } from "react-icons/fa6";
import axios from "axios";
import { urlVariable } from "../../../Axios/Axios";
import Loading from "../../../Components/Loading/Loading";
import LoadingForBtn from "../../../Components/Loading/LoadingForBtn/LoadingForBtn";
import addNewCargo from "../../message/messageFromUser/AddNewCargo";
import { FaCheckCircle, FaPlusCircle } from "react-icons/fa";
import ToastComp from "../../../Components/Toast/ToastComp";
import { MdEdit } from "react-icons/md";




export default function Cars() {


    const [titleToast, setTitleToast] = useState("")
    const [bodyToast, setBodyToast] = useState("")
    const [icon, setIcon] = useState("")
    const [ShowToast, setShowToast] = useState("")
    const [ToastColor, setToastColor] = useState("")

    const [loading, setLoading] = useState(false);
    const [loadingBTN, setLoadingBTN] = useState(false);
    const [refreshData, setRefreshData] = useState(false)

    const [data, setData] = useState([]);

    const [showAddModal, setShowAddModal] = useState(false);
    const [newCar, setNewCar] = useState("");


    // edit modal states 
    const [showEditModal, setShowEditModal] = useState(false);
    const [editedBar, setEditedBar] = useState("")
    const [successfulUpdate, setSuccessfulUpdate] = useState("")
    const [carId, setCarId] = useState("")

    const finalEdit = () => {
        setLoadingBTN(true)
        axios.put(`${urlVariable}/admin/car/edit_car`, {
            name: editedBar,
            carType_id: carId
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then(res => {
            if (res.status === 200) {
                setShowEditModal(false)
                setLoadingBTN(false)
                setRefreshData(!refreshData)
                setShowToast(true)
                setTitleToast("ویرایش ناوگان")
                setBodyToast("ویرایش با موفقیت انجام شد !")
                setIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
                setToastColor('var(--color-1)')
            }

        }).catch(err => {
            if (err?.response?.status !== 200) {
                setShowToast(true)
                setTitleToast("ویرایش ناوگان")
                setBodyToast(err?.response?.data?.msg || "مشکل در اتصال به اینترنت !")
                setIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                setToastColor('var(--danger-color)')
                setLoadingBTN(false)
            }
            if (err.message === "Network Error")
                setLoadingBTN(true)

            console.log(err);
        })
    }

    useEffect(() => {

        setLoading(true)
        axios.get(`${urlVariable}/api/v1/car/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        })
            .then((res) => {
                console.log(res.data);
                setData(res.data.data);
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }, [refreshData]);

    function addNewCar() {

        setLoadingBTN(true)
        axios.post(`${urlVariable}/admin/car/newcar`, {
            name: newCar,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then((res) => {
            console.log(res)
            setTitleToast("عملیات موفق")
            setBodyToast(res.data.msg)
            setIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
            setToastColor('var(--color-1)')
            setShowToast(true)
            setLoadingBTN(false)
            setRefreshData(!refreshData)
            setShowAddModal(false)
            setNewCar("")
        }).catch((err) => {
            console.log(err)
            setTitleToast("عملیات ناموفق")
            setBodyToast(err.response.data.msg)
            setIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')
            setShowToast(true)
            setLoadingBTN(false)
        })

    }

    return (
        <div className="info-list">
            <Breadcrumbs
                items={[
                    { name: 'اطلاعات پایه', link: '' },
                    { name: 'مدیریت ناوگان', link: '/pannel/basicinfo/cars' }
                ]}
            />

            <ToastComp
                title={titleToast}
                body={bodyToast}
                icon={icon}
                onShowToast={setShowToast}
                showToastValue={ShowToast}
                toastColor={ToastColor}
            />

            <InfoListNavigation
                btntitle='افزودن ناوگان جدید'
                onShowModal={setShowAddModal}
                items={[]}
            />

            <div className="info-list-table-section">
                <Container className='mx-0'>
                    <Row>
                        {loading ?
                            <Loading />
                            :
                            data && data.map((item) =>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <div className="basic-info-card">
                                        <div className='city-infos'>
                                            <div>
                                                <div>{item.name}</div>
                                            </div>
                                            <div className='btns-container' onClick={() => {
                                                setShowEditModal(true)
                                                setEditedBar(item?.name)
                                                setCarId(item?._id?.$oid)
                                            }}>
                                                <div className="edit-btn-container">
                                                    <FaPencil />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )}
                    </Row>
                </Container>
            </div>


            {/* edit modal  */}

            <Modal
                show={showEditModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{ width: '100%' }}>
                        <div className="modal-title-section">
                            <span className="modal-title">ویرایش ناوگان</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <div className="col-sm">

                            <div className='modal-input-container'>
                                <label style={{ textAlign: "right" }}>
                                    نوع ناوگان
                                </label>
                                <input value={editedBar} onChange={(e) => setEditedBar(e.target.value)} />
                            </div>
                        </div>
                    </Row>

                </Modal.Body>
                <Modal.Footer style={{ borderTop: 'none' }}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowEditModal(false)}>لغو
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={finalEdit}>

                        {loadingBTN ? <LoadingForBtn sytle={{ fontSize: "10px" }} /> : (<> <MdEdit className='icon'
                            style={{
                                marginLeft: '4px',
                                fontSize: '15px'
                            }} /> ویرایش ناوگان </>)}
                    </button>
                </Modal.Footer>
            </Modal>


            {/*add modal*/}
            <Modal
                show={showAddModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{ width: '100%' }}>
                        <div className="modal-title-section">
                            <span className="modal-title">افزودن ناوگان جدید</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <div className="col-sm">

                            <div className='modal-input-container'>
                                <label style={{ textAlign: "right" }}>
                                    نام ناوگان
                                </label>
                                <input
                                    onChange={(e) => setNewCar(e.target.value)} />

                            </div>
                        </div>
                    </Row>

                </Modal.Body>
                <Modal.Footer style={{ borderTop: 'none' }}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowAddModal(false)}>لغو
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={addNewCar}>

                        {loadingBTN ? <LoadingForBtn sytle={{ fontSize: "10px" }} /> : (<> <FaPlusCircle className='icon'
                            style={{
                                marginLeft: '4px',
                                fontSize: '15px'
                            }} /> افزودن ناوگان جدید</>)}
                    </button>
                </Modal.Footer>
            </Modal>



        </div>
    )
}
