import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import InfoListNavigation from "../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation";
import {
    FaCircleXmark,
    FaTrash,
    // FaChevronDown,
    // FaChevronUp, FaGenderless
    FaChevronDown,
    FaChevronUp,

} from "react-icons/fa6";
import {
    FaArrowCircleDown,
    FaArrowCircleUp,
    FaBus,
    FaEdit,
    FaCheckCircle,
    FaPlusCircle,

    // FaEllipsisH
} from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import ToastComp from "../../Components/Toast/ToastComp";
import { Modal } from "react-bootstrap";
import { urlVariable } from "../../Axios/Axios";
import axios from "axios";
import defaultImg from "../../Assets/groupAppDefaultImg.svg";
import editDescImg from "../../Assets/GroupImgDesc.svg";
import "./GroupApp.css";
import { Container, Row, Col } from "react-bootstrap";
import Loading from "../../Components/Loading/Loading";
import LoadingForBtn from "../../Components/Loading/LoadingForBtn/LoadingForBtn";
import { RiAddCircleFill } from "react-icons/ri";
import { handleErrors } from '../../Axios/Axios';
import { MdDescription } from "react-icons/md";
import { MdGroups } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import { BiShow } from "react-icons/bi";

function GroupApp() {
    const [loadingForBtn, setLoadingForBtn] = useState(false)
    const [totPage, setTotPage] = useState()
    const [page, setPage] = useState(1)


    const formatTimestamp = (timestamp) => {
        if (!timestamp) return " ";
        return Intl.DateTimeFormat("fa-IR").format(timestamp);
    };
    let [groupApp, setGroupApp] = useState([]);
    let [showDeleteModal, setShowDeleteModal] = useState(false);
    let [showEditModal, setShowEditModal] = useState(false);
    let [showAddModal, setShowAddModal] = useState(false);
    let [groupName, setGroupName] = useState("");

    let [groupDesc, setGroupDesc] = useState("");
    let [groupCreatedAt, setGroupCreatedAt] = useState("");
    let [groupCity, setGroupCity] = useState("");

    let [addGroupName, setAddGroupName] = useState("");
    let [addGroupDesc, setAddGroupDesc] = useState("");
    let [addGroupMember, setAddGroupMember] = useState("");
    let [addGroupCreator, setAddGroupCreator] = useState("");
    let [addGroupTime, setAddGroupTime] = useState("");
    let [addGroupType, setAddGroupType] = useState("");
    let [stateID, setStateID] = useState("");
    let [imageSource, setImageSource] = useState("");

    let [isDeleted, setIsDeleted] = useState(false);

    let [userMember, setUserMember] = useState([]);
    let [userMemberId, setUserMemberId] = useState([]);

    const [loading, setLoading] = useState(false)

    const [isNewsAdd, setIsNewsAdd] = useState(false);
    const [searchUsernameApi, setSearchUsernameApi] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [imgLoading, setImgLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [viewImg, setViewImg] = useState(false);


    let [allUsers, setAllUsers] = useState([]);
    useEffect(() => {
        setLoadingDropdown(true);
        axios.get(`${urlVariable}/admin/user/`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
                },
                params: {
                    limit: 40,
                    page: 1,
                    status: "active",
                    phone_Number: searchUsernameApi
                }
            })
            .then((res) => {

                setAllUsers(res.data.data);
                setLoadingDropdown(false);


            })
            .catch((err) => {

                setLoadingDropdown(false);


            })


    }, [searchUsernameApi])
    const changeHandler = (e) => {
        setAddGroupMember(e.target.value);
        setShowUsersDropDown(true);
        setSearchUsernameApi(e.target.value);
    }
    useEffect(() => {
        setLoading(true);
        setIsDeleted(false);
        axios
            .get(`${urlVariable}/admin/groups`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
                },
                params: {
                    pagination: true,
                    limit: 10,
                    page: page,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    setGroupApp(res.data.data);
                    if (res.data.data.length < 1)
                        setIsEmpty(true);
                    setTotPage(res.data.total_pages);

                } else {
                    console.log("error");
                }

                setLoading(false);
            })
            .catch(err => {

                handleErrors(err)

                if (err.message === "Network Error") {

                    setShowToast(true)
                    setToastTitle('عملیات ناموفق')
                    setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید...!')
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')
                    setIsLoading(true);

                }
                else if (err.response && err.response.status <= 499) {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody(err.response.data.msg)
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')

                } else {
                    if (err.response && err.response.status >= 500) {
                        setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
                        setShowToast(true)
                        setToastTitle("عملیات ناموفق")
                        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                        setToastColor('var(--danger-color)')


                    } else {
                        setShowToast(true)
                        setToastTitle("عملیات ناموفق")
                        setToastBody("خطا در بارگیری اطلاعات")
                        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                        setToastColor('var(--danger-color)')

                    }
                }
                setLoading(false);

                setLoadingForBtn(false);




            })
    }, [isDeleted, isNewsAdd, page]);
    function editHandler(temp) {

        console.log(temp);


        setAddGroupType(temp.type)
        setOriginID(temp._id.$oid);
        setShowEditModal(true);
        setGroupName(temp.name);
        setGroupDesc(temp.description);
        setGroupCreatedAt(temp.createdAt);
        setGroupCity(temp.state && temp.state[0] ? temp.state[0].name : "");
        setStateID(temp.state && temp.state[0] ? temp.state[0]._id.$oid : "");
        setImageSource((temp.image && temp.image.source) ? temp.image.source : "");
        setUserMember(temp.users ? temp.users : []);
        setNewsImgId(temp.image ? temp.image._id.$oid : null);
        console.log(userMember);

    }

    function editGroupConfirm() {


        setLoadingForBtn(true);
        const temp3 = [];
        userMember.map((member) => {
            temp3.push(member._id.$oid);
        })
        console.log(originID);
        const temp = {
            _id: originID,
            name: groupName,
            statesID: stateID,
            imageID: newsImgId,
            type: addGroupType,
            description: groupDesc,
            active: false,
            authorizedUsers: temp3
        };
        axios
            .put(`${urlVariable}/admin/groups/edit`, temp, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
                },
            })
            .then((res) => {
                setImageSource("");
                if (res.status === 200) {
                    setShowEditModal(false);
                    setLoadingForBtn(false);
                    setToastTitle("عملیات موفق");
                    setToastBody("گروه شما با موفقیت ویرایش شد");
                    setToastIcon(<FaCheckCircle style={{ marginLeft: "5px" }} />);
                    setToastColor("var(--color-1)");
                    setShowToast(true);
                    setIsDeleted(true);
                    setUserMember([]);


                } else {
                    console.log("error");
                }
            })
            .catch(err => {

                handleErrors(err)
                if (err.message === "Network Error") {

                    setShowToast(true)
                    setToastTitle('عملیات ناموفق')
                    setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید...!')
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')

                }
                else if (err.response && err.response.status <= 499) {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody(err.response.data.msg)
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')

                } else {
                    if (err.response && err.response.status >= 500) {
                        setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
                        setShowToast(true)
                        setToastTitle("عملیات ناموفق")
                        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                        setToastColor('var(--danger-color)')


                    } else {
                        setShowToast(true)
                        setToastTitle("عملیات ناموفق")
                        setToastBody("خطا در بارگیری اطلاعات")
                        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                        setToastColor('var(--danger-color)')
                    }
                }
                setLoadingForBtn(false);




            })

    }

    const addGroupHandler = () => {
        setShowAddModal(true);
        setImageSource("");

    };
    // delete group
    let [deletedGroup, setDeletedGroup] = useState("");
    const deleteHandler = (t) => {
        setShowDeleteModal(true);
        setDeletedGroup(t._id.$oid);
    };
    const deleteGroupConfirm = () => {
        setLoadingForBtn(true);
        const temp = {
            group_id: deletedGroup,
        };
        axios
            .delete(`${urlVariable}/admin/groups/deleted`, {
                data: temp,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    setLoadingForBtn(false);
                    setShowDeleteModal(false);
                    setIsNewsAdd(true);
                    setToastTitle("عملیات موفق");
                    setToastBody("گروه شما با موفقیت حذف شد");
                    setToastIcon(<FaCheckCircle style={{ marginLeft: "5px" }} />);
                    setToastColor("var(--color-1)");
                    setShowToast(true);
                    setIsDeleted(true);


                } else {
                    console.log("error");
                }
            })
            .catch(err => {

                handleErrors(err)
                if (err.message === "Network Error") {

                    setShowToast(true)
                    setToastTitle('عملیات ناموفق')
                    setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید...!')
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')

                }
                else if (err.response && err.response.status <= 499) {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody(err.response.data.msg)
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')

                } else {
                    if (err.response && err.response.status >= 500) {
                        setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
                        setShowToast(true)
                        setToastTitle("عملیات ناموفق")
                        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                        setToastColor('var(--danger-color)')


                    } else {
                        setShowToast(true)
                        setToastTitle("عملیات ناموفق")
                        setToastBody("خطا در بارگیری اطلاعات")
                        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                        setToastColor('var(--danger-color)')


                    }
                }
                setLoadingForBtn(false);
            })
    };
    const [newsImgId, setNewsImgId] = useState("");

    function changeNewsImgHandler(e) {
        setImgLoading(true);
        const formData = new FormData();
        formData.append("attachedFile", e.target.files[0]);
        axios
            .post(
                `${urlVariable}/admin/uploadFile`, formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
                    },
                }
            )
            .then((res) => {

                setToastTitle('عملیات موفق')
                setToastBody('عکس شما با موفقیت بارگذاری شد')
                setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
                setToastColor('var(--color-1)')
                setShowToast(true)
                setImageSource(res.data.data.source);
                if (res.status === 200) {
                    setNewsImgId(res.data.data._id.$oid);
                }
                setImgLoading(false);

            })
            .catch((err) => {
                handleErrors(err)
                setToastTitle('عملیات ناموفق')
                setToastBody(err.response.data.msg)
                setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                setToastColor('var(--danger-color)')
                setShowToast(true)
                setImgLoading(false);
            });
    }
    useEffect(() => {
        if (totPage < page && totPage > 0)
            setPage(totPage);
    }, [totPage, page])
    const [citiesList, setCitiesList] = useState([]);
    const [searchCityShow, setSearchCityShow] = useState("")
    const [searchCityApi, setSearchCityApi] = useState("")

    useEffect(() => {

        setLoadingDropdown(true)
        axios
            .get(`${urlVariable}/api/v1/groups/states`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
                },
                params: {
                    search: searchCityApi
                }
            })
            .then((res) => {
                setLoadingDropdown(false)

                if (res.status === 200) {
                    setCitiesList(res.data.data);
                } else {
                    console.log("error");
                }
            })
            .catch((err) => {
                setLoadingDropdown(false)
                console.log(err);

            });
    }, [searchCityApi]);

    useEffect(() => {

        const delayData = setTimeout(() => {
            setSearchCityApi(searchCityShow);
            setSearchUsernameApi(searchUsernameApi);
        }, [800])
        return () => clearTimeout(delayData)
    }, [searchCityShow]);

    const [loadingDropdown, setLoadingDropdown] = useState(false); // for city and group data
    const [showDropDown, setShowDropDown] = useState("");
    const [showUsersDropDown, setShowUsersDropDown] = useState(false);
    const [origin, setOrigin] = useState("");
    const [originID, setOriginID] = useState(""); // value for send api
    const [addGroupState, setAddGroupState] = useState(""); // value for send api
    function dropdown(value) {
        if (showDropDown === value) setShowDropDown("");
        else setShowDropDown(value);
    }

    function selectOrigin(value) {
        console.log(value);
        setShowDropDown(false);
        setOrigin(value.name);
        setStateID(value._id.$oid);
        setGroupCity(value.name);
    }
    function selectUser(value) {
        if (userMember.find((item) => item.phoneNumber === value.phoneNumber)) {
            setToastTitle('عملیات ناموفق')
            setToastBody('کاربر قبلا اضافه شده است!')
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')
            setShowToast(true)
            return;
        }
        setToastTitle('عملیات موفق')
        setToastBody('کاربر با موفقیت اضافه شد.')
        setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
        setToastColor('var(--color-1)')
        setShowToast(true)



        setAddGroupMember(value.name);
        setShowUsersDropDown(true);
        setUserMember([...userMember, value])
        setUserMemberId([...userMemberId, value._id.$oid])

        showUsersDropDown ? setShowUsersDropDown(false) : setShowUsersDropDown(true);
    }
    const handleChange = (event) => {
        setAddGroupType(event.target.value);
    };
    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastBody, setToastBody] = useState("");
    const [toastIcon, setToastIcon] = useState();
    const [toastColor, setToastColor] = useState("");

    const AddGroup = () => {

        setLoadingForBtn(true);
        const temp3 = [];
        userMember.map((member) => {
            temp3.push(member._id.$oid);
        })
        const temp2 = {
            name: addGroupName,
            statesID: stateID,
            type: addGroupType,
            description: addGroupDesc,
            active: true,
            imageID: newsImgId ? newsImgId : null,
            authorizedUsers: temp3
        };

        axios
            .post(`${urlVariable}/admin/groups/add`, temp2, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
                },
            })
            .then((res) => {
                setShowAddModal(false);
                setIsNewsAdd(true);
                setLoadingForBtn(false);
                setToastTitle("عملیات موفق");
                setToastBody("گروه شما با موفقیت ثبت شد");
                setToastIcon(<FaCheckCircle style={{ marginLeft: "5px" }} />);
                setToastColor("var(--color-1)");
                setShowToast(true);
                setIsNewsAdd(!isNewsAdd);
                setAddGroupName("");
                setAddGroupDesc("");
                setAddGroupCreator("");
                setAddGroupTime("");
                setAddGroupType("");
                setAddGroupState("");
                setGroupCity("");
                setOrigin("");

            })
            .catch(err => {

                handleErrors(err);

                if (err.message === "Network Error") {

                    setShowToast(true)
                    setToastTitle('عملیات ناموفق')
                    setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید...!')
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')

                }
                else if (err.response && err.response.status <= 499) {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody(err.response.data.msg)
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')

                } else {
                    if (err.response && err.response.status >= 500) {
                        setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
                        setShowToast(true)
                        setToastTitle("عملیات ناموفق")
                        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                        setToastColor('var(--danger-color)')


                    } else {
                        setShowToast(true)
                        setToastTitle("عملیات ناموفق")
                        setToastBody("خطا در بارگیری اطلاعات")
                        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                        setToastColor('var(--danger-color)')

                    }
                }
                setLoadingForBtn(false);
            })
    };

    const deleteMember = (member) => {

        const temp = userMember.filter((item) => member._id.$oid !== item._id.$oid)
        setUserMember(temp);
    }
    return (
        <>
            <div className="info-list">
                <Breadcrumbs
                    items={[
                        { name: "اپلیکیشن", link: "" },
                        { name: "مدیریت گروه‌ها", link: "" },
                    ]}
                />

                <InfoListNavigation
                    onShowModal={setShowAddModal}
                    btntitle="افزودن گروه جدید"
                    // onShowModal={}
                    // btnLink=''
                    // setStatus={}
                    // status={}
                    items={[
                        // ["---", "", <FaFileCircleCheck className="icon" />],
                        // ["---", "", <FaFileCircleXmark className="icon" />],
                    ]}
                />
                <div className="info-list-table-section">

                    <table className="info-list-table">
                        <thead>
                            <tr className="info-list-tr-head">
                                <th>
                                    <MdGroups
                                        style={{ marginLeft: "3px", fontSize: "16px" }}
                                    />
                                    نام گروه
                                </th>

                                <th className="td-res-1">
                                    <MdDescription style={{ marginLeft: "3px", fontSize: "16px" }} />
                                    توضیحات
                                </th>
                                <th className="td-res-1">
                                    <svg style={{ marginLeft: '3px' }} stroke="currentColor" fill="currentColor" strokeWidth=".3" viewBox="0 0 16 16" height="17px" width="17px" xmlns="http://www.w3.org/2000/svg"><path d="M6 9.5C6.93191 9.5 7.71496 10.1374 7.93699 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7455 13.8231 11.9496 13.5899 11.9919L13.5 12L7.93673 12.001C7.71435 12.8631 6.93155 13.5 6 13.5C5.06845 13.5 4.28565 12.8631 4.06327 12.001L2.5 12C2.22386 12 2 11.7761 2 11.5C2 11.2545 2.17688 11.0504 2.41012 11.0081L2.5 11H4.06301C4.28504 10.1374 5.06809 9.5 6 9.5ZM6 10.5C5.44772 10.5 5 10.9477 5 11.5C5 12.0523 5.44772 12.5 6 12.5C6.55228 12.5 7 12.0523 7 11.5C7 10.9477 6.55228 10.5 6 10.5ZM10 2.5C10.9319 2.5 11.715 3.13738 11.937 3.99998L13.5 4C13.7761 4 14 4.22386 14 4.5C14 4.74546 13.8231 4.94961 13.5899 4.99194L13.5 5L11.9367 5.00102C11.7144 5.86312 10.9316 6.5 10 6.5C9.06845 6.5 8.28565 5.86312 8.06327 5.00102L2.5 5C2.22386 5 2 4.77614 2 4.5C2 4.25454 2.17688 4.05039 2.41012 4.00806L2.5 4L8.06301 3.99998C8.28504 3.13738 9.06809 2.5 10 2.5ZM10 3.5C9.44772 3.5 9 3.94772 9 4.5C9 5.05228 9.44772 5.5 10 5.5C10.5523 5.5 11 5.05228 11 4.5C11 3.94772 10.5523 3.5 10 3.5Z"></path></svg>

                                    زمان ایجاد
                                </th>
                                <th>
                                    <IoMdSettings
                                        style={{ marginLeft: "3px", fontSize: "16px" }}
                                    />
                                    عملیات
                                </th>
                            </tr>
                        </thead>
                        {groupApp.length ? (
                            <tbody>
                                {
                                    groupApp.map((group) => {
                                        return (
                                            <tr className="info-list-row" key={group._id.$oid}>
                                                <td className="td-res-1 img-container" style={{ paddingRight: '5px' }}>
                                                    <img src={group.image ? `${urlVariable}/${group.image.source}` : defaultImg} />
                                                    <p>{group.name.length > 20 ? <>{group.name.slice(0, 20)} ...</> : group.name}</p>
                                                </td>
                                                <td className="td-res-1">
                                                    <p style={{ width: '200px ' }}>{group.description.length > 20 ? <>{group.description.slice(0, 20)} ...</> : group.description ? group.description : "-"}</p>
                                                </td>
                                                <td className="td-res-1">
                                                    <p>{formatTimestamp(group.createdAt)}</p>
                                                </td>
                                                <td>
                                                    <span
                                                        onClick={() => editHandler(group)}
                                                        style={{
                                                            color: "var(--color-1)",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        ویرایش
                                                    </span>
                                                    <span style={{ margin: "0 3px" }}>/</span>
                                                    <span
                                                        onClick={() => deleteHandler(group)}
                                                        style={{
                                                            color: "var(--danger-color)",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        حذف
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        ) : isLoading ? <tr>
                            <td colSpan="4" style={{
                                textAlign: 'center',
                                padding: '10px 0',
                                backgroundColor: 'var(--bg-color-2)'
                            }}>در حال حاضر گروهی نداریم!
                            </td>
                        </tr> : isEmpty ? <p style={{ textAlign: 'center' }}>گروهی برای نمایش وجود ندارد.</p> : <tr>
                            <td colSpan='4'>
                                <Loading />

                            </td>
                        </tr>}
                    </table>

                    {/* isLoading ? <p style={{ textAlign: 'center' }}>گروهی برای نمایش وجود ندارد.</p> : isEmpty ? <p style={{ textAlign: 'center' }}>گروهی برای نمایش وجود ندارد.</p> : <Loading /> */}

                </div>
                {/* Pagination Section */}
                <div className="pagination-section">
                    <button disabled={page === 1} style={page === 1 ? { cursor: 'not-allowed' } : null} onClick={() => setPage(page - 1)} className='pagination-btn'>قبلی</button>
                    <div>صفحه {page} از {totPage}</div>
                    <button disabled={page >= totPage || !totPage} style={page >= totPage || !totPage ? { cursor: 'not-allowed' } : null} onClick={() => setPage(page + 1)} className='pagination-btn'>بعدی</button>
                </div>
            </div>
            <ToastComp
                title={toastTitle}
                body={toastBody}
                icon={toastIcon}
                onShowToast={setShowToast}
                showToastValue={showToast}
                toastColor={toastColor}
            />
            {/* Toast */}
            {/*<ToastComp*/}
            {/*    title={}*/}
            {/*    body={}*/}
            {/*    icon={}*/}
            {/*    onShowToast={}*/}
            {/*    showToastValue={}*/}
            {/*    toastColor={}*/}
            {/*/> */}

            {/* Delete Modal */}
            <Modal
                // show={}
                show={showDeleteModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{ width: "100%" }}>
                        <div className="modal-title-section">
                            <span className="modal-title">حذف گروه</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>آیا از حذف اطمینان دارید؟</Modal.Body>
                <Modal.Footer style={{ borderTop: "none" }}>
                    <button
                        onClick={() => setShowDeleteModal(false)}
                        className="modal-btn modal-cancle-btn"
                    >
                        لغو
                    </button>
                    <button
                        disabled={loadingForBtn === true}
                        onClick={deleteGroupConfirm}
                        className="modal-btn modal-red-btn"
                    >

                        {loadingForBtn ? <LoadingForBtn /> : <><FaTrash className='icon' />
                            حذف</>}
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal */}
            <Modal show={showEditModal}>
                <Modal.Header>
                    <Modal.Title style={{ width: "100%" }}>
                        <div
                            className="modal-title-section"

                        >
                            <span className="modal-title">ویرایش گروه</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{ textAlign: "right", fontWeight: "normal" }}>
                        <Row style={{ textAlign: "center" }}>
                            <Col xs={12}>
                                {/* Profile */}
                                <div className="Groups-details-profile" style={{ display: 'inline-block', position: 'relative' }}>
                                    <label htmlFor="fileInput" style={{
                                        cursor: 'pointer',
                                    }}>
                                        {imgLoading ? <Loading /> : <img

                                            src={imageSource ? `${urlVariable}/${imageSource}` : defaultImg}
                                            width="80px"
                                            height="80px"
                                            alt="Profile"
                                            style={{ borderRadius: '5px' }}
                                        />}
                                    </label>
                                    {!imgLoading ? <BiShow
                                        onClick={(e) => {
                                            setViewImg(true); // Open the image viewer
                                        }}
                                        style={{
                                            position: "absolute",
                                            bottom: "0",
                                            left: "0",
                                            fontSize: "25px",
                                            fill: "white",
                                            backgroundColor: "var(--color-1)",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                    /> : null}
                                    <input
                                        id="fileInput"
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        onChange={changeNewsImgHandler}
                                    />
                                </div>
                                {/* Details */}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="modal-input-container">
                                    <label style={{ margin: "10px" }}>نام</label>
                                    <input
                                        type="text"
                                        value={groupName}
                                        onChange={(e) => setGroupName(e.target.value)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="modal-input-container">
                                    <label>توضیحات</label>
                                    <textarea
                                        style={{ height: '100px' }}
                                        type="text"
                                        value={groupDesc}
                                        onChange={(e) => setGroupDesc(e.target.value)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {/* <Row>
              <p style={{ fontWeight: 'bold', margin: '10px' }}>متن خبر</p>
            </Row>
            <Row style={{ backgroundColor: '#EAEAEA', margin: '0', borderRadius: '10px' }} >
              <Col style={{ padding: '10px' }} xs={12}>
                <div class="scroll-container">
                    <input type="text" value={}/>
                </div>
              </Col>
            </Row> */}


                        <Row>
                            <Col xs={12}>
                                <div className="col-sm">
                                    <div
                                        className="modal-dropdown-container"

                                    >
                                        <div className="modal-input-container" onClick={() => dropdown("origin")}>
                                            <label style={{ textAlign: "right" }}>استان</label>
                                            <div className="modal-dropdown-input-container">
                                                <input value={groupCity} />
                                                {showDropDown === "origin" ? (
                                                    <FaChevronUp className="icon" />
                                                ) : (
                                                    <FaChevronDown className="icon" />
                                                )}
                                            </div>
                                        </div>
                                        {showDropDown === "origin" ? (
                                            <ul className="modal-dropdown-list">
                                                <input className="search-dropdown-input" placeholder="جست و جو ..." autoComplete='off' value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)} />
                                                {loadingDropdown ? (
                                                    <LoadingForBtn />
                                                ) : (
                                                    citiesList.length ?
                                                        citiesList.map((item, index) => (
                                                            <li key={index} onClick={() => selectOrigin(item)}>
                                                                {item.name}
                                                            </li>
                                                        ))
                                                        :
                                                        <li>استانی یافت نشد!...</li>
                                                )}
                                            </ul>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {/* <Row>
                            <Col xs={12}>
                                <div className="col-sm">
                                    <div
                                        className="modal-dropdown-container"
                                    >
                                        {(showUsersDropDown && addGroupMember) ?
                                            <ul className="modal-dropdown-list" style={{ top: '0' }}>
                                                {loadingDropdown ? (
                                                    <LoadingForBtn />
                                                ) : (
                                                    allUsers.length ?
                                                        allUsers.map((item, index) => (
                                                            <li key={index} onClick={() => selectUser(item)}>
                                                                {item.name}
                                                            </li>
                                                        ))
                                                        :
                                                        <li>استانی یافت نشد!...</li>
                                                )}
                                            </ul> : ""}

                                    </div>
                                </div>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col xs={12}>
                                <div className="">
                                    <label style={{ margin: '10px 0', fontSize: '13px' }}>اعضای گروه </label>
                                    <div className="modal-members-container">
                                        <ul>
                                            {userMember.length ? userMember.map((member, index) => {
                                                return (
                                                    <li style={{
                                                        backgroundColor: '#eaeaea',
                                                        padding: '5px 20px',
                                                        borderRadius: '25px',
                                                        marginLeft: '5px',
                                                        marginBottom: '5px',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                    }} key={index}>

                                                        <div>
                                                            <p style={{ margin: '0' }}>{member.name ? member.name : member.companyName}</p>
                                                            <p style={{ margin: '0', fontSize: '11px' }}> {member.phoneNumber}</p>
                                                        </div>
                                                        <CiCircleRemove cursor={'pointer'} onClick={() => deleteMember(member)} style={{ marginRight: '10px', fontSize: '20px', fill: 'darkred' }}></CiCircleRemove>
                                                    </li>
                                                )
                                            }) : <p style={{ color: 'gray' }}>هنوز عضوی وجود ندارد.</p>}


                                        </ul>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col xs={12}>
                                <div className="modal-input-container" style={{ margin: '0' }}>
                                    <label>افزودن کاربر</label>
                                    <div className="modal-dropdown-input-container">
                                        <input
                                            type="text"
                                            onClick={() => setShowUsersDropDown(!showUsersDropDown)}
                                        />
                                        {showUsersDropDown ? (
                                            <FaChevronUp className="icon" />
                                        ) : (
                                            <FaChevronDown className="icon" />
                                        )}
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="col-sm">
                                    <div
                                        className="modal-dropdown-container"
                                    >

                                        {(showUsersDropDown) ?
                                            <ul className="modal-dropdown-list" style={{ top: '0' }}>
                                                <input className="search-dropdown-input" placeholder="جست و جو با شماره..." autoComplete='off' value={searchUsernameApi} onChange={(e) => setSearchUsernameApi(e.target.value)} />
                                                {loadingDropdown ? (
                                                    <LoadingForBtn />
                                                ) : (
                                                    allUsers.length ?
                                                        allUsers.map((item, index) => (
                                                            <li style={{ display: 'flex', justifyContent: 'space-between' }} key={index} onClick={() => selectUser(item)}>
                                                                <p style={{ margin: '0' }}>{item.name ? item.name : item.companyName}</p>
                                                                <p style={{ margin: '0' }}>{item.phoneNumber}</p>
                                                            </li>
                                                        ))
                                                        :
                                                        <li>کاربری یافت نشد!...</li>
                                                )}
                                            </ul> : ""}

                                    </div>
                                </div>
                            </Col>
                        </Row>


                        <Row>
                            <Col xs={12}>
                                <div className="modal-input-container">
                                    <label style={{ margin: "30px 10px 0px" }}>نوع گروه</label>

                                    <form style={{ display: 'flex' }}>
                                        <div>
                                            <input

                                                onChange={handleChange}
                                                type="radio"
                                                id="css"
                                                name="fav_language"
                                                value="private"
                                                checked={addGroupType === "private"}
                                            />
                                            <label style={{ margin: "10px", fontSize: '16px' }} for="css">
                                                خصوصی
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                onChange={handleChange}
                                                type="radio"
                                                id="javascript"
                                                name="fav_language"
                                                value="public"
                                                checked={addGroupType === "public"}

                                            />
                                            <label style={{ margin: "10px", fontSize: '16px' }} for="javascript">
                                                عمومی
                                            </label>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                        <Row className="descnewsInfo">

                            <p>تاریخ ایجاد : {formatTimestamp(groupCreatedAt)}</p>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{ borderTop: "none" }}>
                    <button
                        onClick={() => {
                            setShowEditModal(false)
                            setUserMember([]);
                            setShowUsersDropDown(false);
                            setSearchUsernameApi("");
                            setOrigin("");
                            setImageSource("");
                        }}
                        className="modal-btn modal-cancle-btn"
                    >
                        لغو
                    </button>
                    <button
                        disabled={loadingForBtn === true}
                        onClick={editGroupConfirm}
                        className="modal-btn modal-green-btn"
                    >
                        {/* <FaEdit
                            className="icon"
                            style={{ marginLeft: "4px", fontSize: "15px" }}
                        /> */}

                        {loadingForBtn ? <LoadingForBtn /> : <><FaEdit className='icon' />
                            ویرایش</>}
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Add Modal */}
            <Modal show={showAddModal}>
                <Modal.Header>
                    <Modal.Title style={{ width: "100%" }}>
                        <div
                            className="modal-title-section"

                        >
                            <span className="modal-title">افزودن گروه</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{ textAlign: "right", fontWeight: "normal" }}>
                        <Row style={{ textAlign: "center" }}>
                            <Col xs={12}>
                                {/* Profile */}
                                <div className="Groups-details-profile" style={{ display: 'inline-block', position: 'relative' }}>
                                    <label htmlFor="fileInput" style={{
                                        cursor: 'pointer'

                                    }}>
                                        {imgLoading ? <Loading /> : <img

                                            src={imageSource ? `${urlVariable}/${imageSource}` : defaultImg}
                                            width="80px"
                                            height="80px"
                                            style={{ borderRadius: '5px' }}
                                            alt="Profile"
                                        />}
                                    </label>
                                    {!imgLoading ? <BiShow
                                        onClick={(e) => {
                                            setViewImg(true); // Open the image viewer
                                        }}
                                        style={{
                                            position: "absolute",
                                            bottom: "0",
                                            left: "0",
                                            fontSize: "25px",
                                            fill: "white",
                                            backgroundColor: "var(--color-1)",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                    /> : null}
                                    <input
                                        id="fileInput"
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        onChange={changeNewsImgHandler}
                                    />
                                </div>
                                {/* Details */}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="modal-input-container">
                                    <label style={{ margin: "10px" }}>نام</label>
                                    <input
                                        type="text"
                                        value={addGroupName}
                                        onChange={(e) => setAddGroupName(e.target.value)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="modal-input-container">
                                    <label>توضیحات</label>
                                    <input
                                        type="text"
                                        value={addGroupDesc}
                                        onChange={(e) => setAddGroupDesc(e.target.value)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="col-sm">
                                    <div
                                        className="modal-dropdown-container"

                                    >
                                        <div className="modal-input-container" onClick={() => dropdown("origin")}>
                                            <label style={{ textAlign: "right" }}>استان</label>
                                            <div className="modal-dropdown-input-container">
                                                <input value={origin} />
                                                {showDropDown === "origin" ? (
                                                    <FaChevronUp className="icon" />
                                                ) : (
                                                    <FaChevronDown className="icon" />
                                                )}
                                            </div>
                                        </div>
                                        {showDropDown === "origin" ? (
                                            <ul className="modal-dropdown-list">
                                                <input className="search-dropdown-input" placeholder="جست و جو ..." autoComplete='off' value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)} />
                                                {loadingDropdown ? (
                                                    <LoadingForBtn />
                                                ) : (
                                                    citiesList.length ?
                                                        citiesList.map((item, index) => (
                                                            <li key={index} onClick={() => selectOrigin(item)}>
                                                                {item.name}
                                                            </li>
                                                        ))
                                                        :
                                                        <li>استانی یافت نشد!...</li>
                                                )}
                                            </ul>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <div className="">
                                    <label style={{ margin: '10px 0', fontSize: '13px' }}>اعضای گروه </label>
                                    <div className="modal-members-container">
                                        <ul>
                                            {userMember.length > 0 ? userMember.map((member, index) => {
                                                return (
                                                    <li style={{
                                                        backgroundColor: '#eaeaea',
                                                        padding: '5px 20px',
                                                        borderRadius: '25px',
                                                        marginLeft: '5px',
                                                        marginBottom: '5px',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                    }} key={index}>

                                                        <div>
                                                            <p style={{ margin: '0' }}>{member.name ? member.name : member.companyName}</p>
                                                            <p style={{ margin: '0', fontSize: '11px' }}> {member.phoneNumber}</p>
                                                        </div>
                                                        <CiCircleRemove cursor={'pointer'} onClick={() => deleteMember(member)} style={{ marginRight: '10px', fontSize: '20px', fill: 'darkred' }}></CiCircleRemove>
                                                    </li>
                                                )
                                            }) : <p style={{ color: 'gray' }}>هنوز عضوی وجود ندارد.</p>}


                                        </ul>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col xs={12}>
                                <div className="modal-input-container" style={{ margin: '0' }}>
                                    <label>افزودن کاربر</label>
                                    <div className="modal-dropdown-input-container">
                                        <input
                                            type="text"
                                            onClick={() => setShowUsersDropDown(!showUsersDropDown)}
                                        />
                                        {showUsersDropDown ? (
                                            <FaChevronUp className="icon" />
                                        ) : (
                                            <FaChevronDown className="icon" />
                                        )}
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="col-sm">
                                    <div
                                        className="modal-dropdown-container"
                                    >

                                        {(showUsersDropDown) ?
                                            <ul className="modal-dropdown-list" style={{ top: '0' }}>
                                                <input className="search-dropdown-input" placeholder="جست و جو با شماره..." autoComplete='off' value={searchUsernameApi} onChange={(e) => setSearchUsernameApi(e.target.value)} />
                                                {loadingDropdown ? (
                                                    <LoadingForBtn />
                                                ) : (
                                                    allUsers.length ?
                                                        allUsers.map((item, index) => (
                                                            <li style={{ display: 'flex', justifyContent: 'space-between' }} key={index} onClick={() => selectUser(item)}>
                                                                <p style={{ margin: '0' }}>{item.name ? item.name : item.companyName}</p>
                                                                <p style={{ margin: '0' }}>{item.phoneNumber}</p>
                                                            </li>
                                                        ))
                                                        :
                                                        <li>کاربری یافت نشد!...</li>
                                                )}
                                            </ul> : ""}

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="modal-input-container">
                                    <label style={{ margin: "30px 10px 0px" }}>نوع گروه</label>

                                    <form style={{ display: 'flex' }}>
                                        <input
                                            onChange={handleChange}
                                            type="radio"
                                            id="css"
                                            name="fav_language"
                                            value="private"
                                        />
                                        <label style={{ margin: "10px", fontSize: '16px' }} htmlFor="css">
                                            خصوصی
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            type="radio"
                                            id="javascript"
                                            name="fav_language"
                                            value="public"
                                        />
                                        <label style={{ margin: "10px", fontSize: '16px' }} htmlFor="javascript">
                                            عمومی
                                        </label>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                        {/* <Row>
              <p style={{ fontWeight: 'bold', margin: '10px' }}>متن خبر</p>
            </Row>
            <Row style={{ backgroundColor: '#EAEAEA', margin: '0', borderRadius: '10px' }} >
              <Col style={{ padding: '10px' }} xs={12}>
                <div class="scroll-container">
                    <input type="text" value={}/>
                </div>
              </Col>
            </Row> */}


                    </Container>
                </Modal.Body>
                <Modal.Footer style={{ borderTop: "none" }}>
                    <button
                        onClick={() => {
                            setShowAddModal(false)
                            setAddGroupName("");
                            setAddGroupDesc("");
                            setAddGroupCreator("");
                            setAddGroupTime("");
                            setAddGroupType("");
                            setAddGroupState("");
                            setGroupCity("");
                            setOrigin("");
                            setUserMember([]);
                            setShowUsersDropDown(false);
                            setSearchUsernameApi("");


                        }}
                        className="modal-btn modal-cancle-btn"
                    >
                        لغو
                    </button>
                    <button disabled={loadingForBtn === true}
                        onClick={AddGroup} className="modal-btn modal-green-btn">
                        {loadingForBtn ? <LoadingForBtn /> : <><RiAddCircleFill className='icon' />
                            افزودن</>}
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal
                className='news modal-img'
                show={viewImg}
                centered
            >
                <Modal.Header>

                </Modal.Header>
                <Modal.Body>
                    <Container style={{ textAlign: 'right', fontWeight: 'normal' }}>
                        <img className='fullImg' style={{ width: '100%', height: '400px' }} src={imageSource ? `${urlVariable}/${imageSource}` : defaultImg} alt="" />
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{ borderTop: 'none', justifyContent: 'space-between' }}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setViewImg(false)}>
                        بازگشت
                    </button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default GroupApp;
