import React from 'react';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import { Container, Row, Col } from 'react-bootstrap';
import StatisticsCard from '../../Components/StatisticsCard/StatisticsCard';
import { BsFillPatchCheckFill } from "react-icons/bs";
import { urlVariable } from '../../Axios/Axios';
import { useState , useEffect} from 'react';
import axios from 'axios';
import Loading from '../../Components/Loading/Loading';
export default function StatisticsMessages() {
    const [loading, setLoading] = useState(false);
    const [barDataCount, setBarDataCount] = useState("")

    useEffect(() => {
        axios.get(`${urlVariable}/admin/cargo`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            },
            params : {
                Bot : 'active'
            }
        })
            .then((res) => {
                console.log(res);
                setBarDataCount(res.data.data.length);

            })
            .catch(err => {
               

            })
    }, [])

    const [usersCargoCount, setUsersCargoCount] = useState("")
    useEffect(() => {

        axios.get(`${urlVariable}/admin/cargo`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            },
            params : {
                User : 'active'
            }
        })
            .then((res) => {
                console.log(res);
                setUsersCargoCount(res.data.data.length);
            })
            .catch(err => {
               

            })
    }, [])
    
    const [newsCount, setNewsCount] = useState("")
    useEffect(()=>{
        axios.get(`${urlVariable}/admin/news/get_list`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
                },   
                params : {
                    "is-confirmed": true
                }
            }
        )
        .then(res =>
        {
            setNewsCount(res.data.total_count)
        }
        )
    },[])
   
    return (
        <div className="info-list">
            <Breadcrumbs
                items={[
                    { name: 'آمار بارها', link: '/pannel/statistics/messages' },
                ]}
            />
            <div className='mt-4'>
                <Container>
                    <Row>
                        <Col xs={12} md={4}>
                            <StatisticsCard
                                link='/pannel/message/barecopy'
                                title='بارهای بارکپی'
                                count={barDataCount ? barDataCount : <Loading />}
                                icon={<BsFillPatchCheckFill className='icon' />}
                                
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <StatisticsCard
                                link='/pannel/message/fromUser'
                                title='بارهای کاربران'
                                count={usersCargoCount ? usersCargoCount : <Loading /> }
                                icon={<BsFillPatchCheckFill className='icon' />}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <StatisticsCard
                                link='/pannel/news'
                                title='تعداد اخبار'
                                count={newsCount ? newsCount : <Loading /> }
                                icon={<BsFillPatchCheckFill className='icon' />}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
