import {
    FaGgCircle
} from "react-icons/fa6";

const sidebarItemsData = [
    {
        title: 'بارکپی',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: '',
        subItems: [
            { subtitle: 'مدیریت الگوها', sublink: '' },
            { subtitle: 'مدیریت الگوهای شماره', sublink: '/pannel/barcopy/patternno' },
            { subtitle: 'مدیریت گروه‌ها', sublink: '/pannel/barcopy/groups' },
        ]
    },
    {
        title: 'بارها',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: '',
        subItems: [
            { subtitle: 'بارکپی', sublink: '/pannel/message/barecopy' },
            { subtitle: 'بارهای ارسالی کاربران', sublink: '/pannel/message/fromUser' },
            { subtitle: 'افزودن بار جدید', sublink: '/pannel/message/addNewCargo' },
        ]
    },
    {
        title: 'اپلیکیشن',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: '',
        subItems: [
            { subtitle: 'مدیریت گروه‌ها', sublink: '/pannel/groupsApp' },
            { subtitle: 'مدیریت کلمات نامناسب', sublink: '' },
        ]
    },
    {
        title: 'کاربران',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: '',
        subItems: [
            { subtitle: 'همه کاربران', sublink: '/pannel/users/all' },
            { subtitle: 'کاربران مسدود شده', sublink: '/pannel/users/blocked' },
            { subtitle: 'کاربران آنلاین', sublink: '/pannel/users/online' },
            { subtitle: 'کاربران دارای اشتراک', sublink: '/pannel/users/subscribed' },
            { subtitle: 'درآمد', sublink: '/pannel/income' },
        ]
    },
    {
        title: 'اخبار',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: '/pannel/news',
        subItems: []
    },
    {
        title: 'تخلفات',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: '/pannel/violation',
        subItems: []
    },
    {
        title: 'آمار',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: '',
        subItems: [
            { subtitle: 'کاربران', sublink: '/pannel/statistics/users' },
            { subtitle: 'بارها', sublink: '/pannel/statistics/messages' },
            { subtitle: 'تخلفات', sublink: '' },
            { subtitle: 'مدیریت', sublink: '' },
        ]
    },
    {
        title: 'تیکت‌ها',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: '/pannel/tickets/list',
        subItems: []
    },
    {
        title: 'اطلاعات پایه',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: '',
        subItems: [
            { subtitle: 'مدیریت استان‌ها ', sublink: '/pannel/basicinfo/states' },
            { subtitle: 'مدیریت شهرها ', sublink: '/pannel/basicinfo/cities' },
            { subtitle: 'مدیریت ناوگان ', sublink: '/pannel/basicinfo/cars' },
            { subtitle: 'قوانین', sublink: '/pannel/basicinfo/rules' },
            { subtitle: 'درباره ما', sublink: '/pannel/basicinfo/aboutus' },
            { subtitle: 'اخطاریه', sublink: '/pannel/basicinfo/warning' },
            { subtitle: 'سوالات متداول', sublink: '/pannel/basicinfo/questions' },
            { subtitle: 'اعلان عمومی اپلیکیشن', sublink: '/pannel/basicinfo/publicnotice' },
        ]
    },
    {
        title: 'مدیریت',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: '/pannel/admins',
        subItems: []
    },
    {
        title: 'شوفریاب',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: "",
        subItems: [
            { subtitle: 'همه آگهی‌ها', sublink: '/pannel/Chauffeur' },
            { subtitle: 'آگهی رانندگان', sublink: '/pannel/Chauffeur/driver' },
            { subtitle: 'آگهی صاحبین ماشین', sublink: '/pannel/Chauffeur/carOwner' },
        ]
    },
    {
        title: 'لیست تماس‌ها',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: '/pannel/calllist',
        subItems: []
    },
    {
        title: 'تنظیمات',
        icon: <FaGgCircle className='sidebar-item-icon' />,
        link: '',
        subItems: [
            { subtitle: 'گروه‌های اپلیکیشن', sublink: '' },
            { subtitle: 'بارکپی', sublink: '' },
            { subtitle: 'بارها', sublink: '' },
        ]
    },
]

export { sidebarItemsData }