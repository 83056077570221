import React from 'react';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import InfoListNavigation from '../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation';
import { FaUser } from "react-icons/fa6";
import { FaPhone, FaUserTie } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { Link } from 'react-router-dom';

export default function Management() {
    return (
        <>
            <div className="info-list">
                <Breadcrumbs
                    items={[
                        { name: 'مدیریت', link: '' },
                    ]}
                />
                <InfoListNavigation
                    // onShowModal={setShowAddModal}
                    btntitle="افزودن مدیر جدید"
                    items={[]}
                />
                <div className="info-list-table-section">

                    <table className="info-list-table">
                        <thead>
                            <tr className='info-list-tr-head'>
                                <th>
                                    <FaUser style={{ marginLeft: '3px', fontSize: '13px' }} />
                                    نام و نام‌خانوادگی
                                </th>
                                <th className='td-res-1'>
                                    <FaPhone style={{ marginLeft: '3px', fontSize: '13px' }} />
                                    شماره موبایل
                                </th>
                                <th className='td-res-1'>
                                    <FaUserTie style={{ marginLeft: '3px', fontSize: '13px' }} />
                                    نقش کاربر
                                </th>
                                <th>
                                    <IoMdSettings style={{ marginLeft: '3px', fontSize: '16px' }} />
                                    عملیات
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='info-list-row'>
                                <td>محمد محمدی</td>
                                <td className='td-res-1'>09133322526</td>
                                <td className='td-res-1'>مدیر</td>
                                <td>
                                    <Link style={{ color: 'var(--color-1)', cursor: 'pointer', }} className="link">جزئیات</Link>
                                    <span style={{margin: '0 3px'}}>/</span>
                                    <span style={{ color: 'var(--danger-color)', cursor: 'pointer' }}>حذف</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    )
}
