import React, { useEffect, useState } from 'react';
import './Cities.css';
import Breadcrumbs from '../../../Components/Breadcrumbs/Breadcrumbs';
import InfoListNavigation from '../../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { FaPencil, FaRegTrashCan, FaLocationDot } from "react-icons/fa6";
import axios from "axios";
import { urlVariable } from "../../../Axios/Axios";
import Loading from "../../../Components/Loading/Loading";
import LoadingForBtn from "../../../Components/Loading/LoadingForBtn/LoadingForBtn";
import { IoFilter } from "react-icons/io5";
import { FaCheckCircle } from 'react-icons/fa';
import ToastComp from '../../../Components/Toast/ToastComp';
import { FaChevronUp } from 'react-icons/fa6';
import { FaCircleXmark } from 'react-icons/fa6';
import { FaChevronDown } from 'react-icons/fa6';
import { MdDelete } from "react-icons/md";

export default function Cities() {

    const [loading, setLoading] = useState(false);
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [stateList, setStateList] = useState([])
    const [data, setData] = useState([]);
    const [searchCityShow, setSearchCityShow] = useState('');
    const [searchCityApi, setSearchCityApi] = useState('');
    const [specificStateId, setSpeicificStateId] = useState("");
    const [stateName, setStateName] = useState("")

    console.log("state list : ", stateList);


    // add
    const [showAddModal, setShowAddModal] = useState(false)
    const [addNewCityName, setAddNewCityName] = useState("")
    const [showDropdown, setShowDropdown] = useState('')
    const [city, setCity] = useState('')
    const [loadingToAdd, setLoadingToAdd] = useState(false)
    const [cityID, setCityID] = useState('')    // just use for send to api

    // edit 
    const [stateId, setStateId] = useState("");
    const [showEditModal, setShowEditModal] = useState(false)
    const [newCityName, setNewCityName] = useState("")
    const [updateAfterChange, setUpdateAfterChange] = useState(false);

    // delete
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    // toast
    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState('');
    const [toastBody, setToastBody] = useState('');
    const [toastIcon, setToastIcon] = useState();
    const [toastColor, setToastColor] = useState('');

    useEffect(() => {

        setLoading(true)
        axios.get(`${urlVariable}/api/v1/groups/city`, {
            params: {
                search: searchCityApi
            }
        })
            .then((res) => {
                console.log(res.data);
                setData(res.data.data);
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }, [searchCityApi, updateAfterChange]);

    const finalAdd = () => {
        if (addNewCityName && stateName) {
            setLoadingToAdd(true)
            axios.post(`${urlVariable}/admin/city/add`, {
                name: addNewCityName,
                state: specificStateId
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
                }
            }).then(res => {
                setShowToast(true)
                setStateName("")
                setShowAddModal(false)
                setLoadingToAdd(false)
                setAddNewCityName("")
                setToastTitle("اضافه کردن شهر")
                setToastBody("اضافه کردن شهر با موفقیت انجام شد !")
                setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
                setToastColor(`var(--color-1)`)
                setUpdateAfterChange(prev => !prev)
            }).catch(err => {
                if (err?.response?.status !== 200) {
                    setShowToast(true)
                    setToastTitle("اضافه کردن شهر ")
                    setToastBody(err?.response?.data?.msg || "مشکل در اتصال به اینترنت !")
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')
                    setLoadingToAdd(false)
                }
                if (err?.message === "Network Error")
                    setLoadingToAdd(true)

                console.log(err);
            })
        }
        else {
            setShowToast(true)
            setToastBody("لطفا نام شهر و استان را وارد کنید!")
            setToastTitle("خطا!")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')
            return null
        }
        setLoadingToAdd(true)

    }

    const finalDelete = () => {
        setLoadingToAdd(true)
        axios.delete(`${urlVariable}/admin/city/delete`,
            {
                data: {
                    city: stateId
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
                }
            }).then(res => {
                if (res.status === 200) {
                    setShowDeleteModal(false)
                    setShowToast(true)
                    setUpdateAfterChange(prev => !prev)
                    setToastTitle("حذف شهر")
                    setLoadingToAdd(false)
                    setToastBody("حذف کردن شهر با موفقیت انجام شد !")
                    setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
                    setToastColor(`var(--color-1)`)
                }
            }).catch(err => {
                if (err?.response?.status !== 200) {
                    setShowToast(true)
                    setUpdateAfterChange(prev => !prev)
                    setLoadingToAdd(false)
                    setToastTitle("حذف شهر")
                    setToastBody("حذف کردن شهر ناموفق بود!")
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor(`var(--color-1)`)
                }
                if (err?.message === "Network Error")
                    setLoadingToAdd(true)

            })
    }

    useEffect(() => {
        setLoading(true)
        axios.get(`${urlVariable}/api/v1/groups/states`, {
            params: {
                search: searchCityApi
            }
        })
            .then((res) => {
                setStateList(res.data.data)
                console.log(res.data);
                setLoading(false)
            })
            .catch(err => {
                if (err) {
                    setLoading(true)
                }
            })
    }, [])

    const finalEdit = () => {
        axios.put(`${urlVariable}/admin/city/update`, {
            name: newCityName,
            city: stateId,
            state: specificStateId
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then(res => {
            setShowToast(true)
            setToastTitle("ویرایش شهر")
            setShowEditModal(false)
            setToastBody("ویرایش با موفقیت انجام شد !")
            setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
            setToastColor(`var(--color-1)`)
            setUpdateAfterChange(prev => !prev)
        }).catch(err => {
            if (err) {
                setShowToast(true)
                setToastTitle("ویرایش شهر")
                setToastBody("عملیات ناموفق بود !")
                setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                setToastColor(`var(--color-1)`)
            }
        })
    }

    function selectCity(value) {
        setCityID(value._id.$oid)
        setCity(value.name)
        setShowDropdown('')
        setSearchCityApi("")
        setSearchCityShow("")
    }

    function searchFilter() {
        setSearchCityApi(searchCityShow)
        setShowModalFilter(false)
    }

    function Dropdown(value) {
        if (showDropdown === value)
            setShowDropdown('')
        else setShowDropdown(value)
        setSearchCityApi("")
        setSearchCityShow("")
    }


    return (

        <div className="info-list">
            <Breadcrumbs
                items={[
                    { name: 'اطلاعات پایه', link: '' },
                    { name: 'مدیریت شهرها', link: '/pannel/basicinfo/cities' }
                ]}
            />
            <InfoListNavigation
                onShowModal={() => setShowAddModal(true)}
                btntitle={'افزودن شهر جدید'}
                hasFilter={true}
                onShowFilter={setShowModalFilter}
                items={[]}
            />

            <div className="info-list-table-section">
                <Container className='mx-0'>
                    <Row>
                        {loading ?
                            <Loading />
                            :
                            data.length ?
                                data.map((item) =>
                                    <Col xs={12} sm={6} md={4} lg={3} key={item._id?.$oid}>
                                        <div className="basic-info-card" >
                                            <div className='city-infos'>
                                                <div>
                                                    <div>{item.name}</div>
                                                    <div className='state-name'>
                                                        <FaLocationDot style={{ marginLeft: '2px', fontSize: '11px' }} />
                                                        {item.state[item.state.length - 1]?.name}
                                                    </div>
                                                </div>
                                                <div className='btns-container'>
                                                    <div onClick={() => {
                                                        setShowEditModal(true)
                                                        setStateId(item._id.$oid)
                                                        setNewCityName(item.name)
                                                    }} className="edit-btn-container">
                                                        <FaPencil />
                                                    </div>

                                                    <div className='btns-container'>
                                                        <div onClick={() => {
                                                            setStateId(item._id.$oid)
                                                            setShowDeleteModal(true)
                                                            setNewCityName(item.name)
                                                        }} className="delete-btn-container">
                                                            <MdDelete />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                                :
                                <div>
                                    <Loading />
                                </div>

                        }


                    </Row>
                </Container>



                {/* Toast */}
                <ToastComp
                    title={toastTitle}
                    body={toastBody}
                    icon={toastIcon}
                    onShowToast={setShowToast}
                    showToastValue={showToast}
                    toastColor={toastColor}
                />


                {/* Edit Modal */}
                <Modal show={showEditModal} centered>
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <div className="modal-title-section">
                                <span className="modal-title">ویرایش شهر</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container style={{ textAlign: 'right', fontWeight: 'normal' }}>
                            <div className="modal-dropdown-container">
                                <div className='modal-input-container'>
                                    <label style={{ textAlign: "right" }}>شهر</label>
                                    <div className='modal-dropdown-input-container'>
                                        <input value={stateName} onClick={() => setShowDropdown(prev => !prev)} type="text" readOnly style={{ cursor: "pointer" }} />
                                        <FaChevronDown className='icon' onClick={() => setShowDropdown(prev => !prev)} />
                                    </div>
                                </div>
                                {
                                    showDropdown &&
                                    <div>
                                        {
                                            loading ? (
                                                <LoadingForBtn />
                                            ) : (
                                                <>
                                                    {
                                                        stateList.length ? (
                                                            <ul className="modal-dropdown-list">
                                                                <input className='search-dropdown-input' autoComplete="off" placeholder="جست و جو ..." value={searchCityShow}
                                                                    onChange={(e) => setSearchCityShow(e.target.value)} />
                                                                {
                                                                    stateList.filter(i => i.name.includes(searchCityShow)).map((item, index) => (
                                                                        <li
                                                                            onClick={() => {
                                                                                setSpeicificStateId(item._id.$oid)
                                                                                setShowDropdown(prev => !prev)
                                                                                setStateName(item.name)
                                                                            }}
                                                                            style={{ textAlign: "right" }}
                                                                            key={index}
                                                                        >
                                                                            {item.name}
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        ) : (
                                                            <div>
                                                                نتیجه ای یافت نشد
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                            <Row>
                                <Col xs={12}>
                                    <div className="modal-dropdown-container">
                                        <div className='modal-input-container'>
                                            <label>نام شهر</label>
                                            <div className='modal-dropdown-input-container'>
                                                <input value={newCityName} onChange={(e) => setNewCityName(e.target.value)} type="text" style={{ cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <button className='modal-btn modal-cancle-btn' onClick={() => {
                            setShowEditModal(false)
                            setLoadingToAdd(false)
                        }}>بازگشت</button>
                        <button onClick={() => {
                            finalEdit()
                            setNewCityName("")
                        }}
                            className='modal-btn modal-green-btn'>
                            {loadingToAdd ? <LoadingForBtn /> : "تایید"}
                        </button>
                    </Modal.Footer>
                </Modal>



                {/* delete modal  */}
                <Modal
                    show={showDeleteModal}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <div className="modal-title-section">
                                <span className="modal-title">حذف شهر</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div style={{ width: "100%" }}>
                            <p style={{ textAlign: "right" }}>آیا از حذف کردن شهر <span style={{ color: "var(--color-1)" }}>{newCityName} </span>اطمینان دارید ؟</p>
                        </div>

                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <button className='modal-btn modal-cancle-btn' onClick={() => {
                            setShowDeleteModal(false)
                            setLoadingToAdd(false)
                        }}>انصراف
                        </button>
                        <button className='modal-btn modal-red-btn' onClick={finalDelete}>

                            <>
                                <MdDelete className='icon' style={{ marginLeft: '4px', fontSize: '15px' }} />
                                {loadingToAdd ? <LoadingForBtn /> : "حذف شهر"}
                            </>

                        </button>
                    </Modal.Footer>
                </Modal>



                {/*----------------- filter modal -----------------------*/}
                <Modal
                    show={showModalFilter}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <div className="modal-title-section">
                                <span className="modal-title">فیلتر شهرهای استان</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="container">
                            <div className="row">
                                <div className="col">

                                    <div className='modal-input-container'>
                                        <label style={{ textAlign: "right" }}>
                                            شهر
                                        </label>
                                        <input placeholder="شهر مورد نظر خود را جست و جو کنید" value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)} />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <button className='modal-btn modal-cancle-btn' onClick={() => { setShowModalFilter(false); setSearchCityApi(""); setSearchCityShow("") }}>لغو فیلتر
                        </button>
                        <button className='modal-btn modal-green-btn' onClick={searchFilter}>

                            <>
                                <IoFilter className='icon' style={{ marginLeft: '4px', fontSize: '15px' }} />
                                اعمال فیلتر</>

                        </button>
                    </Modal.Footer>
                </Modal>

                {/* add cities  */}

                <Modal
                    show={showAddModal}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <div className="modal-title-section">
                                <span className="modal-title">افزودن شهر جدید</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-dropdown-container"

                        >
                            <div className="modal-dropdown-container">
                                <div className='modal-input-container'>
                                    <label style={{ textAlign: "right" }}>شهر</label>
                                    <div className='modal-dropdown-input-container'>
                                        <input value={stateName} onClick={() => setShowDropdown(prev => !prev)} type="text" readOnly style={{ cursor: "pointer" }} />
                                        <FaChevronDown className='icon' onClick={() => setShowDropdown(prev => !prev)} />
                                    </div>
                                </div>
                                {
                                    showDropdown &&
                                    <div>
                                        {
                                            loading ? (
                                                <LoadingForBtn />
                                            ) : (
                                                <>
                                                    {
                                                        stateList.length ? (
                                                            <ul className="modal-dropdown-list">
                                                                <input className='search-dropdown-input' autoComplete="off" placeholder="جست و جو ..." value={searchCityShow}
                                                                    onChange={(e) => setSearchCityShow(e.target.value)} />
                                                                {
                                                                    stateList.filter(i => i.name.includes(searchCityShow)).map((item, index) => (
                                                                        <li
                                                                            onClick={() => {
                                                                                setSpeicificStateId(item._id.$oid)
                                                                                setShowDropdown(prev => !prev)
                                                                                setStateName(item.name)
                                                                            }}
                                                                            style={{ textAlign: "right" }}
                                                                            key={index}
                                                                        >
                                                                            {item.name}
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        ) : (
                                                            <div>
                                                                نتیجه ای یافت نشد
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                            <Row>
                                <div className="col-sm">
                                    <div className='modal-input-container'>
                                        <label style={{ textAlign: "right" }}>
                                            نام شهر
                                        </label>
                                        <input type='text' onChange={(e) => setAddNewCityName(e.target.value)} value={addNewCityName} />
                                    </div>
                                </div>
                            </Row>

                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <button className='modal-btn modal-cancle-btn' onClick={() => {
                            setShowAddModal(false)
                            setLoadingToAdd(false)
                        }}>لغو
                        </button>
                        <button onClick={finalAdd}
                            className='modal-btn modal-green-btn'>
                            {loadingToAdd ? <LoadingForBtn /> : "تایید"}
                        </button>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>
    )
}
