import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs";
import InfoListNavigation from "../../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation";
import {
    FaCircleXmark,
    FaTrash,
    FaFileCircleCheck,
    FaFileCircleXmark,
    FaBus,
    FaChevronDown, FaChevronUp
} from "react-icons/fa6";
import {
    FaArrowCircleDown,
    FaArrowCircleUp, FaCheckCircle, FaEdit, FaPhone
} from "react-icons/fa";
import { GrStatusInfo } from "react-icons/gr";

import { MdDateRange } from "react-icons/md";
import {IoMdClose, IoMdSettings} from "react-icons/io";
import ToastComp from "../../../Components/Toast/ToastComp";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {handleErrors, urlVariable} from '../../../Axios/Axios';
import axios from 'axios';
import Loading from '../../../Components/Loading/Loading';
import LoadingForBtn from '../../../Components/Loading/LoadingForBtn/LoadingForBtn';
import {IoFilter} from "react-icons/io5";


function BareCopy() {

    const location = useLocation();

    // Toast State
    const [showToast, setShowToast] = useState(false)
    const [bodyToast, setBodyToast] = useState("")
    const [showSuccessfulToast, setShowSuccessfulToast] = useState(false)

    // Modal States
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showFilterIcon, setShowFilterIcon] = useState(false)

    // Loading State
    const [loading, setLoading] = useState(false)
    const [loadingBTN, setLoadingBTN] = useState(false)

    // Dropdown State
    const [showDropDown, setShowDropDown] = useState("")
    const [loadingDropdown, setLoadingDropdown] = useState(false)

    const [refreshData, setRefreshData] = useState(false)

    const [cityName, setCityName] = useState([])
    const [searchCityShow, setSearchCityShow] = useState("")
    const [searchCityApi, setSearchCityApi] = useState("")
    const [groupName, setGroupName] = useState([])
    const [typeCarName, setTypeCarName] = useState([])


    // value for filter api
    const [originFilter, setOriginFilter] = useState({
        show: "",
        id: ""
    })
    const [originIDFilterAPi, setOriginIDFilterAPi] = useState()
    const [destinationFilter, setDestinationFilter] = useState({
        show: "",
        id: ""
    })
    const [destinationIDFilterApi, setDestinationIDFilterApi] = useState()
    const [typeCarFilter, setTypeCarFilter] = useState({
        show: "",
        id: ""
    })
    const [typeCarIDFilterApi, setTypeCarIDFilterApi] = useState()



    // value for edit and show
    const [message, setMessage] = useState("")
    const [origin, setOrigin] = useState("")
    const [destination, setDestination] = useState("")
    const [carType, setCarType] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [socialApp, setSocialApp] = useState("")
    const [date, setDate] = useState('')

    const [originID, setOriginID] = useState("")
    const [destinationID, setDestinationID] = useState("")
    const [TypeCarID, setTypeCarID] = useState("")


    // Get Cargo
    const [barData, setBarData] = useState([])
    const [statusUrl, setStatusUrl] = useState("active")
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [totalData, setTotalData] = useState()



    useEffect(() => {

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/groups/city`, {
            params: {
                search: searchCityApi
            }
        })
            .then((res) => {
                setCityName(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingDropdown(true) //دلیل خط 117
            })

    }, [searchCityApi])


    useEffect(() => {

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/car/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        })
            .then((res) => {
                setTypeCarName(res.data.data);
                console.log(res.data.data)
                setLoadingDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingDropdown(true)
            })

    }, [])

    useEffect(() => {

        if (showFilterModal) {
            setOrigin("")
            setDestination("")
            setOriginID("")
            setDestinationID("")
            setTypeCarID('')
            setCarType("")
            setShowDropDown("")
            setSearchCityShow("")
            setSearchCityApi("")
        }

    }, [showFilterModal]);

    useEffect(() => {
        setLoading(true)
        axios.get(`${urlVariable}/admin/cargo`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            },
            params:
                {
                    pagination: true,
                    limit: limit,
                    page: page,
                    source: "bot",
                    status: statusUrl,
                    origin: originIDFilterAPi,
                    destination: destinationIDFilterApi,
                    typeCar: typeCarIDFilterApi,
                }
        })
            .then((res) => {
                console.log(res);
                setBarData(res.data.data);
                setLoading(false)
                setTotalPage(res.data.total_pages)
                setTotalData(res.data.total_count)
            })
            .catch(err => {
                handleErrors(err)
                if (err.message === "Network Error") {
                    setShowToast(true)
                    setBodyToast("لطفا اینترنت خود را بررسی کنید!..")
                    setShowSuccessfulToast(false)
                }
                if (err.response && err.response.status <= 499) {
                    setShowToast(true)
                    setBodyToast("لطفا دوباره تلاش کنید!..")
                    setShowSuccessfulToast(false)
                } else {
                    if (err.response && err.response.status >= 500) {
                        setShowToast(true)
                        setBodyToast("در سرور به وحود آمده است. لطفا دوباره تلاش کنید!..")
                        setShowSuccessfulToast(false)
                    }
                }
                setLoading(false)
            })
    }, [page, statusUrl, refreshData, destinationIDFilterApi, originIDFilterAPi, typeCarIDFilterApi])

    useEffect(() => {

        if ((page > totalPage) && (totalPage > 0))
            setPage(totalPage)

    }, [totalPage]);


    useEffect(() => {

        const delayedSetData = setTimeout(() => {
            setSearchCityApi(searchCityShow)
        }, 800)

        return () => clearTimeout(delayedSetData);

    }, [searchCityShow]);


    const [id, setId] = useState("")

    function Delete(iteam) {
        setId(iteam._id.$oid)
        setShowDeleteModal(true)
    }

    function deleteBarAPi() {

        setLoadingBTN(true)
        axios.delete(`${urlVariable}/admin/cargo/delete`, {
            data: {
                cargo_id: id,
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then((res) => {
            console.log(res)
            setShowToast(true)
            setBodyToast(res.data.msg)
            setShowSuccessfulToast(true)
            setShowDeleteModal(false)
            setLoadingBTN(false)
            setRefreshData(!refreshData)
        }).catch((err) => {
            console.log(err)
            if (err.message === "Network Error") {
                setBodyToast("اینترنت خود را بررسی کنید!..")
                setShowToast(true)
                setShowSuccessfulToast(false)
                setShowDeleteModal(false)
                setLoadingBTN(false)
            }
            if (err.response && err.response.status <= 499) {
                setBodyToast(err.response.data.msg)
                setShowToast(true)
                setShowSuccessfulToast(false)
                setShowDeleteModal(false)
                setLoadingBTN(false)
            } else {
                if (err.response && err.response.status >= 500) {
                    setBodyToast("مشکلی در سرور پیش آمد است. لطفا دوباره تلاش کنید")
                    setShowToast(true)
                    setShowSuccessfulToast(false)
                    setShowDeleteModal(false)
                    setLoadingBTN(false)
                } else {
                    setBodyToast(" لطفا دوباره تلاش کنید")
                    setShowToast(true)
                    setShowSuccessfulToast(false)
                    setShowDeleteModal(false)
                    setLoadingBTN(false)
                }
            }

        })

    }


    function Edit(item) {

        console.log(item)

        setId(item._id?.$oid)
        setMessage(item.message)
        setOrigin(item.origin_detail)
        setDestination(item.destination_detail)
        setCarType(item.carType_detail)
        setSocialApp(item.social_app)
        setDate(formatTimestamp(item.date))
        setPhoneNumber(item.phone_number || item.phoneNumber)
        setShowEditModal(true)
        setShowDropDown('')

        typeCarName.some((value) => {
            if (item.carType_detail === value.name) {
                setTypeCarID(value._id?.$oid)
                return true
            }
        })

        cityName.some((value) => {
            if (item.origin_detail === value.name) {
                setOriginID(value._id?.$oid)
                return true
            }
        })

        cityName.some((value) => {
            if (item.destination_detail === value.name) {
                setDestinationID(value._id?.$oid)
                return true
            }
        })


    }

    function EditForApi() {

        if (phoneNumber.length === 11) {

            const info = {
                _id: id,
                origin: originID,
                destination: destinationID,
                CarType: TypeCarID,
                phoneCall: phoneNumber,
            }
            console.log(info)
            setLoadingBTN(true)
            axios.put(`${urlVariable}/admin/cargo/edit`, info, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
                }
            }).then((res) => {
                setShowToast(true)
                setBodyToast(res.data.msg)
                setShowSuccessfulToast(true)
                setShowEditModal(false)
                setLoadingBTN(false)
                setRefreshData(!refreshData)
            }).catch((err) => {
                handleErrors(err)
                if (err.message === "Network Error") {
                    console.log(err)
                    setBodyToast("اینترنت خود را بررسی کنید!..")
                    setShowToast(true)
                    setShowSuccessfulToast(false)
                    setShowEditModal(true)
                    setLoadingBTN(false)
                }
                if (err.response && err.response.status <= 499) {
                    setBodyToast(err.response.data.msg)
                    setShowToast(true)
                    setShowSuccessfulToast(false)
                    setShowEditModal(true)
                    setLoadingBTN(false)
                } else {
                    if (err.response && err.response.status >= 500) {
                        setBodyToast("مشکلی در سرور پیش آمد است. لطفا دوباره تلاش کنید")
                        setShowToast(true)
                        setShowSuccessfulToast(false)
                        setShowEditModal(true)
                        setLoadingBTN(false)
                    } else {
                        setBodyToast(" لطفا دوباره تلاش کنید")
                        setShowToast(true)
                        setShowSuccessfulToast(false)
                        setShowEditModal(true)
                        setLoadingBTN(false)
                    }
                }
            })

        } else {
            setBodyToast("شماره تلفن به صورت صحیح وارد کنید")
            setShowToast(true)
            setShowSuccessfulToast(false)
            setShowEditModal(true)
            setLoadingBTN(false)
        }
    }


    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ' ';
        return new Date(timestamp).toLocaleDateString("fa-IR")
    };


    function selectOrigin(value) {
        setOriginID(value._id.$oid)
        setOrigin(value.name)
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")

    }

    function selectDestination(value) {
        setDestinationID(value._id.$oid)
        setDestination(value.name)
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")
    }

    function selectTypeCar(value) {
        setTypeCarID(value._id.$oid)
        setCarType(value.name);
    }

    function dropdown(value) {
        if (showDropDown === value)
            setShowDropDown("")
        else setShowDropDown(value)
        setSearchCityShow("")
        setSearchCityApi("")
    }


    function selectOriginFilter (item) {
        setOriginFilter({
            show: item.name,
            id: item._id.$oid
        })
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")
    }


    function selectDestinationFilter (item) {
        setDestinationFilter({
            show: item.name,
            id: item._id.$oid
        })
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")
    }

    function selectTypeCarFilter (item) {
        setTypeCarFilter({
            show: item.name,
            id: item._id.$oid,
        })
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")
    }

    function setFilterForApi () {


        if (originFilter.id)
            setOriginIDFilterAPi(originFilter.id)
        else setOriginIDFilterAPi()
        if (destinationFilter.id)
            setDestinationIDFilterApi(destinationFilter.id)
        else setDestinationIDFilterApi()
        if (typeCarFilter.id)
            setTypeCarIDFilterApi(typeCarFilter.id)
        else setTypeCarIDFilterApi()

        if (originFilter.id || destinationFilter.id || typeCarFilter.id) {
            setShowFilterIcon(true)
            setShowFilterModal(false)
        }else setShowFilterIcon(false)
    }


    function removeAllFilter () {


        setOriginFilter({
            show: "",
            id: "",
        })
        setDestinationFilter({
            show: "",
            id: "",
        })
        setTypeCarFilter({
            show: "",
            id: "",
        })

        setOriginIDFilterAPi()
        setDestinationIDFilterApi()
        setTypeCarIDFilterApi()
        setShowFilterIcon(false)
        setShowFilterModal(false)
    }



    return (
        <>
            <div className="info-list">

                <Breadcrumbs
                    items={[{name: 'بارها', link: '/pannel/message/fromUser'},
                        {name: 'بارکپی', link: '/pannel/message/barecopy'}]}
                />

                <div className='platform-btns-container'>
                    <Link className='link' to='/pannel/message/barecopy'>
                        <button className={location.pathname === '/pannel/message/barecopy' && 'active'}>افزوده شده به
                            برنامه
                        </button>
                    </Link>
                    <Link className='link' to='/pannel/message/barecopy/notok'>
                        <button className={location.pathname === '/pannel/message/barecopy/notok' && 'active'}>نیاز به
                            بررسی
                        </button>
                    </Link>
                </div>

                <InfoListNavigation
                    // btntitle='افزودن بار جدید'
                    // onShowModal={setShowAddModal}
                    // btnLink='/pannel/message/fromUser'
                    // displayBtn={"none"}
                    hasFilter={true}
                    hasFilterShow={showFilterIcon}
                    onShowFilter={setShowFilterModal}
                    setStatus={setStatusUrl}
                    status={statusUrl}
                    items={[
                        ['بارهای فعال', 'active', <FaFileCircleCheck className='icon'/>],
                        ['بارهای غیرفعال', 'delete', <FaFileCircleXmark className='icon'/>]
                    ]}
                />

                <div className="info-list-table-section">
                    <table className='info-list-table'>
                        <thead>
                        <tr className='info-list-tr-head'>
                            <th>
                                <FaArrowCircleDown style={{marginLeft: '3px', fontSize: '13px'}}/>
                                مبدا
                            </th>
                            <th>
                                <FaArrowCircleUp style={{marginLeft: '3px', fontSize: '13px'}}/>
                                مقصد
                            </th>
                            <th>
                                <FaBus style={{marginLeft: '3px', fontSize: '13px'}}/>
                                نوع ناوگان
                            </th>
                            <th className='td-res-1'>
                                <FaPhone style={{marginLeft: '3px', fontSize: '13px'}}/>
                                شماره تلفن
                            </th>
                            <th className='td-res-1'>
                                <MdDateRange style={{marginLeft: '3px', fontSize: '13px'}}/>
                                تاریخ
                            </th>
                            <th className='td-res-1'>
                                <GrStatusInfo style={{marginLeft: '3px', fontSize: '13px'}}/>
                                وضعیت
                            </th>
                            <th>
                                <IoMdSettings style={{marginLeft: '3px', fontSize: '16px'}}/>
                                عملیات
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading ?
                            (<tr>
                                <td colSpan="7"><Loading/></td>
                            </tr>)
                            :
                            barData.length ?
                                barData.map((iteam, index) => {
                                        return (
                                            <tr key={index} className='info-list-row'>
                                                <td>{iteam.origin_detail}</td>
                                                <td>{iteam.destination_detail}</td>
                                                <td >{iteam.carType_detail}</td>
                                                <td className='td-res-1'>{iteam.phone_number || iteam.phoneNumber}</td>
                                                <td className='td-res-1'>{formatTimestamp(iteam.date)}</td>
                                                <td className='td-res-1'>
                                                    {iteam.status === "active" ?
                                                        <span style={{color: "var(--color-4)"}}>فعال</span>
                                                        :
                                                        <span style={{color: "var(--color-3)"}}>غیر فعال</span>
                                                    }
                                                </td>
                                                <td>
                                                    <span style={{color: 'var(--color-1)', cursor: 'pointer'}}
                                                          onClick={() => Edit(iteam)}>ویرایش</span>
                                                    {iteam.status === "active" &&
                                                        <>
                                                            <span style={{margin: '0 3px'}}>/</span>
                                                            <span style={{
                                                                color: 'var(--danger-color)',
                                                                cursor: 'pointer'
                                                            }}
                                                                  onClick={() => Delete(iteam)}
                                                            >حذف</span>
                                                        </>
                                                    }

                                                </td>
                                            </tr>
                                        )
                                    }
                                )
                                :
                                <tr>
                                    <td colSpan="7" style={{
                                        textAlign: 'center',
                                        padding: '10px 0',
                                        backgroundColor: 'var(--bg-color-2)'
                                    }}>در حال حاضر باری نداریم!
                                    </td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>

                {/* Pagination Section */}
                {totalData > limit ?
                    <div className="pagination-section">
                        <button className='pagination-btn' disabled={page <= 1}
                                style={page === 1 ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page - 1)}>قبلی
                        </button>
                        <div>صفحه {page} از {totalPage}</div>
                        <button className='pagination-btn' disabled={page >= totalPage}
                                style={page === totalPage ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page + 1)}>بعدی
                        </button>
                    </div>
                    : null}

            </div>

            {/* Toast */}
            <ToastComp
                title={showSuccessfulToast ? "عملیات موفق" : "عملیات ناموفق"}
                body={bodyToast}
                icon={showSuccessfulToast ? <FaCheckCircle style={{marginLeft: '5px'}}/> :
                    <FaCircleXmark style={{marginLeft: '5px'}}/>}
                onShowToast={setShowToast}
                showToastValue={showToast}
                toastColor={showSuccessfulToast ? 'var(--color-1)' : 'var(--danger-color)'}
            />


            {/* Delete Modal */}
            <Modal
                show={showDeleteModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">حذف بار</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    آیا از حذف این بار اطمینان دارید؟
                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-red-btn' onClick={deleteBarAPi}>
                        {loadingBTN ? <LoadingForBtn sytle={{fontSize: "10px"}}/> : (<> <FaTrash className='icon'
                                                                                                 style={{
                                                                                                     marginLeft: '4px',
                                                                                                     fontSize: '15px'
                                                                                                 }}/> حذف</>)}
                    </button>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowDeleteModal(false)}>لغو
                    </button>

                </Modal.Footer>
            </Modal>


            <Modal
                show={showFilterModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">فیلتر بار</span>
                            <span className="hover-close-model"
                                  onClick={() => setShowFilterModal(false)}><IoMdClose/></span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">


                        <div className="row">

                            <div className="col">

                                <div className="modal-dropdown-container">

                                    <div className='modal-input-container' onClick={() => dropdown("origin")}>
                                        <label style={{textAlign: "right"}}>
                                            مبدا
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input  value={originFilter.show}/>
                                            {showDropDown === "origin" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "origin" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            <input className='search-dropdown-input' autoComplete="off"
                                                   placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ?
                                                    cityName.map((item, index) => (
                                                        <li key={index} onClick={() => selectOriginFilter(item)}>
                                                            {item.name}
                                                        </li>
                                                    ))
                                                    :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>

                                        : null}

                                </div>

                            </div>

                            <div className="col">

                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container' onClick={() => dropdown("destination")}>
                                        <label style={{textAlign: "right"}}>
                                            مقصد
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={destinationFilter.show}/>
                                            {showDropDown === "destination" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "destination" ?
                                        <ul className="modal-dropdown-list"
                                            style={{textAlign: "right", height: "10rem"}}>
                                            <input className='search-dropdown-input' autoComplete="off"
                                                   placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ? cityName.map((item, index) => (
                                                        <li key={index} onClick={() => selectDestinationFilter(item)}>
                                                            {item.name}
                                                        </li>
                                                    ))
                                                    :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>
                                        : null}
                                </div>

                            </div>

                        </div>
                        <div className="row">

                            <div className="col">

                                <div className="modal-dropdown-container" onClick={() => dropdown("typeCar")}>
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            نوع ناوگان
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={typeCarFilter.show}/>
                                            {showDropDown === "typeCar" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>
                                    {showDropDown === "typeCar" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                (typeCarName && typeCarName.map((item, index) => (
                                                    <li key={index} onClick={() => selectTypeCarFilter(item)}>
                                                        {item.name}
                                                    </li>
                                                )))}
                                        </ul>
                                        : null}

                                </div>

                            </div>
                        </div>


                    </div>

                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={removeAllFilter}>حذف فیلترها
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={setFilterForApi}>
                        <IoFilter className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>
                        اعمال فیلتر

                    </button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal */}
            <Modal show={showEditModal} centered>
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">ویرایش بار</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{textAlign: 'right', fontWeight: 'normal'}}>
                        <Row>
                            <Col xs={12}>
                                <div className='modal-input-container'>
                                    <label>متن پیام</label>
                                    <textarea rows={5} value={message}></textarea>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="modal-dropdown-container">

                                    <div className='modal-input-container' onClick={() => dropdown("origin")}>
                                        <label style={{textAlign: "right"}}>
                                            مبدا
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={origin}/>
                                            {showDropDown === "origin" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "origin" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            <input className='search-dropdown-input' autoComplete="off"
                                                   placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ?
                                                    cityName.map((item, index) => (
                                                        <li key={index} onClick={() => selectOrigin(item)}>
                                                            {item.name}
                                                        </li>
                                                    ))
                                                    :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>

                                        : null}

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container' onClick={() => dropdown("destination")}>
                                        <label style={{textAlign: "right"}}>
                                            مقصد
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={destination}/>
                                            {showDropDown === "destination" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "destination" ?
                                        <ul className="modal-dropdown-list"
                                            style={{textAlign: "right", height: "10rem"}}>
                                            <input className='search-dropdown-input' autoComplete="off"
                                                   placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ? cityName.map((item, index) => (
                                                        <li key={index} onClick={() => selectDestination(item)}>
                                                            {item.name}
                                                        </li>
                                                    ))
                                                    :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>
                                        : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="modal-dropdown-container" onClick={() => dropdown("typeCar")}>
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            نوع ناوگان
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={carType}/>
                                            {showDropDown === "typeCar" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>
                                    {showDropDown === "typeCar" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                (typeCarName && typeCarName.map((item, index) => (
                                                    <li key={index} onClick={() => selectTypeCar(item)}>
                                                        {item.name}
                                                    </li>
                                                )))}
                                        </ul>
                                        : null}

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className='modal-input-container'>
                                    <label>شماره موبایل</label>
                                    <input type="text" value={phoneNumber}
                                           onChange={(e) => setPhoneNumber(e.target.value)}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className='modal-input-container'>
                                    <label>پلتفرم</label>
                                    <input type="text" value={socialApp}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className='modal-input-container'>
                                    <label>تاریخ</label>
                                    <input type="text" value={date}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowEditModal(false)}>بازگشت
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={EditForApi}>
                        {loadingBTN ?
                            <LoadingForBtn/>
                            :
                            <>
                                <FaEdit className='icon'/>
                                ویرایش
                            </>
                        }

                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )

}


export default BareCopy;