import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs";
import InfoListNavigation from "../../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation";
import {
    FaCircleXmark,
    FaTrash,
    FaFileCircleCheck,
    FaFileCircleXmark,
    FaBus,
    FaChevronDown
} from "react-icons/fa6";
import {
    FaArrowCircleDown,
    FaPencilAlt,
    FaArrowCircleUp,
} from "react-icons/fa";
import {IoMdSettings} from "react-icons/io";
import ToastComp from "../../../Components/Toast/ToastComp";
import {Container, Row, Col, Modal, ToastBody} from "react-bootstrap";
import {handleErrors, urlVariable} from '../../../Axios/Axios';
import axios from 'axios';
import Loading from '../../../Components/Loading/Loading';
import LoadingForBtn from '../../../Components/Loading/LoadingForBtn/LoadingForBtn';






function BareCopyNotOk() {

    const location = useLocation();

    // Toast State
    const [showToast, setShowToast] = useState(false)
    const [toastTitle, setToastTitle] = useState('')
    const [toastBody, setToastBody] = useState('')
    const [toastIcon, setToastIcon] = useState()
    const [toastColor, setToastColor] = useState('')

    // Flag State
    const [flag, setFlag] = useState(false)

    // Modal States
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)

    // Loading State
    const [loading, setLoading] = useState(false)
    const [loadingDeleteBTN, setLoadingDeleteBTN] = useState(false)
    const [loadingCitiesDropdown, setLoadingCitiesDropdown] = useState(false)
    const [loadingCarDropdown, setLoadingCarDropdown] = useState(false)

    // Dropdown State
    const [showOriginDropdown, setShowOriginDropdown] = useState(false)
    const [showDestinationDropdown, setShowDestinationDropdown] = useState(false)
    const [showCarDropdown, setShowCarDropdown] = useState(false)

    // Get Cargo
    const [barData, setBarData] = useState([])
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState("")
    const [totalData, setTotalData] = useState('')


    useEffect(() => {
        setLoading(true)
        axios.get(`${urlVariable}/admin/cargo/get/notcomplited`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            },
            params: {
                pagination: true,
                limit: limit,
                page: page,
            }
        })
            .then((res) => {
                console.log(res);
                setBarData(res.data.data);
                setTotalPage(res.data.total_pages)
                setTotalData(res.data.total_count)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                setToastTitle('عملیات ناموفق')
                setToastBody('خطا در اتصال اینترنت')
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setShowToast(true)
            })
    }, [flag, page])

    // Get Cities
    const [allCities, setAllCities] = useState([])
    const [searchCityShow, setSearchCityShow] = useState("")
    const [searchCityApi, setSearchCityApi] = useState("")


    useEffect(() => {
        setLoadingCitiesDropdown(true)
        axios.get(`${urlVariable}/api/v1/groups/city`,
            {
                params: {
                    search: searchCityApi
                }
            }
        )
            .then((res) => {
                setAllCities(res.data.data);
                setLoadingCitiesDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingCitiesDropdown(true)
            })
    }, [searchCityApi])



    useEffect(() => {

        const delayedSetData = setTimeout(() => {
            setSearchCityApi(searchCityShow)
        }, 800)

        return () => clearTimeout(delayedSetData);

    }, [searchCityShow]);


    useEffect(() => {

        if ((page > totalPage) && (totalPage > 0))
            setPage(totalPage)

    }, [totalPage]);


    // Get Car Types
    const [allCars, setAllCars] = useState([])
    useEffect(() => {
        setLoadingCarDropdown(true)
        axios.get(`${urlVariable}/api/v1/car/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        })
            .then((res) => {
                console.log(res.data.data);
                setAllCars(res.data.data);
                setLoadingCarDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingCarDropdown(true)
            })
    }, [])

    // Edit Cargo
    const [IdCargo, setIdCargo] = useState('')
    const [message, setMessage] = useState('')
    const [originText, setOriginText] = useState('')
    const [originId, setOriginId] = useState('')
    const [destinationText, setDestinationText] = useState('')
    const [destinationId, setDestinationId] = useState('')
    const [phoneNum, setPhoneNum] = useState('')
    const [socialAppText, setSocialAppText] = useState('')
    const [carTypeText, setCarTypeText] = useState('')
    const [carTypeId, setCarTypeId] = useState('')
    const [messageDate, setMessageDate] = useState('')



    function editCargoHandler(value) {
        console.log(value);
        setShowEditModal(true)
        setIdCargo(value._id.$oid)
        setMessage(value.message)
        setOriginText(value.origin_detail ?? 'نامشخص')
        setOriginId(value.origin)
        setDestinationText(value.destination_detail ?? 'نامشخص')
        setDestinationId(value.destination)
        setPhoneNum(value.phone_number  ?? 'نامشخص')
        if (value.social_app === "rubika") {
            setSocialAppText('روبیکا')
        } else if (value.social_app === "Telegram") {
            setSocialAppText('تلگرام')
        } else if (value.social_app === "eita") {
            setSocialAppText('ایتا')
        }
        setCarTypeText(value.transit_type ?? 'نامشخص')
        setCarTypeId(value.transit_type)
        setMessageDate(value.date)
    }

    function setOriginHandler(id, name) {
        setOriginId(id)
        setOriginText(name)
        setShowOriginDropdown(false)
        setSearchCityShow("")
        setSearchCityApi("")
    }

    function setDestinationHandler(id, name) {
        setDestinationId(id)
        setDestinationText(name)
        setShowDestinationDropdown(false)
        setSearchCityShow("")
        setSearchCityApi("")
    }

    function setCarTypeHandler(id, name) {
        setCarTypeId(id)
        setCarTypeText(name)
        setShowCarDropdown(false)
    }

    function editCargoApiHandler() {

        const updatedCargo = {
            _id: IdCargo,
            origin: originId,
            destination: destinationId,
            CarType: carTypeId,
            phoneNumber: phoneNum
        }

        axios.put(`${urlVariable}/admin/cargo/edit/notcomplited`,
            updatedCargo,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
                }
            }
        )
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    setShowToast(true)
                    setToastTitle("عملیات موفق")
                    setToastBody("بار با موفقیت ویرایش شد!")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--color-1)')
                    setShowEditModal(false)
                    setFlag(prev => !prev)
                }
            })
            .catch(err => {
                handleErrors(err)
                console.log(err)
                if (err.message === "Network Error") {
                    setToastTitle('عملیات ناموفق')
                    setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')
                    setShowToast(true)
                }
                if (err.response && err.response.status <= 499) {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody(err.response.data.msg)
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')
                } else {
                    if (err.response && err.response.status >= 500) {
                        setShowToast(true)
                        setToastTitle("عملیات ناموفق")
                        setToastBody("خطای در سرور رخ داده است لطفا دوباره تلاش کنید!..")
                        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                        setToastColor('var(--danger-color)')
                    } else {
                        setShowToast(true)
                        setToastTitle("عملیات ناموفق")
                        setToastBody("خطا در بارگیری اطلاعات")
                        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                        setToastColor('var(--danger-color)')
                    }
                }
                // setToastTitle('عملیات ناموفق')
                // setToastBody('خطا در ویرایش اطلاعات بار')
                // setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                // setToastColor('var(--danger-color)')
                // setShowToast(true)
            })
    }

    // Delete Cargo
    const [id, setId] = useState("")

    function Delete(iteam) {
        setId(iteam._id.$oid)
        setShowDeleteModal(true)
    }

    function deleteBarAPi() {
        setLoadingDeleteBTN(true)
        axios.delete(`${urlVariable}/admin/cargo/delete`, {
            data: {
                cargo_id: id,
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })
    }


    return (
        <>
            <div className="info-list">
                <Breadcrumbs
                    items={[{name: 'بارها', link: '/pannel/message/fromUser'},
                        {name: 'بارکپی', link: '/pannel/message/barecopy'}]}
                />
                <div className='platform-btns-container mb-4'>
                    <Link className='link' to='/pannel/message/barecopy'>
                        <button className={location.pathname === '/pannel/message/barecopy' && 'active'}>افزوده شده به
                            برنامه
                        </button>
                    </Link>
                    <Link className='link' to='/pannel/message/barecopy/notok'>
                        <button className={location.pathname === '/pannel/message/barecopy/notok' && 'active'}>نیاز به
                            بررسی
                        </button>
                    </Link>
                </div>

                <InfoListNavigation

                    items={[
                        ['نیاز به تایید', 'active', <FaFileCircleCheck className='icon'/>],
                        ['بدون اطلاعات', 'delete', <FaFileCircleXmark className='icon'/>]
                    ]}
                />

                <div className="info-list-table-section">
                    <table className='info-list-table'>
                        <thead>
                        <tr className='info-list-tr-head'>
                            <th>
                                <FaArrowCircleDown style={{marginLeft: '3px', fontSize: '13px'}}/>
                                پیام
                            </th>
                            <th>
                                <IoMdSettings style={{marginLeft: '3px', fontSize: '16px'}}/>
                                عملیات
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading ?
                            (<tr>
                                <td colSpan="2"><Loading/></td>
                            </tr>)
                            :
                            (barData && barData.map((iteam, index) => {
                                    return (
                                        <tr key={index} className='info-list-row'>
                                            <td>{iteam.message?.length > 70 ? (iteam.message.slice(0, 80) + ' ...' ?? "پیام نامشحض") : (iteam.message ?? "پیام نامشخص")}</td>
                                            <td>
                                                <span style={{color: 'var(--color-1)', cursor: 'pointer'}}
                                                      onClick={() => editCargoHandler(iteam)}>ویرایش</span>
                                                <span style={{margin: '0 3px'}}>/</span>
                                                <span style={{color: 'var(--danger-color)', cursor: 'pointer'}}
                                                      onClick={() => Delete(iteam)}
                                                >حذف</span>
                                            </td>
                                        </tr>
                                    )
                                }
                            ))
                        }
                        </tbody>
                    </table>
                </div>

                {/* Pagination Section */}
                {totalData > limit ?
                    <div className="pagination-section">
                        <button className='pagination-btn' disabled={page <= 1}
                                style={page === 1 ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page - 1)}>قبلی
                        </button>
                        <div>صفحه {page} از {totalPage}</div>
                        <button className='pagination-btn' disabled={page >= totalPage}
                                style={page === totalPage ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page + 1)}>بعدی
                        </button>
                    </div>
                    : null}

            </div>

            {/* Toast */}
            <ToastComp
                title={toastTitle}
                body={toastBody}
                icon={toastIcon}
                onShowToast={setShowToast}
                showToastValue={showToast}
                toastColor={toastColor}
            />

            {/* Delete Modal */}
            <Modal
                show={showDeleteModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">حذف بار</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    آیا از حذف این بار اطمینان دارید؟
                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-red-btn' onClick={deleteBarAPi}>
                        {loadingDeleteBTN ? <LoadingForBtn sytle={{fontSize: "10px"}}/> : (<> <FaTrash className='icon'
                                                                                                       style={{
                                                                                                           marginLeft: '4px',
                                                                                                           fontSize: '15px'
                                                                                                       }}/> حذف</>)}
                    </button>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowDeleteModal(false)}>لغو
                    </button>

                </Modal.Footer>
            </Modal>

            {/* Edit Modal */}
            <Modal show={showEditModal} centered>
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">ویرایش بار</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{textAlign: 'right', fontWeight: 'normal'}}>
                        <Row>
                            <Col xs={12}>
                                <div className='modal-input-container'>
                                    <label>متن پیام</label>
                                    <textarea rows={5}>{message}</textarea>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container'>
                                        <label>مبدا</label>
                                        <div className='modal-dropdown-input-container'>
                                            <input onClick={() => {
                                                setShowOriginDropdown(prev => !prev);
                                                setShowDestinationDropdown(false);
                                                setSearchCityShow("")
                                                setSearchCityApi("")
                                            }} type="text"
                                                   value={originText} readOnly style={{cursor: "pointer"}}/>
                                            <FaChevronDown className='icon'
                                                           onClick={() => setShowOriginDropdown(prev => !prev)}/>
                                        </div>
                                    </div>
                                    {
                                        showOriginDropdown &&
                                        <ul className="modal-dropdown-list">
                                            <input className='search-dropdown-input' autoComplete="off" placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {
                                                loadingCitiesDropdown ?
                                                    <LoadingForBtn/> :
                                                    allCities.length ? allCities.map(city => {
                                                            return <li key={city._id.$oid}
                                                                       onClick={() => setOriginHandler(city._id.$oid, city.name)}>{city.name}</li>
                                                        })
                                                        :
                                                        <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>
                                    }
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container'>
                                        <label>مقصد</label>
                                        <div className='modal-dropdown-input-container'>
                                            <input onClick={() => {
                                                setShowDestinationDropdown(prev => !prev);
                                                setShowOriginDropdown(false);
                                                setSearchCityShow("")
                                                setSearchCityApi("")
                                            }} type="text" value={destinationText} readOnly
                                                   style={{cursor: "pointer"}}/>
                                            <FaChevronDown className='icon'
                                                           onClick={() => setShowDestinationDropdown(prev => !prev)}/>
                                        </div>
                                    </div>
                                    {
                                        showDestinationDropdown &&
                                        <ul className="modal-dropdown-list">
                                            <input className='search-dropdown-input' autoComplete="off" placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {
                                                loadingCitiesDropdown ?
                                                    <LoadingForBtn/> :
                                                    allCities.length ?
                                                        allCities.map(city => {
                                                            return <li key={city._id.$oid}
                                                                       onClick={() => setDestinationHandler(city._id.$oid, city.name)}>{city.name}</li>
                                                        })
                                                        :
                                                        <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container'>
                                        <label>ناوگان</label>
                                        <div className='modal-dropdown-input-container'>
                                            <input onClick={() => setShowCarDropdown(prev => !prev)} type="text"
                                                   value={carTypeText} readOnly style={{cursor: "pointer"}}/>
                                            <FaChevronDown className='icon'
                                                           onClick={() => setShowCarDropdown(prev => !prev)}/>
                                        </div>
                                    </div>
                                    {
                                        showCarDropdown &&
                                        <ul className="modal-dropdown-list">
                                            {
                                                loadingCarDropdown ?
                                                    <LoadingForBtn/> :
                                                    allCars && allCars.map(car => {
                                                        return <li key={car._id.$oid}
                                                                   onClick={() => setCarTypeHandler(car._id.$oid, car.name)}>{car.name}</li>
                                                    })
                                            }
                                        </ul>
                                    }
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className='modal-input-container'>
                                    <label>شماره موبایل</label>
                                    <input type="text" maxLength={11} value={phoneNum}
                                           onChange={(e) => setPhoneNum(e.target.value)}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className='modal-input-container'>
                                    <label>پلتفرم</label>
                                    <input type="text" readOnly value={socialAppText}/>
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className='modal-input-container'>
                                    <label>تاریخ</label>
                                    <input type="text" readOnly
                                           value={new Date(messageDate).toLocaleDateString("fa-ir")}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-green-btn' onClick={editCargoApiHandler}>
                        <FaPencilAlt className='icon'/>
                        ویرایش و تایید
                    </button>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowEditModal(false)}>بازگشت
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )

}


export default BareCopyNotOk;