import React, {useState, useEffect} from 'react';
import {Col, Modal, Row} from "react-bootstrap";
import {
    FaChevronDown,
    FaChevronUp,
    FaCircleXmark,
    FaFileCircleCheck,
    FaFileCircleQuestion,
    FaFileCircleXmark,
    FaUser
} from "react-icons/fa6";
import { IoDocumentText } from "react-icons/io5";
import {FaCity, FaPhone, FaUserTie} from "react-icons/fa";
import {GrStatusInfo} from "react-icons/gr";
import { IoMdPricetag } from "react-icons/io";
import {IoMdClose, IoMdSettings} from "react-icons/io";
import Loading from "../../../Components/Loading/Loading";
import {Link} from "react-router-dom";
import {
    FaArrowCircleDown,
    FaArrowCircleUp,
    FaBus,
    FaEdit,
    FaCheckCircle, FaPlusCircle, FaEllipsisH
} from "react-icons/fa";
import axios from 'axios';
import {urlVariable} from '../../../Axios/Axios';
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs";
import InfoListNavigation from "../../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation";
import LoadingForBtn from "../../../Components/Loading/LoadingForBtn/LoadingForBtn";
import {IoFilter} from "react-icons/io5";
import ToastComp from "../../../Components/Toast/ToastComp";
import {TbNews} from "react-icons/tb";






function ViolationsReported ({userID}) {



    // Toast State
    const [showToast, setShowToast] = useState(false)
    const [bodyToast, setBodyToast] = useState("")
    const [showSuccessfulToast, setShowSuccessfulToast] = useState(false)

    // value for data
    const [reportData, setReportData] = useState([])
    // const [limit, setLimit] = useState(10)
    // const [page, setPage] = useState(1)
    // const [totalPage, setTotalPage] = useState()
    // const [totalData, setTotalData] = useState()


    const [loading, setLoading] = useState(false)



    useEffect(() => {
        setLoading(true)
        axios.get(`${urlVariable}/admin/cargo/report_list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            },
            params: {
                reported_user: userID
            }
        })
            .then((res) => {
                console.log(res)
                setReportData(res.data.data);
                // setTotalPage(res.data.total_pages)
                // setTotalData(res.data.total_count)
                setLoading(false)
            })
            .catch(err => {
                if (err.message === "Network Error"){
                    setBodyToast("اینترنت خود را بررسی کنید!..")
                    setShowToast(true)
                    setShowSuccessfulToast(false)
                    setLoading(false)
                }else {
                    setBodyToast("لطفا دوباره تلاش کنید!..")
                    setShowToast(true)
                    setShowSuccessfulToast(false)
                    setLoading(false)
                }
            })
    }, [])


    //
    // useEffect(() => {
    //
    //     if ((page > totalPage) && (totalPage > 0))
    //         setPage(totalPage)
    //
    // }, [totalPage]);


    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ' ';
        return Intl.DateTimeFormat('fa-IR').format(timestamp)
    };


    return (

        <>

            <div className="info-list" style={{boxShadow: "none", backgroundColor: "transparent", margin: "0"}} >

                {/* Toast */}
                <ToastComp
                    title={showSuccessfulToast ? "عملیات موفق" : "عملیات ناموفق"}
                    body={bodyToast}
                    icon={showSuccessfulToast ? <FaCheckCircle style={{marginLeft: '5px'}}/> :
                        <FaCircleXmark style={{marginLeft: '5px'}}/>}
                    onShowToast={setShowToast}
                    showToastValue={showToast}
                    toastColor={showSuccessfulToast ? 'var(--color-1)' : 'var(--danger-color)'}
                />



                <div className="info-list-table-section">
                    <table className='info-list-table'>
                        <thead>
                        <tr className='info-list-tr-head'>
                            <th>
                                <TbNews style={{marginLeft: '3px', fontSize: '13px'}}/>
                                عنوان تخلف
                            </th>
                            <th className='td-res-1'>
                                <IoDocumentText style={{marginLeft: '3px', fontSize: '13px'}}/>
                                متن تخلف
                            </th>
                            <th className='td-res-1'>
                                <svg style={{marginLeft: '3px'}} stroke="currentColor" fill="currentColor"
                                     strokeWidth=".3" viewBox="0 0 16 16" height="17px" width="17px"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6 9.5C6.93191 9.5 7.71496 10.1374 7.93699 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7455 13.8231 11.9496 13.5899 11.9919L13.5 12L7.93673 12.001C7.71435 12.8631 6.93155 13.5 6 13.5C5.06845 13.5 4.28565 12.8631 4.06327 12.001L2.5 12C2.22386 12 2 11.7761 2 11.5C2 11.2545 2.17688 11.0504 2.41012 11.0081L2.5 11H4.06301C4.28504 10.1374 5.06809 9.5 6 9.5ZM6 10.5C5.44772 10.5 5 10.9477 5 11.5C5 12.0523 5.44772 12.5 6 12.5C6.55228 12.5 7 12.0523 7 11.5C7 10.9477 6.55228 10.5 6 10.5ZM10 2.5C10.9319 2.5 11.715 3.13738 11.937 3.99998L13.5 4C13.7761 4 14 4.22386 14 4.5C14 4.74546 13.8231 4.94961 13.5899 4.99194L13.5 5L11.9367 5.00102C11.7144 5.86312 10.9316 6.5 10 6.5C9.06845 6.5 8.28565 5.86312 8.06327 5.00102L2.5 5C2.22386 5 2 4.77614 2 4.5C2 4.25454 2.17688 4.05039 2.41012 4.00806L2.5 4L8.06301 3.99998C8.28504 3.13738 9.06809 2.5 10 2.5ZM10 3.5C9.44772 3.5 9 3.94772 9 4.5C9 5.05228 9.44772 5.5 10 5.5C10.5523 5.5 11 5.05228 11 4.5C11 3.94772 10.5523 3.5 10 3.5Z"></path>
                                </svg>
                                تاریخ ایجاد
                            </th>
                            <th>
                                <GrStatusInfo style={{marginLeft: '3px', fontSize: '16px'}}/>
                                وضعیت
                            </th>
                        </tr>
                        </thead>
                        <tbody>

                        {loading ?

                            (<tr>
                                <td colSpan="6"><Loading/></td>
                            </tr>)
                            :
                            (reportData.length ?
                                    reportData.map((value, index) =>
                                        <tr className='info-list-row'>
                                            <td>{value.title ?? "-"}</td>
                                            <td className='td-res-1'>{value.report ?? "-"}</td>
                                            <td>{formatTimestamp(value.createdIn)}</td>
                                            <td>
                                                <span
                                                    style={{color: "var(--color-4)"}}>{value.status === "active" && "فعال"}</span>
                                                <span
                                                    style={{color: "var(--color-3)"}}>{value.status === "delete" && "غیر فعال"}</span>
                                                <span
                                                    style={{color: "var(--color-2"}}>{value.status === "pending" && "در حال بررسی"}</span>
                                            </td>
                                        </tr>
                                    ) :
                                    <tr>
                                        <td colSpan="6" style={{
                                            textAlign: 'center',
                                            padding: '10px 0',
                                            backgroundColor: 'var(--bg-color-2)'
                                        }}>در حال حاضر این کاربر تخلفی انجام نداده است!
                                        </td>
                                    </tr>
                            )
                        }


                        </tbody>
                    </table>
                </div>

                {/*/!* Pagination Section *!/*/}
                {/*{totalData > limit ?*/}
                {/*    <div className="pagination-section">*/}
                {/*        <button className='pagination-btn' disabled={page <= 1}*/}
                {/*                style={page === 1 ? {cursor: "not-allowed"} : null}*/}
                {/*                onClick={() => setPage(page - 1)}>قبلی*/}
                {/*        </button>*/}
                {/*        <div>صفحه {page} از {totalPage}</div>*/}
                {/*        <button className='pagination-btn' disabled={page >= totalPage}*/}
                {/*                style={page === totalPage ? {cursor: "not-allowed"} : null}*/}
                {/*                onClick={() => setPage(page + 1)}>بعدی*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*    : null}*/}


            </div>


        </>
    )

}


export default ViolationsReported;