import React, {useEffect, useState} from 'react';
import {Col, Modal, Row} from "react-bootstrap";
import {
    FaChevronDown,
    FaChevronUp,
    FaCircleXmark,
    FaFileCircleCheck,
    FaFileCircleQuestion,
    FaFileCircleXmark,
    FaUser
} from "react-icons/fa6";
import {FaPhone, FaUserTie} from "react-icons/fa";
import {GrStatusInfo} from "react-icons/gr";
import { IoMdPricetag } from "react-icons/io";
import {IoMdClose, IoMdSettings} from "react-icons/io";
import Loading from "../../../Components/Loading/Loading";
import {Link} from "react-router-dom";
import {
    FaArrowCircleDown,
    FaArrowCircleUp,
    FaBus,
    FaEdit,
    FaCheckCircle, FaPlusCircle, FaEllipsisH
} from "react-icons/fa";
import axios from 'axios';
import {urlVariable} from '../../../Axios/Axios';
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs";
import InfoListNavigation from "../../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation";
import LoadingForBtn from "../../../Components/Loading/LoadingForBtn/LoadingForBtn";
import {IoFilter} from "react-icons/io5";
import ToastComp from "../../../Components/Toast/ToastComp";




function CargoRegistered ({userID}) {


    // Toast State
    const [showToast, setShowToast] = useState(false)
    const [bodyToast, setBodyToast] = useState("")
    const [showSuccessfulToast, setShowSuccessfulToast] = useState(false)


    // dropdown value
    const [showDropDown, setShowDropDown] = useState("")
    const [showEditModal, setShowEditModal] = useState(false)


    // value for filter api
    const [originFilter, setOriginFilter] = useState({
        show: "",
        id: ""
    })
    const [originIDFilterAPi, setOriginIDFilterAPi] = useState()
    const [destinationFilter, setDestinationFilter] = useState({
        show: "",
        id: ""
    })
    const [destinationIDFilterApi, setDestinationIDFilterApi] = useState()
    const [typeCarFilter, setTypeCarFilter] = useState({
        show: "",
        id: ""
    })
    const [typeCarIDFilterApi, setTypeCarIDFilterApi] = useState()


    // Modal States
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showFilterIcon, setShowFilterIcon] = useState(false)


    // value for data
    const [barData, setBarData] = useState([])
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [totalData, setTotalData] = useState()


    const [loading, setLoading] = useState(false)
    const [loadingDropdown, setLoadingDropdown] = useState(false) // for city and group data
    const [statusUrl, setStatusUrl] = useState("active")

    // value of city list from api
    const [cityName, setCityName] = useState([])
    const [searchCityShow, setSearchCityShow] = useState("")
    const [searchCityApi, setSearchCityApi] = useState("")
    const [typeCarName, setTypeCarName] = useState([])



    // value for edit data
    const [createdIn, setCreatedIn] = useState("");
    const [description, setDescription] = useState("");
    const [destination, setDestination] = useState("");
    const [editOn, setEditOn] = useState("");
    const [group, setGroup] = useState("");
    const [origin, setOrigin] = useState("");
    const [phoneCall, setPhoneCall] = useState("");
    const [price, setPrice] = useState("");
    const [savecargo, setSavecargo] = useState("");
    const [status, setStatus] = useState({
        show: '',
        title: '',
        realData: ''
    });
    const [typeCar, setTypeCar] = useState("");
    const [typeCargo, setTypeCargo] = useState("");
    const [weight, setWeight] = useState("");
    const [freeWeight, setFreeWeight] = useState(false)




    useEffect(() => {
        setLoading(true)
        axios.get(`${urlVariable}/admin/cargo`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            },
            params: {
                pagination: true,
                source: "user",
                status: statusUrl,
                limit: limit,
                page:page,
                origin: originIDFilterAPi,
                destination: destinationIDFilterApi,
                typeCar: typeCarIDFilterApi,
                user: userID
            }
        })
            .then((res) => {
                console.log(res)
                setBarData(res.data.data);
                setTotalPage(res.data.total_pages)
                setTotalData(res.data.total_count)
                setLoading(false)
            })
            .catch(err => {
                if (err.message === "Network Error"){
                    setBodyToast("اینترنت خود را بررسی کنید!..")
                    setShowToast(true)
                    setShowSuccessfulToast(false)
                    setLoading(false)
                }else {
                    setBodyToast("لطفا دوباره تلاش کنید!..")
                    setShowToast(true)
                    setShowSuccessfulToast(false)
                    setLoading(false)
                }
            })
    }, [statusUrl, page, originIDFilterAPi, destinationIDFilterApi, typeCarIDFilterApi])



    useEffect(() => {

        if ((page > totalPage) && (totalPage > 0))
            setPage(totalPage)

    }, [totalPage]);


    useEffect(() => {

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/groups/city`, {
            params: {
                search: searchCityApi
            }
        })
            .then((res) => {
                setCityName(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingDropdown(true) //دلیل خط 117
            })

    }, [searchCityApi])


    useEffect(() => {

        const delayedSetData = setTimeout(() => {
            setSearchCityApi(searchCityShow)
        }, 800)

        return () => clearTimeout(delayedSetData);

    }, [searchCityShow]);


    useEffect(() => {

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/car/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        })
            .then((res) => {
                setTypeCarName(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingDropdown(true)
            })

    }, [])

    useEffect(() => {

        if (showFilterModal) {

            setShowDropDown("")
            setSearchCityShow("")
            setSearchCityApi("")
        }

    }, [showFilterModal]);



    function dropdown(value) {
        if (showDropDown === value)
            setShowDropDown("")
        else setShowDropDown(value)
        setSearchCityShow("")
        setSearchCityApi("")
    }


    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ' ';
        return Intl.DateTimeFormat('fa-IR').format(timestamp)
    };



    function selectOriginFilter(item) {
        setOriginFilter({
            show: item.name,
            id: item._id.$oid
        })
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")
    }


    function selectDestinationFilter(item) {
        setDestinationFilter({
            show: item.name,
            id: item._id.$oid
        })
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")
    }

    function selectTypeCarFilter(item) {
        setTypeCarFilter({
            show: item.name,
            id: item._id.$oid,
        })
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")
    }


    function setFilterForApi() {


        if (originFilter.id)
            setOriginIDFilterAPi(originFilter.id)
        else setOriginIDFilterAPi()
        if (destinationFilter.id)
            setDestinationIDFilterApi(destinationFilter.id)
        else setDestinationIDFilterApi()
        if (typeCarFilter.id)
            setTypeCarIDFilterApi(typeCarFilter.id)
        else setTypeCarIDFilterApi()

        if (originFilter.id || destinationFilter.id || typeCarFilter.id) {
            setShowFilterIcon(true)
            setShowFilterModal(false)
        } else setShowFilterIcon(false)
    }


    function removeAllFilter() {


        setOriginFilter({
            show: "",
            id: "",
        })
        setDestinationFilter({
            show: "",
            id: "",
        })
        setTypeCarFilter({
            show: "",
            id: "",
        })

        setOriginIDFilterAPi()
        setDestinationIDFilterApi()
        setTypeCarIDFilterApi()
        setShowFilterIcon(false)
        setShowFilterModal(false)
    }


    function DetailsItem (item) {

        console.log(item);
        setCreatedIn(formatTimestamp(item.createdIn) || "-")
        setDescription(item.description || "-")
        setDestination(item.destination_detail || "-")
        setEditOn(formatTimestamp(item.editOn) || "-")
        setGroup((item.group?.$ref || item.group) ?? "-")
        setOrigin(item.origin_detail || "-")
        setPhoneCall(item.phoneCall || "-")
        setPrice(item.price || "-")
        if (item.savecargo) {
            if (item.savecargo === true)
                setSavecargo("ذخیره شده")
            if (item.savecargo === false)
                setSavecargo("ذخیره شده")
        }else setSavecargo("-")
        if (item.status) {
            if (item.status === "delete")
                setStatus({
                    show: "تاییده نشده",
                    title: item.status,
                    realData: item.status
                })
            if (item.status === "active")
                setStatus({
                    show: "تاییده شده",
                    title: item.status,
                    realData: item.status
                })
            if (item.status === "pending")
                setStatus({
                    show: "در انتظار تایید",
                    title: item.status,
                    realData: item.status
                })
        }else setStatus({show: "-", title: "-", realData: "-"})
        setTypeCar(item.carType_detail || "-")
        setTypeCargo(item.typeCargo || "-")
        setWeight(item.weight || "-")
        setShowEditModal(true)
        setShowDropDown("")

    }


    return (

        <>

            <div>

                {/* Toast */}
                <ToastComp
                    title={showSuccessfulToast ? "عملیات موفق" : "عملیات ناموفق"}
                    body={bodyToast}
                    icon={showSuccessfulToast ? <FaCheckCircle style={{marginLeft: '5px'}}/> :
                        <FaCircleXmark style={{marginLeft: '5px'}}/>}
                    onShowToast={setShowToast}
                    showToastValue={showToast}
                    toastColor={showSuccessfulToast ? 'var(--color-1)' : 'var(--danger-color)'}
                />


                <InfoListNavigation
                    hasFilter={true}
                    hasFilterShow={showFilterIcon}
                    onShowFilter={setShowFilterModal}
                    setStatus={setStatusUrl}
                    status={statusUrl}
                    items={[
                        ['بارهای فعال', 'active', <FaFileCircleCheck className='icon'/>],
                        ['بارهای غیرفعال', 'delete', <FaFileCircleXmark className='icon'/>],
                        ['بارهای در حال بررسی', 'pending', <FaFileCircleQuestion className='icon'/>]
                    ]}
                />


                <div className="info-list-table-section">
                    <table className='info-list-table'>
                        <thead>
                        <tr className='info-list-tr-head'>
                            <th>
                                <FaArrowCircleDown style={{marginLeft: '3px', fontSize: '13px'}}/>
                                مبدا
                            </th>
                            <th className='td-res-1'>
                                <FaArrowCircleUp style={{marginLeft: '3px', fontSize: '13px'}}/>
                                مقصد
                            </th>
                            <th className='td-res-1'>
                                <FaBus style={{marginLeft: '3px', fontSize: '13px'}}/>
                                نوع ناوگان
                            </th>
                            <th>
                                <GrStatusInfo style={{marginLeft: '3px', fontSize: '16px'}}/>
                                وضعیت
                            </th>
                            <th>
                                <IoMdSettings style={{marginLeft: '3px', fontSize: '16px'}}/>
                                عملیات
                            </th>
                        </tr>
                        </thead>
                        <tbody>

                        {loading ?

                            (<tr>
                                <td colSpan="6"><Loading/></td>
                            </tr>)
                            :
                            (barData.length ?
                                    barData.map((value, index) =>
                                        <tr key={value._id?.$oid} className='info-list-row'>
                                            <td>{value.origin_detail ?? "-"}</td>
                                            <td className='td-res-1'>{value.destination_detail ?? "-"}</td>
                                            <td className='td-res-1'>{(value.carType_detail.$ref ? value.carType_detail?.$ref : value.carType_detail) ?? "-"}</td>
                                            <td>
                                                <span style={{color: "var(--color-4)"}}>{value.status === "active" && "فعال"}</span>
                                                <span style={{color: "var(--color-3)"}}>{value.status === "delete" && "غیر فعال"}</span>
                                                <span style={{color: "var(--color-2"}}>{value.status === "pending" && "در حال بررسی"}</span>
                                            </td>
                                            <td  style={{color: 'var(--color-1)', cursor: 'pointer',}} onClick={() => DetailsItem(value)}>نمایش جزئیات</td>
                                        </tr>
                                    ) :
                                    <tr>
                                        {showFilterIcon ?
                                            <td colSpan="6" style={{
                                                textAlign: 'center',
                                                padding: '10px 0',
                                                backgroundColor: 'var(--bg-color-2)'
                                            }}>باری یافت نشد!
                                            </td>
                                            :
                                            <td colSpan="6" style={{
                                                textAlign: 'center',
                                                padding: '10px 0',
                                                backgroundColor: 'var(--bg-color-2)'
                                            }}>در حال حاضر این کاربر باری ندارد!
                                            </td>
                                        }

                                    </tr>
                            )
                        }


                        </tbody>
                    </table>
                </div>

                {/* Pagination Section */}
                {totalData > limit ?
                    <div className="pagination-section">
                    <button className='pagination-btn' disabled={page <= 1}
                                style={page === 1 ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page - 1)}>قبلی
                        </button>
                        <div>صفحه {page} از {totalPage}</div>
                        <button className='pagination-btn' disabled={page >= totalPage}
                                style={page === totalPage ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page + 1)}>بعدی
                        </button>
                    </div>
                    : null}


            </div>


            <Modal
                show={showFilterModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">فیلتر بار</span>
                            <span className="hover-close-model"
                                  onClick={() => setShowFilterModal(false)}><IoMdClose/></span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">


                        <div className="row">

                            <div className="col">

                                <div className="modal-dropdown-container">

                                    <div className='modal-input-container' onClick={() => dropdown("origin")}>
                                        <label style={{textAlign: "right"}}>
                                            مبدا
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={originFilter.show}/>
                                            {showDropDown === "origin" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "origin" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            <input className='search-dropdown-input' autoComplete="off"
                                                   placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ?
                                                    cityName.map((item, index) => (
                                                        <li key={index} onClick={() => selectOriginFilter(item)}>
                                                            {item.name}
                                                        </li>
                                                    ))
                                                    :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>

                                        : null}

                                </div>

                            </div>

                            <div className="col">

                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container' onClick={() => dropdown("destination")}>
                                        <label style={{textAlign: "right"}}>
                                            مقصد
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={destinationFilter.show}/>
                                            {showDropDown === "destination" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "destination" ?
                                        <ul className="modal-dropdown-list"
                                            style={{textAlign: "right", height: "10rem"}}>
                                            <input className='search-dropdown-input' autoComplete="off"
                                                   placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ? cityName.map((item, index) => (
                                                        <li key={index} onClick={() => selectDestinationFilter(item)}>
                                                            {item.name}
                                                        </li>
                                                    ))
                                                    :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>
                                        : null}
                                </div>

                            </div>

                        </div>
                        <div className="row">

                            <div className="col">

                                <div className="modal-dropdown-container" onClick={() => dropdown("typeCar")}>
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            نوع ناوگان
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={typeCarFilter.show}/>
                                            {showDropDown === "typeCar" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>
                                    {showDropDown === "typeCar" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                (typeCarName && typeCarName.map((item, index) => (
                                                    <li key={index} onClick={() => selectTypeCarFilter(item)}>
                                                        {item.name}
                                                    </li>
                                                )))}
                                        </ul>
                                        : null}

                                </div>

                            </div>
                        </div>


                    </div>

                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={removeAllFilter}>حذف فیلترها
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={setFilterForApi}>
                        <IoFilter className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>
                        اعمال فیلتر

                    </button>
                </Modal.Footer>
            </Modal>


            {/* Edit Modal */}
            <Modal
                show={showEditModal}
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section" style={{justifyContent: "space-around"}}>
                            <span className="modal-title">اطلاعات بار</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">

                        <div className="row">

                            <div className="col">
                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            وضعیت
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={status.show}/>
                                            {status.realData === "active" ?
                                                null
                                                :
                                                showDropDown === "status" ? (<FaChevronUp className='icon'/>) :
                                                    (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="row">

                            <div className="col-sm">

                                <div className="modal-dropdown-container" >

                                    <div className='modal-input-container' >
                                        <label style={{textAlign: "right"}}>
                                            مبدا
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={origin}/>

                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className="col-sm">

                                <div className="modal-dropdown-container" >
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            مقصد
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={destination}/>

                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className="modal-dropdown-container" >
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            نوع ناوگان
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={typeCar}/>

                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        نوع بار
                                    </label>
                                    <input value={typeCargo}/>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        تلفن
                                    </label>
                                    <input type="number" value={phoneCall}/>

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className="modal-dropdown-container" >
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            گروه
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={group}/>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        وزن (تن)
                                    </label>
                                    <input type="number" value={weight}/>

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        قیمت
                                    </label>
                                    <input type="number" className="input-group" value={price}/>

                                </div>
                            </div>

                        </div>


                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        ایجاد شده در
                                    </label>
                                    <input value={createdIn}/>

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        آخرین ویرایش
                                    </label>
                                    <input value={editOn}/>

                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        توضیحات
                                    </label>
                                    <input value={description}/>

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        ذخیره بار
                                    </label>
                                    <input value={savecargo}/>

                                </div>
                            </div>

                        </div>


                    </div>

                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowEditModal(false)}>لغو
                    </button>

                </Modal.Footer>
            </Modal>



        </>
    )
}


export default CargoRegistered;