import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../../Components/Breadcrumbs/Breadcrumbs';
import InfoListNavigation from '../../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { FaPencil, FaRegTrashCan } from "react-icons/fa6";
import axios from "axios";
import { urlVariable } from "../../../Axios/Axios";
import Loading from "../../../Components/Loading/Loading";
import { IoFilter } from "react-icons/io5";
import { FaCheckCircle } from 'react-icons/fa';
import ToastComp from '../../../Components/Toast/ToastComp';
import LoadingForBtn from "../../../Components/Loading/LoadingForBtn/LoadingForBtn";
import { FaCircleXmark } from 'react-icons/fa6';
// import { RiImageCircleLine } from "react-icons/ri";
import { MdInsertPhoto } from "react-icons/md";
import Spinner from 'react-bootstrap/Spinner';
import editIcon from '../edit-image.svg'
import { MdDelete } from "react-icons/md";




export default function States() {

    const [loading, setLoading] = useState(false);
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [imageSrc, setImageSrc] = useState("")
    const [data, setData] = useState([]);
    const [searchCityShow, setSearchCityShow] = useState('');
    const [searchCityApi, setSearchCityApi] = useState('');
    const [stateId, setStateId] = useState("");
    const [imagePathToShow, setImagePathToShow] = useState("");
    const [loadingToAdd, setLoadingToAdd] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    //edit
    const [newStateName, setNewStateName] = useState("");
    const [updateAfterChanage, setUpdateAfterChange] = useState(false);
    const [uploadedPic, setUploadedPic] = useState(false);
    const [selectedNewPic, setSelectedNewPic] = useState("");
    const [updateError, setUpdateError] = useState(false)
    const [lastImgId, setLastImgId] = useState("")

    // add
    const [showAddModal, setShowAddModal] = useState(false)
    const [addNewCityName, setAddNewCityName] = useState("")
    const [addLoading, setAddLoading] = useState(false)

    // delete
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    // toast
    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState('');
    const [toastBody, setToastBody] = useState('');
    const [toastIcon, setToastIcon] = useState();
    const [toastColor, setToastColor] = useState('');

    // loadings
    const [uploadImage, setUploadImage] = useState(false)

    console.log("state id :", stateId);

    const finalAdd = () => {
        if (addNewCityName) {
            setAddLoading(true)
            axios.post(`${urlVariable}/admin/state/add`, {
                name: addNewCityName,
            }, {

                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
                }
            }).then(res => {
                setShowToast(true)
                setAddLoading(false)
                setToastTitle("افزودن استان")
                setShowAddModal(false)
                setToastBody("اضافه کردن استان با موفقیت انجام شد !")
                setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
                setToastColor(`var(--color-1)`)
                setAddNewCityName("")
                setUpdateAfterChange(prev => !prev)

            }).catch(err => {
                if (err?.response?.status !== 200) {
                    setShowToast(true)
                    setToastTitle("اضافه کردن استان")
                    setToastBody(err.response.data.msg)
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')
                    setAddLoading(false)
                }
                if (err?.message === "Network Error")
                    setLoadingToAdd(true)

                console.log(err);
            })
        } else {
            setShowToast(true)
            setToastBody("لطفا نام استان را وارد کنید!")
            setToastTitle("خطا!")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')
            return null
        }

    }

    const finalEdit = () => {
        if (newStateName.trim() !== "") {
            setAddLoading(true)
            axios.put(`${urlVariable}/admin/state/update`, {
                name: newStateName,
                state: stateId
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
                }
            }).then(res => {
                changePhotoAfterAccept()
                setAddLoading(false)
                setShowEditModal(false)
                setShowToast(true)
                setToastTitle("ویرایش استان")
                setToastBody("ویرایش استان با موفقیت انجام شد !")
                setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
                setToastColor(`var(--color-1)`)
                setUpdateAfterChange(prev => !prev)
            }).catch(err => {
                if (err?.response?.status !== 200) {
                    setShowToast(true)
                    setToastTitle("ویرایش استان")
                    setToastBody(err?.response?.data?.msg || "مشکل در اتصال به اینترنت !")
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')
                    setAddLoading(false)
                }
                if (err.message === "Network Error")
                    setAddLoading(true)

                console.log(err);

            })
        } else {
            setShowToast(true)
            setToastTitle("ویرایش استان")
            setToastBody("لطفا نام استان را وارد کنید !")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
            setToastColor('var(--danger-color)')
        }
    }

    const changePhotoAfterAccept = () => {
        axios.post(`${urlVariable}/admin/image/setstateimg`, {
            stateID: stateId,
            imgID: imageSrc || lastImgId
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then(res => {
            console.log(res.data);
            setUploadedPic(prev => !prev);
        }).catch(err => {
            setShowToast(true);
            setToastTitle("خطا!");
            setToastBody(err.response.data.msg);
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />);
            setToastColor("var(--danger-color)");
        })
    }

    const finalDelete = () => {
        setAddLoading(true)
        axios.delete(`${urlVariable}/admin/state/delete`,
            {
                data: {
                    state: stateId
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
                }
            }).then(res => {
                if (res.status === 200) {
                    setShowDeleteModal(false)
                    setAddLoading(false)
                    setShowToast(true)
                    setUpdateAfterChange(prev => !prev)
                    setToastTitle("حذف استان")
                    setToastBody("حذف کردن استان با موفقیت انجام شد !")
                    setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
                    setToastColor(`var(--color-1)`)
                }
            }).catch(err => {
                if (err?.response?.status !== 200) {
                    setShowToast(true)
                    setUpdateAfterChange(prev => !prev)
                    setToastTitle("حذف استان")
                    setToastBody("حذف کردن استان ناموفق بود!")
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor(`var(--color-1)`)
                    setAddLoading(false)
                }
                if (err?.message === "Network Error")
                    setAddLoading(true)

            })
    }

    const handleCloseEditModal = () => {
        setAddLoading(false)
        setShowEditModal(false);
        setUploadImage(false)
        setSelectedNewPic("")
        setStateId("");
    };

    useEffect(() => {
        setLoading(true)
        axios.get(`${urlVariable}/api/v1/groups/states`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            },
            params: {
                search: searchCityApi
            }
        })
            .then((res) => {
                setData(res.data.data);
                setLoading(false)
                console.log("state data is :", res.data);
            })
            .catch(err => {
                console.log(err)
                setLoading(true)
            })
    }, [searchCityApi, updateAfterChanage, uploadedPic])

    console.log('searchCityApiiiii : ', searchCityApi);

    const prepareImage = (e, id) => {
        const formData = new FormData();
        const file = e.target.files[0];

        formData.append('attachedFile', file);

        setUploadImage(true);

        axios.post(`${urlVariable}/admin/uploadFile`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then(res => {
            setImageSrc(res.data.data._id.$oid);
            setUploadImage(false);
            setSelectedNewPic(res.data?.data?.source)
            console.log("image in axios-upload : ", res.data);
        }).catch(err => {
            console.log('err : ', err);

            setShowToast(true);
            setToastTitle("خطا!");
            setToastBody(err.response.data.msg);
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />);
            setToastColor("var(--danger-color)");
        });
    };


    console.log('image path to show url :', imagePathToShow);
    console.log('specific state id : ', stateId);

    function searchFilter() {
        setSearchCityApi(searchCityShow)
        setShowModalFilter(false)
    }

    return (
        <div className="info-list">
            <Breadcrumbs
                items={[
                    { name: 'اطلاعات پایه', link: '' },
                    { name: 'مدیریت استان‌ها', link: '/pannel/basicinfo/states' }
                ]}
            />
            <InfoListNavigation
                btntitle={loadingToAdd ? (<LoadingForBtn />) : 'افزودن استان جدید'}
                onShowModal={() => setShowAddModal(true)}
                hasFilter={true}
                onShowFilter={setShowModalFilter}
                items={[]}
            />

            <div className="info-list-table-section">
                <Container className='mx-0'>
                    <Row>
                        {loading ?
                            <Loading />
                            :
                            data.length ?
                                data.map((item, index) =>

                                    <Col xs={12} sm={6} md={4} lg={3} key={index}>
                                        <div className="basic-info-card">
                                            <div className='city-infos'>
                                                <div
                                                    style={{
                                                        width: '45px',
                                                        height: '45px',
                                                        border: '1px solid grey',
                                                        overflow: 'hidden',
                                                        borderRadius: '50%',
                                                        position: 'relative',
                                                        flexGrow: "0",
                                                        flexShrink: "0"
                                                    }}
                                                >


                                                    <>
                                                        {
                                                            item?.image[0]?.source ? (
                                                                <img
                                                                    style={{
                                                                        width: '45px',
                                                                        height: '45px',
                                                                        borderRadius: '50%',
                                                                    }}
                                                                    src={`${urlVariable}/${item?.image[0]?.source}`}
                                                                    onError={(e) => { e.target.style.display = 'none'; }}
                                                                />
                                                            ) : (
                                                                <MdInsertPhoto style={{ fontSize: "35px", margin: "4px", color: "var(--color-1)" }} />
                                                            )
                                                        }
                                                    </>

                                                </div>
                                                <div>
                                                    <div style={{ textAlign: "center" }}>{item.name}</div>
                                                </div>

                                                <div
                                                    className='btns-container'
                                                >
                                                    <div className="edit-btn-container"
                                                        onClick={() => {
                                                            setStateId(item._id.$oid)
                                                            setImagePathToShow(item?.image[0]?.source)
                                                            setNewStateName(item.name)
                                                            setShowEditModal(true)
                                                            setLastImgId(item?.image[0]?._id?.$oid)
                                                        }}>
                                                        <FaPencil />
                                                    </div>
                                                    <div onClick={() => {
                                                        setShowDeleteModal(true)
                                                        setNewStateName(item.name)
                                                        setStateId(item._id.$oid)
                                                    }} className="delete-btn-container" >
                                                        <MdDelete />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                                :
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "var(--shadow-color)",
                                    padding: "15px 15px",
                                    borderRadius: "10px",
                                    fontWeight: "bold"
                                }}>
                                    استانی یافت نشد!..
                                </div>
                        }
                    </Row>
                </Container>

                {/* Toast */}
                <ToastComp
                    title={toastTitle}
                    body={toastBody}
                    icon={toastIcon}
                    onShowToast={setShowToast}
                    showToastValue={showToast}
                    toastColor={toastColor}
                />

                {/* Edit Modal */}

                <Modal show={showEditModal} centered>
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <div className="modal-title-section">
                                <span className="modal-title">ویرایش استان</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container style={{ textAlign: 'right', fontWeight: 'normal' }}>
                            <Row>
                                <Col xs={12}>
                                    <div className="modal-dropdown-container">

                                        {/* image container  */}
                                        <div
                                            style={{
                                                width: '65px',
                                                height: '65px',
                                                border: '1px solid grey',
                                                overflow: 'hidden',
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                                position: 'relative',
                                                margin: "10px auto",
                                            }}
                                        >
                                            {
                                                uploadImage ? (
                                                    <Spinner style={{ margin: "15px" }} animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                ) : (
                                                    <>
                                                        <img
                                                            style={{
                                                                width: '65px',
                                                                height: '65px',
                                                                borderRadius: '50%',
                                                            }}
                                                            alt="Edit State Image"
                                                            src={selectedNewPic || imagePathToShow ? `${urlVariable}/${selectedNewPic ? selectedNewPic : imagePathToShow}` : editIcon}
                                                        />
                                                    </>
                                                )
                                            }

                                            <input
                                                id="file-upload"
                                                type="file"
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    borderRadius: '50%',
                                                    zIndex: "5",
                                                    opacity: 0,
                                                    cursor: 'pointer',
                                                }}
                                                onChange={(e) => prepareImage(e, stateId)}
                                            />
                                        </div>
                                        {/* <h6 style={{ textAlign: "center" }}>ویرایش عکس</h6> */}

                                        {/* modal info  */}

                                        <div className='modal-input-container'>
                                            <label>نام استان</label>
                                            <div className='modal-dropdown-input-container'>
                                                <input
                                                    value={newStateName}
                                                    onChange={(e) => setNewStateName(e.target.value)}
                                                    type="text"
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <button className='modal-btn modal-cancle-btn' onClick={handleCloseEditModal}>بازگشت</button>
                        <button
                            onClick={finalEdit}
                            className='modal-btn modal-green-btn'
                        >
                            {addLoading ? <LoadingForBtn /> : "تایید"}
                        </button>
                    </Modal.Footer>
                </Modal>

                {/* add state  */}

                <Modal
                    show={showAddModal}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <div className="modal-title-section">
                                <span className="modal-title">افزودن استان جدید</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <div className="col-sm">
                                <div className='modal-input-container'>
                                    <label style={{ textAlign: "right" }}>
                                        نام استان
                                    </label>
                                    <input type='text' onChange={(e) => setAddNewCityName(e.target.value)} value={addNewCityName} />
                                </div>
                            </div>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <button className='modal-btn modal-cancle-btn' onClick={() => {
                            setShowAddModal(false)
                            setLoadingToAdd(false)
                        }}>لغو
                        </button>
                        <button onClick={finalAdd}
                            className='modal-btn modal-green-btn'>
                            {addLoading ? <LoadingForBtn /> : "تایید"}
                        </button>
                    </Modal.Footer>
                </Modal>



                {/* ---------------------------- filter modal ------------------------------ */}
                <Modal
                    show={showModalFilter}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <div className="modal-title-section">
                                <span className="modal-title">فیلتر استان‌های کشور</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className='modal-input-container'>
                                        <label style={{ textAlign: "right" }}>
                                            استان
                                        </label>
                                        <input placeholder="استان مورد نظر خود را جست و جو کنید" value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)} />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <button className='modal-btn modal-cancle-btn' onClick={() => {
                            setShowModalFilter(false);
                            setSearchCityApi("");
                            setSearchCityShow("")
                        }}>لغو فیلتر
                        </button>
                        <button className='modal-btn modal-green-btn' onClick={searchFilter}>
                            <>
                                <IoFilter className='icon' style={{ marginLeft: '4px', fontSize: '15px' }} />
                                اعمال فیلتر
                            </>
                        </button>
                    </Modal.Footer>
                </Modal>


                {/* delete modal  */}
                <Modal
                    show={showDeleteModal}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <div className="modal-title-section">
                                <span className="modal-title">حذف استان</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div style={{ width: "100%" }}>
                            <p style={{ textAlign: "right" }}>آیا از حذف کردن استان <span style={{ color: "var(--color-1)" }}>{newStateName} </span>اطمینان دارید ؟</p>
                        </div>

                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <button className='modal-btn modal-cancle-btn' onClick={() => { setShowDeleteModal(false) }}>انصراف
                        </button>
                        <button className='modal-btn modal-red-btn' onClick={finalDelete}>

                            <>
                                <MdDelete className='icon' style={{ marginLeft: '4px', fontSize: '15px' }} />
                                حذف استان</>

                        </button>
                    </Modal.Footer>
                </Modal>


            </div>
        </div>
    )
}
