import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {handleErrors, urlVariable} from "../../../Axios/Axios";
import {FaChevronDown, FaChevronUp, FaCircleXmark} from "react-icons/fa6";
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs";
import ToastComp from "../../../Components/Toast/ToastComp";
import PersonalInformation from "./PersonalInformation";
import CargoRegistered from "./CargoRegistered";
import ViolationsRegistered from "./ViolationsRegistered";
import ViolationsReported from "./ViolationsReported";
import NewsRegistered from "./NewsRegistered";


function DetailsOfUser() {


    const user = useParams()

    const [infoType, setInfoType] = useState("Personal information")

    const [refreshData, setRefreshData] = useState(false)

    const [loading, setLoading] = useState(true)

    // Toast State
    const [showToast, setShowToast] = useState(false)
    const [toastTitle, setToastTitle] = useState('')
    const [toastBody, setToastBody] = useState('')
    const [toastIcon, setToastIcon] = useState()
    const [toastColor, setToastColor] = useState('')



    const [userDetailsData, setUserDetailsData] = useState([])


    useEffect(() => {
        setLoading(true)
        axios.get(`${urlVariable}/admin/user/detail/${user.id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then(res => {
            setLoading(false)
            setUserDetailsData(res.data.data)
            console.log(res.data.data)
        }).catch((err) => {
            setLoading(false)
            handleErrors(err)
            if (err.message === "Network Error") {
                setToastTitle('عملیات ناموفق')
                setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setShowToast(true)
            } else {
                if (err.response && err.response.status >= 500) {
                    setToastTitle('عملیات ناموفق')
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setShowToast(true)
                    setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
                } else {
                    setToastTitle('عملیات ناموفق')
                    setToastBody('خطا در بارگیری اطلاعات')
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setShowToast(true)
                }
            }
        })

    }, [refreshData])



    return (

        <div>
            <div className="info-list">

                {/* Toast */}
                <ToastComp
                    title={toastTitle}
                    body={toastBody}
                    icon={toastIcon}
                    onShowToast={setShowToast}
                    showToastValue={showToast}
                    toastColor={toastColor}
                />


                <Breadcrumbs
                    items={[
                        {name: 'کاربران', link: '/pannel/users/all'},
                        {name: 'جزئیات کاربر', link: ''},
                        {
                            name:  loading ? <>در حال دریافت اطلاعات ...</> :
                                (userDetailsData.userType === "driver" && "راننده") ||
                                (userDetailsData.userType === "Curator" && "متصدی") ||
                                (userDetailsData.userType === "cargoOwners" && "صاحبین بار") ||
                                (userDetailsData.userType === "freight" && "باربری")
                            , link: ''
                        },
                        !loading && {name: (userDetailsData.name || userDetailsData.companyOwnerName), link: ''},

                    ]}
                />


                <div className='platform-btns-container'>

                    <button
                        className={`${infoType === "Personal information" && "active"}`}
                        onClick={() => setInfoType("Personal information")}
                    >اطلاعات شخصی
                    </button>
                    <button
                        className={`${infoType === "cargo" && "active"}`}
                        onClick={() => setInfoType("cargo")}
                    >بارهای ثبت شده
                    </button>

                    <button
                        className={`${infoType === "New registered" && "active"}`}
                        onClick={() => setInfoType("New registered")}
                    >اخبارهای ثبت شده
                    </button>

                    <button
                        className={`${infoType === "Violations registered" && "active"}`}
                        onClick={() => setInfoType("Violations registered")}
                    >تخلفات گزارش شده
                    </button>

                    <button
                        className={`${infoType === "Violations reported" && "active"}`}
                        onClick={() => setInfoType("Violations reported")}
                    >تخلفات انجام شده
                    </button>
                </div>


                {infoType === "Personal information" &&
                    <PersonalInformation user={userDetailsData} setRefreshData={setRefreshData}
                                         refreshData={refreshData}/>}
                {infoType === "New registered" && <NewsRegistered userID={user.id}/>}
                {infoType === "cargo" && <CargoRegistered userID={user.id}/>}
                {infoType === "Violations registered" && <ViolationsRegistered userID={user.id}/>}
                {infoType === "Violations reported" && <ViolationsReported userID={user.id}/>}


            </div>

        </div>
    )
}


export default DetailsOfUser