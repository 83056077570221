import React, {useState} from 'react';
import './InfoListNavigation.css';
import {Link, useLocation} from 'react-router-dom';
import {RiAddCircleFill} from "react-icons/ri";
import {FaEllipsisH} from "react-icons/fa";
import {FaGenderless} from "react-icons/fa6";
import {IoSearchCircle} from "react-icons/io5";


export default function InfoListNavigation({...props}) {


    const location = useLocation();
    const [showList, setShowList] = useState(false);

    function handelStatus(value) {
        if (props.setStatus)
            props.setStatus(value);
    }

    function modalShowHandler(onShowModal) {
        if (onShowModal) {
            onShowModal(true);
        }
    }

    return (
        <div className="info-list-navigate-section">
            <div className="right-side py-2">
                {props.items && props.items.map((item, index) => (
                    <Link key={index} className='link info-list-navigate-tag-link'>
                        <span className={`info-list-navigate-link ${props.status === item[1] && 'active'}`}
                              onClick={() => handelStatus(item[1])}>
                            {item[2]}
                            {item[0]}
                        </span>
                    </Link>
                ))}
                <div className='info-list-navigate-response-container'>
                    <FaEllipsisH className='icon' onClick={() => setShowList(prev => !prev)}/>
                    <ul className={showList ? 'info-list-navigate-response show' : 'info-list-navigate-response hidden'}>
                        {props.items && props.items.map((item, index) => (
                            <Link key={index} className='link' style={{textAlign: 'right'}}
                                  onClick={() => props.setStatus(item[1])}>
                                <li
                                    className={`info-list-navigate-link-response ${props.status === item[1] && 'active'}`}
                                >
                                    <FaGenderless style={{marginLeft: '4px', fontSize: '10px'}}/>
                                    {item[0]}
                                </li>
                            </Link>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="left-side" style={{display: 'flex', gap: "16px"}}>

                {/*for search filter*/}
                {props.hasFilter ?
                    <Link className='link' to={props.btnLink} onClick={() => modalShowHandler(props.onShowFilter)}
                          style={{
                              display: "flex",
                              alignItems: "end"
                          }}>
                        <button className='add-new-info-btn'>
                            {props.hasFilterShow ?
                                <div style={{
                                    height: "8px",
                                    width: "8px",
                                    backgroundColor: "red",
                                    borderRadius: "100%",
                                    position: "absolute"
                                }}></div>
                                : null}

                            <IoSearchCircle className='iconForSearch'/>
                            <span className='btn-text'>جست و جو</span>
                        </button>
                    </Link>
                    : null}


                {props.btntitle &&
                    <Link className='link' to={props.btnLink} onClick={() => modalShowHandler(props.onShowModal)}
                          style={{
                              display: "flex",
                              alignItems: "end"
                          }}>
                        <button className='add-new-info-btn'>
                            <RiAddCircleFill className='icon'/>
                            <span className='btn-text'>{props.btntitle}</span>
                        </button>
                    </Link>
                }


            </div>

        </div>
    );
}
