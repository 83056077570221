import AllUsers from './Pages/Users/AllUsers/AllUsers';
import CuratorUsers from './Pages/Users/CuratorUsers/CuratorUsers';
import CargoOwners from './Pages/Users/CargoOwners/CargoOwners';
import Driver from './Pages/Users/Driver/Driver';
import Freight from './Pages/Users/Freight/Freight';
import GroupsList from './Pages/BarCopy/GroupsList';
import BareCopy from "./Pages/message/bareCopy/BareCopy";
import BareCopyNotOk from './Pages/message/bareCopy/BareCopyNotOk';
import MessageFromUser from "./Pages/message/messageFromUser/MessageFromUser";
import TicketsList from './Pages/Tickets/TicketsList/TicketsList';
import DetailesTickets from './Pages/Tickets/DetailesTickets/DetailesTickets';
import News from "./Pages/News/News";
import Violation from './Pages/Violation/Violation';
import GroupApp from './Pages/groupApp/GroupApp';
import AddNewCargo from "./Pages/message/messageFromUser/AddNewCargo";
import Chauffeur from "./Pages/Chauffeur/Chauffeur";
import DriverChauffeur from "./Pages/Chauffeur/DriverChauffeur";
import CarOwnerChauffeur from "./Pages/Chauffeur/CarOwnerChauffeur";
import States from './Pages/BasicInfo/States/States';
import Cities from './Pages/BasicInfo/Cities/Cities';
import Cars from './Pages/BasicInfo/Cars/Cars';
import Management from './Pages/Management/Management';
import UserStatistics from './Pages/UserStatistics/UserStatistics';
import MessagesStatistics from './Pages/MessagesStatistics/MessagesStatistics';
import CallList from './Pages/CallList/CallList';
import PatternNo from './Pages/BarCopy/PatternNo/PatternNo';
import BlockedUsers from './Pages/Users/BlockedUsers/BlockedUsers';
import OnlineUsers from './Pages/Users/OnlineUsers/OnlineUsers';
import SubscribedUsers from './Pages/Users/SubscribedUsers/SubscribedUsers';
import Income from './Pages/Income/Income';
import Rules from './Pages/BasicInfo/Rules/Rules';
import Warning from './Pages/BasicInfo/Warning/Warning';
import AboutUs from './Pages/BasicInfo/AboutUs/AboutUs';
import Questions from './Pages/BasicInfo/Questions/Questions';
import PublicNotice from './Pages/BasicInfo/PublicNotice/PublicNotice';
import DetailsOfUser from "./Pages/Users/DetailsOfUser/DetailsOfUser";


const routes2 = [
    { path: '/pannel/users/all', element: <AllUsers /> },
    { path: '/pannel/users/Details/:id', element: <DetailsOfUser /> },
    { path: '/pannel/users/curators', element: <CuratorUsers /> },
    { path: '/pannel/users/cargoOwners', element: <CargoOwners /> },
    { path: '/pannel/users/driver', element: <Driver /> },
    { path: '/pannel/users/freight', element: <Freight /> },
    { path: '/pannel/users/blocked', element: <BlockedUsers /> },
    { path: '/pannel/users/online', element: <OnlineUsers /> },
    { path: '/pannel/users/subscribed', element: <SubscribedUsers /> },
    { path: '/pannel/barcopy/groups', element: <GroupsList /> },
    { path: '/pannel/barcopy/patternno', element: <PatternNo /> },
    { path: '/pannel/message/barecopy', element: <BareCopy /> },
    { path: '/pannel/message/barecopy/notok', element: <BareCopyNotOk /> },
    { path: '/pannel/message/fromUser', element: <MessageFromUser /> },
    { path: '/pannel/tickets/list', element: <TicketsList /> },
    { path: '/pannel/tickets/detailes', element: <DetailesTickets /> },
    { path: '/pannel/news', element: <News /> },
    { path: '/pannel/violation', element: <Violation /> },
    { path: '/pannel/groupsApp', element: <GroupApp /> },
    { path: '/pannel/message/addNewCargo', element: <AddNewCargo /> },
    { path: '/pannel/Chauffeur', element: <Chauffeur /> },
    { path: '/pannel/Chauffeur/driver', element: <DriverChauffeur /> },
    { path: '/pannel/Chauffeur/carOwner', element: <CarOwnerChauffeur /> },
    { path: '/pannel/basicinfo/states', element: <States /> },
    { path: '/pannel/basicinfo/cities', element: <Cities /> },
    { path: '/pannel/basicinfo/cars', element: <Cars /> },
    { path: '/pannel/basicinfo/rules', element: <Rules /> },
    { path: '/pannel/basicinfo/aboutus', element: <AboutUs /> },
    { path: '/pannel/basicinfo/warning', element: <Warning /> },
    { path: '/pannel/basicinfo/questions', element: <Questions /> },
    { path: '/pannel/basicinfo/publicnotice', element: <PublicNotice /> },
    { path: '/pannel/admins', element: <Management /> },
    { path: '/pannel/statistics/users', element: <UserStatistics /> },
    { path: '/pannel/statistics/messages', element: <MessagesStatistics /> },
    { path: '/pannel/calllist', element: <CallList /> },
    { path: '/pannel/income', element: <Income /> },
]

export { routes2 }
