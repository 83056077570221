import React, {useState, useEffect} from 'react';
import '../messageStyle.css'
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs";
import InfoListNavigation from "../../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation";
import {
    FaCircleXmark,
    FaTrash,
    FaFileCircleCheck,
    FaFileCircleXmark,
    FaChevronDown,
    FaChevronUp, FaGenderless
} from "react-icons/fa6";
import {
    FaArrowCircleDown,
    FaArrowCircleUp,
    FaBus,
    FaEdit,
    FaCheckCircle, FaPlusCircle, FaEllipsisH
} from "react-icons/fa";
import {IoMdClose, IoMdSettings} from "react-icons/io";
import ToastComp from "../../../Components/Toast/ToastComp";
import {Modal} from "react-bootstrap";
import axios from "axios";
import {urlVariable} from "../../../Axios/Axios";
import Loading from "../../../Components/Loading/Loading";
import LoadingForBtn from "../../../Components/Loading/LoadingForBtn/LoadingForBtn";
import {Link} from "react-router-dom";
import {RiAddCircleFill} from "react-icons/ri";
import {IoFilter} from "react-icons/io5";





function MessageFromUser() {

    // Toast State
    const [showToast, setShowToast] = useState(false)
    const [bodyToast, setBodyToast] = useState("")
    const [showSuccessfulToast, setShowSuccessfulToast] = useState(false)

    // dropdown value
    const [showDropDown, setShowDropDown] = useState("")


    // value for filter api
    const [originFilter, setOriginFilter] = useState({
        show: "",
        id: ""
    })
    const [originIDFilterAPi, setOriginIDFilterAPi] = useState()
    const [destinationFilter, setDestinationFilter] = useState({
        show: "",
        id: ""
    })
    const [destinationIDFilterApi, setDestinationIDFilterApi] = useState()
    const [typeCarFilter, setTypeCarFilter] = useState({
        show: "",
        id: ""
    })
    const [typeCarIDFilterApi, setTypeCarIDFilterApi] = useState()



    // Modal States
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showFilterIcon, setShowFilterIcon] = useState(false)


    // value for data
    const [barData, setBarData] = useState([])
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [totalData, setTotalData] = useState()


    const [loading, setLoading] = useState(false)
    const [loadingBTN, setLoadingBTN] = useState(false)
    const [loadingDropdown, setLoadingDropdown] = useState(false) // for city and group data
    const [refreshData, setRefreshData] = useState(false)
    const [statusUrl, setStatusUrl] = useState("active")

    // value of city list from api
    const [cityName, setCityName] = useState([])
    const [searchCityShow, setSearchCityShow] = useState("")
    const [searchCityApi, setSearchCityApi] = useState("")
    const [groupName, setGroupName] = useState([])
    const [typeCarName, setTypeCarName] = useState([])

    // value for edit data
    const [id, setId] = useState("")
    const [createdIn, setCreatedIn] = useState("");
    const [description, setDescription] = useState("");
    const [destination, setDestination] = useState("");
    const [destinationID, setDestinationID] = useState(""); // value for send api
    const [editOn, setEditOn] = useState("");
    const [group, setGroup] = useState("");
    const [origin, setOrigin] = useState("");
    const [originID, setOriginID] = useState("")  // value for send api
    const [phoneCall, setPhoneCall] = useState("");
    const [price, setPrice] = useState("");
    const [savecargo, setSavecargo] = useState("");
    const [status, setStatus] = useState({
        show: '',
        title: '',
        realData: ''
    });
    const [typeCar, setTypeCar] = useState("");
    const [typeCarID, setTypeCarID] = useState("");
    const [typeCargo, setTypeCargo] = useState("");
    const [weight, setWeight] = useState("");
    const [freeWeight, setFreeWeight] = useState(false)

    // value for pagenation
    // const [currentPage, setCurrentPage] = useState(1);
    // const [currentItems, setCurrentItems] = useState([]);


    useEffect(() => {
        setLoading(true)
        axios.get(`${urlVariable}/admin/cargo`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            },
            params: {
                pagination: true,
                source: "user",
                status: statusUrl,
                limit: limit,
                page:page,
                origin: originIDFilterAPi,
                destination: destinationIDFilterApi,
                typeCar: typeCarIDFilterApi,
            }
        })
            .then((res) => {
                console.log(res)
                setBarData(res.data.data);
                setTotalPage(res.data.total_pages)
                setTotalData(res.data.total_count)
                setLoading(false)
            })
            .catch(err => {
                setShowToast(true)
                setBodyToast("لطفا دوباره تلاش کنید!..")
                setShowSuccessfulToast(false)
                setLoading(false)
                if (err.message === "Network Error")
                    setBodyToast("اینترنت خود را بررسی کنید!..")
            })
    }, [refreshData, statusUrl, page, originIDFilterAPi, destinationIDFilterApi, typeCarIDFilterApi])

    useEffect(() => {

        if (showAddModal) {
            setDestination("")
            setDestinationID("")
            setOriginID("")
            setOrigin("")
            setTypeCarID("")
            setTypeCar("")
            setTypeCargo("")
            setWeight("")
            setPrice("")
            setDescription("")
            setPhoneCall("")
        }
    }, [showAddModal])


    useEffect(() => {

        if ((page > totalPage) && (totalPage > 0))
            setPage(totalPage)

    }, [totalPage]);


    useEffect(() => {

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/admin/groups`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then((res) => {
            // console.log(res);
            setGroupName(res.data.data);
            setLoadingDropdown(false)
        }).catch((err) => {
            setLoadingDropdown(true) // برای در حالت لودینگ بمونه که کاربر متوجه شه و دوباره رفرش کنه
            console.log(err)
        })

    }, []);

    useEffect(() => {
        setShowDropDown("")
    }, [showAddModal]);

    useEffect(() => {

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/groups/city`, {
            params: {
                search: searchCityApi
            }
        })
            .then((res) => {
                setCityName(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingDropdown(true) //دلیل خط 117
            })

    }, [searchCityApi])


    useEffect(() => {

        const delayedSetData = setTimeout(() => {
          setSearchCityApi(searchCityShow)
        }, 800)

        return () => clearTimeout(delayedSetData);

    }, [searchCityShow]);

    useEffect(() => {

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/car/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        })
            .then((res) => {
                setTypeCarName(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingDropdown(true)
            })

    }, [])

    useEffect(() => {

        if (showFilterModal) {
            setOrigin("")
            setDestination("")
            setOriginID("")
            setDestinationID("")
            setTypeCarID('')
            setTypeCar("")
            setShowDropDown("")
            setSearchCityShow("")
            setSearchCityApi("")
        }

    }, [showFilterModal]);


    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ' ';
        return Intl.DateTimeFormat('fa-IR').format(timestamp)
    };

    // function edit for edit modal
    function Edit(item) {

        console.log(item);
        setId(item._id?.$oid || "-")
        setCreatedIn(formatTimestamp(item.createdIn) || "-")
        setDescription(item.description || "-")
        setDestination(item.destination_detail || "-")
        setEditOn(formatTimestamp(item.editOn) || "-")
        setGroup((item.group?.$ref || item.group) ?? "-")
        setOrigin(item.origin_detail || "-")
        setPhoneCall(item.phoneCall || "-")
        setPrice(item.price || "-")
        if (item.savecargo) {
            if (item.savecargo === true)
                setSavecargo("ذخیره شده")
            if (item.savecargo === false)
                setSavecargo("ذخیره شده")
        }else setSavecargo("-")
        if (item.status) {
            if (item.status === "delete")
                setStatus({
                    show: "تاییده نشده",
                    title: item.status,
                    realData: item.status
                })
            if (item.status === "active")
                setStatus({
                    show: "تاییده شده",
                    title: item.status,
                    realData: item.status
                })
            if (item.status === "pending")
                setStatus({
                    show: "در انتظار تایید",
                    title: item.status,
                    realData: item.status
                })
        }else setStatus({show: "-", title: "-", realData: "-"})
        setTypeCar(item.carType_detail || "-")
        setTypeCargo(item.typeCargo || "-")
        setWeight(item.weight || "-")
        setShowEditModal(true)
        setShowDropDown("")
        setOriginID(item.origin[item.destination.length - 1]?._id?.$oid)
        setDestinationID(item.destination[item.destination.length - 1]?._id?.$oid)
        setTypeCarID(item.typeCar[item.typeCar.length - 1]?._id?.$oid)
    }

    function selectOrigin(value) {
        setOriginID(value._id.$oid)
        setOrigin(value.name)
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")

    }

    function selectDestination(value) {
        setDestinationID(value._id.$oid)
        setDestination(value.name)
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")
    }

    function selectTypeCar(value) {
        setTypeCarID(value._id.$oid)
        setTypeCar(value.name);
    }

    function dropdown(value) {
        if (showDropDown === value)
            setShowDropDown("")
        else setShowDropDown(value)
        setSearchCityShow("")
        setSearchCityApi("")
    }

    function Delete(iteam) {
        setId(iteam._id.$oid)
        setShowDeleteModal(true)
    }

    function deleteBarAPi() {

        setLoadingBTN(true)
        axios.delete(`${urlVariable}/admin/cargo/delete`, {
            data: {
                cargo_id: id,
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then((res) => {
            console.log(res)
            setShowToast(true)
            setBodyToast(res.data.msg)
            setShowSuccessfulToast(true)
            setShowDeleteModal(false)
            setLoadingBTN(false)
            setRefreshData(!refreshData)
        }).catch((err) => {
            console.log(err)
            setBodyToast(err.response.data.msg)
            setShowToast(true)
            setShowSuccessfulToast(false)
            setShowDeleteModal(false)
            setLoadingBTN(false)
        })

    }

    // edited for send api
    async function sendEditedToApi() {


        const edit = {
            _id: id,
            origin: originID,
            destination: destinationID,
            typeCar: typeCarID,
            typeCargo: typeCargo,
            phoneCall: phoneCall,
            // group: group,
            weight: weight,
            price: price,
            description: description,
            status: status.title,
        }

        setLoadingBTN(true);
        await axios.put(`${urlVariable}/admin/cargo/edit`, edit, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then((res) => {
            setShowToast(true);
            setBodyToast(res.data.msg);
            setShowSuccessfulToast(true);
            setLoadingBTN(false);
            setShowEditModal(false);
            setRefreshData(!refreshData);
        }).catch((err) => {
            setBodyToast(err.response.data.msg);
            setShowToast(true);
            setShowSuccessfulToast(false);
            setLoadingBTN(false);
        })
    }

    function addNewCargo() {

        const newCargo = {
            data: [
                {
                    origin: originID,
                    freeweight: Boolean(freeWeight),
                    typeCargo: typeCargo,
                    description: description,
                    price: Number(price),
                    weight: Number(weight),
                    typeCar: typeCarID,
                    destination: destinationID,
                    phoneNumber: phoneCall
                }
            ]
        }

        setLoadingBTN(true)
        axios.post(`${urlVariable}/admin/cargo/create`, newCargo, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then((res) => {
            setShowToast(true)
            setBodyToast(res.data.msg)
            setShowSuccessfulToast(true)
            setLoadingBTN(false)
            setShowAddModal(false)
            setRefreshData(!refreshData)
        }).catch((err) => {
            setBodyToast(err.response.data.msg)
            setShowToast(true)
            setShowSuccessfulToast(false)
            setLoadingBTN(false)
        })
    }

    function selectStatus(show, title) {
        setStatus({
            show: show,
            title: title
        })
        setShowDropDown("")
    }


    function selectOriginFilter (item) {
        setOriginFilter({
            show: item.name,
            id: item._id.$oid
        })
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")
    }


    function selectDestinationFilter (item) {
        setDestinationFilter({
            show: item.name,
            id: item._id.$oid
        })
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")
    }

    function selectTypeCarFilter (item) {
        setTypeCarFilter({
            show: item.name,
            id: item._id.$oid,
        })
        setSearchCityShow("")
        setSearchCityApi("")
        setShowDropDown("")
    }



    function setFilterForApi () {


        if (originFilter.id)
            setOriginIDFilterAPi(originFilter.id)
        else setOriginIDFilterAPi()
        if (destinationFilter.id)
            setDestinationIDFilterApi(destinationFilter.id)
        else setDestinationIDFilterApi()
        if (typeCarFilter.id)
            setTypeCarIDFilterApi(typeCarFilter.id)
        else setTypeCarIDFilterApi()

        if (originFilter.id || destinationFilter.id || typeCarFilter.id) {
            setShowFilterIcon(true)
            setShowFilterModal(false)
        }else setShowFilterIcon(false)
    }


    function removeAllFilter () {


        setOriginFilter({
            show: "",
            id: "",
        })
        setDestinationFilter({
            show: "",
            id: "",
        })
        setTypeCarFilter({
            show: "",
            id: "",
        })

        setOriginIDFilterAPi()
        setDestinationIDFilterApi()
        setTypeCarIDFilterApi()
        setShowFilterIcon(false)
        setShowFilterModal(false)
    }



    return (
        <>
            <div className="info-list">
                <Breadcrumbs
                    items={[{name: 'بارها', link: '/pannel/message/fromUser'},
                        {name: 'بارهای ارسالی کاربران', link: '/pannel/message/fromUser'}]}
                />

                <InfoListNavigation
                    btntitle='افزودن بار جدید'
                    onShowModal={setShowAddModal}
                    hasFilter={true}
                    hasFilterShow={showFilterIcon}
                    onShowFilter={setShowFilterModal}
                    setStatus={setStatusUrl}
                    status={statusUrl}
                    items={[
                        ['بارهای فعال', 'active', <FaFileCircleCheck className='icon'/>],
                        ['بارهای غیرفعال', 'delete', <FaFileCircleXmark className='icon'/>],
                    ]}
                />


                <div className="info-list-table-section">
                    <table className='info-list-table'>
                        <thead>
                        <tr className='info-list-tr-head'>
                            <th>
                                <FaArrowCircleDown style={{marginLeft: '3px', fontSize: '13px'}}/>
                                مبدا
                            </th>
                            <th className='td-res-1'>
                                <FaArrowCircleUp style={{marginLeft: '3px', fontSize: '13px'}}/>
                                مقصد
                            </th>
                            <th className='td-res-1'>
                                <FaBus style={{marginLeft: '3px', fontSize: '13px'}}/>
                                نوع ناوگان
                            </th>
                            <th>
                                <IoMdSettings style={{marginLeft: '3px', fontSize: '16px'}}/>
                                عملیات
                            </th>
                        </tr>
                        </thead>
                        <tbody>

                        {loading ?

                            (<tr>
                                <td colSpan="4"><Loading/></td>
                            </tr>)
                            :
                            (barData.length ?
                                    barData.map((value, index) =>
                                        <tr key={value._id?.$oid} className='info-list-row'>
                                            <td>{value.origin_detail ?? "-"}</td>
                                            <td className='td-res-1'>{value.destination_detail ?? "-"}</td>
                                            <td className='td-res-1'>{(value.carType_detail.$ref ? value.carType_detail?.$ref : value.carType_detail) ?? "-"}</td>
                                            <td>
                                                    <span style={{color: 'var(--color-1)', cursor: 'pointer'}}
                                                          onClick={() => Edit(value)}>ویرایش</span>
                                                <span style={{margin: '0 3px'}}>/</span>
                                                <span style={{color: 'var(--danger-color)', cursor: 'pointer'}}
                                                      onClick={() => Delete(value)}>حذف</span>
                                            </td>
                                        </tr>
                                    ) :
                                    <tr>
                                        <td colSpan="4" style={{
                                            textAlign: 'center',
                                            padding: '10px 0',
                                            backgroundColor: 'var(--bg-color-2)'
                                        }}>در حال حاضر باری نداریم!
                                        </td>
                                    </tr>
                            )
                        }


                        </tbody>
                    </table>
                </div>

                {/* Pagination Section */}
                {totalData > limit ?
                    <div className="pagination-section">
                        <button className='pagination-btn' disabled={page <= 1}
                                style={page === 1 ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page - 1)}>قبلی
                        </button>
                        <div>صفحه {page} از {totalPage}</div>
                        <button className='pagination-btn' disabled={page >= totalPage}
                                style={page === totalPage ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page + 1)}>بعدی
                        </button>
                    </div>
                    : null}



            </div>

            {/* Toast */}
            <ToastComp
                title={showSuccessfulToast ? "عملیات موفق" : "عملیات ناموفق"}
                body={bodyToast}
                icon={showSuccessfulToast ? <FaCheckCircle style={{marginLeft: '5px'}}/> :
                    <FaCircleXmark style={{marginLeft: '5px'}}/>}
                onShowToast={setShowToast}
                showToastValue={showToast}
                toastColor={showSuccessfulToast ? 'var(--color-1)' : 'var(--danger-color)'}
            />

            {/* Delete Modal */}
            <Modal
                show={showDeleteModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">حذف بار</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    آیا از حذف این بار اطمینان دارید؟
                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowDeleteModal(false)}>لغو
                    </button>
                    <button className='modal-btn modal-red-btn' onClick={deleteBarAPi}>

                        {loadingBTN ? <LoadingForBtn sytle={{fontSize: "10px"}}/> : (<> <FaTrash className='icon'
                                                                                                 style={{
                                                                                                     marginLeft: '4px',
                                                                                                     fontSize: '15px'
                                                                                                 }}/> حذف</>)}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showFilterModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">فیلتر بار</span>
                            <span className="hover-close-model"
                                  onClick={() => setShowFilterModal(false)}><IoMdClose/></span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">


                        <div className="row">

                            <div className="col">

                                <div className="modal-dropdown-container">

                                    <div className='modal-input-container' onClick={() => dropdown("origin")}>
                                        <label style={{textAlign: "right"}}>
                                            مبدا
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input  value={originFilter.show}/>
                                            {showDropDown === "origin" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "origin" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            <input className='search-dropdown-input' autoComplete="off"
                                                   placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ?
                                                    cityName.map((item, index) => (
                                                        <li key={index} onClick={() => selectOriginFilter(item)}>
                                                            {item.name}
                                                        </li>
                                                    ))
                                                    :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>

                                        : null}

                                </div>

                            </div>

                            <div className="col">

                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container' onClick={() => dropdown("destination")}>
                                        <label style={{textAlign: "right"}}>
                                            مقصد
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={destinationFilter.show}/>
                                            {showDropDown === "destination" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "destination" ?
                                        <ul className="modal-dropdown-list"
                                            style={{textAlign: "right", height: "10rem"}}>
                                            <input className='search-dropdown-input' autoComplete="off"
                                                   placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ? cityName.map((item, index) => (
                                                        <li key={index} onClick={() => selectDestinationFilter(item)}>
                                                            {item.name}
                                                        </li>
                                                    ))
                                                    :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>
                                        : null}
                                </div>

                            </div>

                        </div>
                        <div className="row">

                            <div className="col">

                                <div className="modal-dropdown-container" onClick={() => dropdown("typeCar")}>
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            نوع ناوگان
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={typeCarFilter.show}/>
                                            {showDropDown === "typeCar" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>
                                    {showDropDown === "typeCar" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                (typeCarName && typeCarName.map((item, index) => (
                                                    <li key={index} onClick={() => selectTypeCarFilter(item)}>
                                                        {item.name}
                                                    </li>
                                                )))}
                                        </ul>
                                        : null}

                                </div>

                            </div>
                        </div>


                    </div>

                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={removeAllFilter}>حذف فیلترها
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={setFilterForApi}>
                        <IoFilter className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>
                        اعمال فیلتر

                    </button>
                </Modal.Footer>
            </Modal>


            {/* Edit Modal */}
            <Modal
                show={showEditModal}
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section" style={{justifyContent: "space-around"}}>
                            <span className="modal-title">ویرایش بار</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">

                        <div className="row">

                            <div className="col">
                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container' onClick={() => dropdown("status")}>
                                        <label style={{textAlign: "right"}}>
                                            وضعیت
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={status.show}/>
                                            {status.realData === "active" ?
                                                null
                                                :
                                                showDropDown === "status" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {status.realData === "active" ?
                                        null
                                        :
                                        showDropDown === "status" ?
                                        <ul className="modal-dropdown-list"
                                            style={{textAlign: "right"}}>
                                            <li onClick={() => selectStatus("تایید شده", "active")}>
                                                تایید شده
                                            </li>
                                        </ul>
                                        : null}
                                </div>

                            </div>

                        </div>

                        <div className="row">

                            <div className="col-sm">

                                <div className="modal-dropdown-container" >

                                    <div className='modal-input-container' onClick={() => dropdown("origin")}>
                                        <label style={{textAlign: "right"}}>
                                            مبدا
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={origin}/>
                                            {showDropDown === "origin" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "origin" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            <input className='search-dropdown-input' autoComplete="off" placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ?
                                                    cityName.map((item, index) => (
                                                    <li key={index} onClick={() => selectOrigin(item)}>
                                                        {item.name}
                                                    </li>
                                                ))
                                                    :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>

                                        : null}

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className="modal-dropdown-container" >
                                    <div className='modal-input-container' onClick={() => dropdown("destination")}>
                                        <label style={{textAlign: "right"}}>
                                            مقصد
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={destination}/>
                                            {showDropDown === "destination" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "destination" ?
                                        <ul className="modal-dropdown-list"
                                            style={{textAlign: "right", height: "10rem"}}>
                                            <input className='search-dropdown-input' autoComplete="off" placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ? cityName.map((item, index) => (
                                                    <li key={index} onClick={() => selectDestination(item)}>
                                                        {item.name}
                                                    </li>
                                                ))
                                                    :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>
                                        : null}
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className="modal-dropdown-container" onClick={() => dropdown("typeCar")}>
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            نوع ناوگان
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={typeCar}/>
                                            {showDropDown === "typeCar" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>
                                    {showDropDown === "typeCar" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            {loadingDropdown ?
                                                <Loading/>
                                                :
                                                (typeCarName && typeCarName.map((item, index) => (
                                                    <li key={index} onClick={() => selectTypeCar(item)}>
                                                        {item.name}
                                                    </li>
                                                )))}
                                        </ul>
                                        : null}

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        نوع بار
                                    </label>
                                    <input value={typeCargo}
                                           onChange={(e) => setTypeCargo(e.target.value)}/>

                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        تلفن
                                    </label>
                                    <input type="number" value={phoneCall}
                                           onChange={(e) => setPhoneCall(e.target.value)}/>

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className="modal-dropdown-container" onClick={() => dropdown("group")}>
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            گروه
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={group}/>
                                            {showDropDown === "group" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>
                                    {showDropDown === "group" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            {loadingDropdown ?
                                                <Loading/>
                                                :
                                                (groupName && groupName.map((item, index) => (
                                                    <li key={index} onClick={() => setGroup(item.name)}>
                                                        {item.name}
                                                    </li>
                                                )))}
                                        </ul>
                                        : null}

                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        وزن (تن)
                                    </label>
                                    <input type="number" value={weight}
                                           onChange={(e) => setWeight(e.target.value)}/>

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        قیمت
                                    </label>
                                    <input type="number" className="input-group" value={price}
                                           onChange={(e) => setPrice(e.target.value)}/>

                                </div>
                            </div>

                        </div>


                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        ایجاد شده در
                                    </label>
                                    <input value={createdIn}/>

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        آخرین ویرایش
                                    </label>
                                    <input value={editOn}/>

                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        توضیحات
                                    </label>
                                    <input value={description}
                                           onChange={(e) => setDescription(e.target.value)}/>

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        ذخیره بار
                                    </label>
                                    <input value={savecargo}/>

                                </div>
                            </div>

                        </div>


                    </div>

                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowEditModal(false)}>لغو
                    </button>
                    <button className='modal-btn modal-green-btn'
                            onClick={sendEditedToApi}>
                        {loadingBTN ?
                            <LoadingForBtn/>
                            :
                            (<><FaEdit className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>ویرایش</>)
                        }
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Add Modal */}
            <Modal
                show={showAddModal}
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section" style={{justifyContent: "space-around"}}>
                            <span className="modal-title">افزودن بار</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">

                        <div className="row">

                            <div className="col-sm">

                                <div className="modal-dropdown-container" >
                                    <div className='modal-input-container' onClick={() => dropdown("origin")}>
                                        <div style={{textAlign: "right"}}>
                                            مبدا
                                        </div>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={origin}/>
                                            {showDropDown === "origin" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "origin" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            <input className='search-dropdown-input' autoComplete="off" placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ?
                                                    cityName.map((item, index) => (
                                                    <li key={index} onClick={() => selectOrigin(item)}>
                                                        {item.name}
                                                    </li>
                                                ))
                                                    :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>
                                        : null}

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container' onClick={() => dropdown("destination")}>
                                        <div style={{textAlign: "right"}}>
                                            مقصد
                                        </div>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={destination}/>
                                            {showDropDown === "destination" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "destination" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            <input className='search-dropdown-input' autoComplete="off" placeholder="جست و جو ..." value={searchCityShow}
                                                   onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ?
                                                    cityName.map((item, index) => (
                                                        <li key={index} onClick={() => selectDestination(item)}>
                                                            {item.name}
                                                        </li>
                                                    ))
                                                    :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>
                                        : null}


                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className="modal-dropdown-container" onClick={() => dropdown("typeCar")}>
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            نوع ناوگان
                                        </label>

                                        <div className='modal-dropdown-input-container'>
                                            <input value={typeCar}/>
                                            {showDropDown === "typeCar" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>
                                    {showDropDown === "typeCar" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            {loadingDropdown ?
                                                <Loading/>
                                                :
                                                typeCarName && typeCarName.map((item, index) => (
                                                    <li key={index} onClick={() => selectTypeCar(item)}>
                                                        {item.name}
                                                    </li>
                                                ))}
                                        </ul>
                                        : null}

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: 'right'}}>
                                        نوع بار
                                    </label>
                                    <input value={typeCargo}
                                           onChange={(e) => setTypeCargo(e.target.value)}/>

                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        توضیحات
                                    </label>
                                    <input value={description}
                                           onChange={(e) => setDescription(e.target.value)}/>

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: 'right'}}>
                                        شماره موبایل
                                    </label>
                                    <input type="number" value={phoneCall}
                                           onChange={(e) => setPhoneCall(e.target.value)}/>

                                </div>
                            </div>

                        </div>


                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        وزن (تن)
                                    </label>
                                    <input type="number" value={weight}
                                           onChange={(e) => setWeight(e.target.value)}/>
                                    <div style={{marginTop: "4px", textAlign: "right"}}>
                                        <input style={{width: "auto"}} type="checkbox" id="freeWeight"
                                               checked={freeWeight}
                                               onChange={() => setFreeWeight(!freeWeight)}/>
                                        <label style={{marginRight: "4px"}} htmlFor="freeWeight">تناژ آزاد</label>
                                    </div>

                                </div>
                            </div>


                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        قیمت
                                    </label>
                                    <input type="number" value={price}
                                           onChange={(e) => setPrice(e.target.value)}/>

                                </div>
                            </div>

                        </div>


                    </div>

                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>

                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowAddModal(false)}>لغو
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={addNewCargo}>
                        {loadingBTN ?
                            <LoadingForBtn/>
                            :
                            (<><FaPlusCircle className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>افزودن بار
                                جدید</>)
                        }
                    </button>

                </Modal.Footer>
            </Modal>
        </>
    )
}


export default MessageFromUser;