import React, {useState, useEffect} from 'react';
import './AllUsers.css';
import InfoListTitle from '../../../Components/InfoListComponents/InfoListTitle/InfoListTitle';
import InfoListNavigation from '../../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation';
import {Container, Row, Col, Modal} from 'react-bootstrap';
import {
    FaUser,
    FaUserCheck,
    FaUserLargeSlash,
    FaCircleXmark,
    FaTrash,
    FaChevronDown,
    FaUserPlus, FaChevronUp
} from "react-icons/fa6";
import {GrStatusInfo} from "react-icons/gr";
import {IoMdClose} from "react-icons/io";
import {FaCheckCircle} from "react-icons/fa";
import {IoMdSettings} from "react-icons/io";
import {IoFilter} from "react-icons/io5";
import Loading from '../../../Components/Loading/Loading';
import LoadingForBtn from '../../../Components/Loading/LoadingForBtn/LoadingForBtn';
import axios from 'axios';
import Breadcrumbs from '../../../Components/Breadcrumbs/Breadcrumbs';
import ToastComp from '../../../Components/Toast/ToastComp';
import {FaDumpsterFire, FaEdit, FaPhone, FaUserTie} from 'react-icons/fa';
import {handleErrors, urlVariable} from '../../../Axios/Axios';
import dropdown from "bootstrap/js/src/dropdown";
import {Link} from "react-router-dom";


export default function PassengersList() {

    // Toast State
    const [showToast, setShowToast] = useState(false)
    const [toastTitle, setToastTitle] = useState('')
    const [toastBody, setToastBody] = useState('')
    const [toastIcon, setToastIcon] = useState()
    const [toastColor, setToastColor] = useState('')


    // this value for Register Type
    const [switchTypeRegister, setSwitchTypeRegister] = useState("driver")

    // just use for get city and car list
    const [cityList, setCityList] = useState([])
    const [searchCityShow, setSearchCityShow] = useState("")
    const [searchCityApi, setSearchCityApi] = useState("")
    const [typeCarList, setTypeCarList] = useState([])

    // Common values for Register
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [codeMely, setCodeMely] = useState('')
    const [city, setCity] = useState('')
    const [cityID, setCityID] = useState('')    // just use for send to api

    // value for driver register
    const [typeCar, setTypeCar] = useState('')
    const [typeCarID, setTypeCarID] = useState('')  // just use for send to api

    // value for freight register
    const [companyName, setCompanyName] = useState('')

    // loading value
    const [loadingDropdown, setLoadingDropdown] = useState(false)
    const [loadingForBtn, setLoadingForBtn] = useState(false)
    const [loading, setLoading] = useState(false)
    const [refreshData, setRefreshData] = useState(false)


    // Modal States
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [hasFilterShow, setHasFilterShow] = useState(false)

    // value for filter users
    const [userFilter, setUserFilter] = useState('')
    const [phoneNumberFilter, setPhoneNumberFilter] = useState('')
    const [userFilterApi, setUserFilterApi] = useState('')
    const [phoneNumberFilterApi, setPhoneNumberFilterApi] = useState('')


    // Get All Users
    const [allUsers, setAllUsers] = useState([])
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [totalData, setTotalData] = useState()

    // Delete User
    const [userId, setUserId] = useState()
    const [statusURl, setStatusURl] = useState("active")
    const [userTypeAPi, setUserTypeApi] = useState("")


    useEffect(() => {    // get city list api

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/groups/city`, {
            params: {
                search: searchCityApi
            }
        })
            .then((res) => {
                setCityList(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                setLoadingDropdown(true)
            })

    }, [searchCityApi])

    useEffect(() => {

        const delayedSetData = setTimeout(() => {
            setSearchCityApi(searchCityShow)
        }, 800)

        return () => clearTimeout(delayedSetData);

    }, [searchCityShow]);

    useEffect(() => {    // get car list api

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/car/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        })
            .then((res) => {
                setTypeCarList(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                handleErrors(err)
                setLoadingDropdown(true)
            })

    }, [])

    useEffect(() => {

        if ((page > totalPage) && (totalPage > 0))
            setPage(totalPage)

    }, [totalPage]);

    useEffect(() => {

        setLoading(true)
        axios.get(`${urlVariable}/admin/user/`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
                },
                params: {
                    limit: limit,
                    page: page,
                    status: statusURl,
                    role: userTypeAPi,
                    name: userFilterApi,
                    phone_Number: phoneNumberFilterApi,
                }
            })
            .then(res => {
                setAllUsers(res.data.data)
                setTotalPage(res.data.total_pages)
                setTotalData(res.data.total_count)
                setLoading(false)
            })
            .catch(err => {
                handleErrors(err)
                if (err.message === "Network Error") {
                    setToastTitle('عملیات ناموفق')
                    setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setShowToast(true)
                    setLoading(false)
                } else {
                    if (err.response && err.response.status >= 500) {
                        setToastTitle('عملیات ناموفق')
                        setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                        setToastColor('var(--danger-color)')
                        setShowToast(true)
                        setToastBody("خطای رخ در سرور داده لطفا دوباره تلاش کتید!..")
                        setLoading(false)
                    } else {
                        setToastTitle('عملیات ناموفق')
                        setToastBody('خطا در بارگیری اطلاعات')
                        setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                        setToastColor('var(--danger-color)')
                        setShowToast(true)
                        setLoading(false)
                    }
                }

            })
    }, [statusURl, refreshData, page, userFilterApi, phoneNumberFilterApi, userTypeAPi])

    useEffect(() => {
        setPage(1)
    }, [statusURl]);


    function deleteHandle(value) {
        setUserId(value._id?.$oid)
        setShowDeleteModal(true)
    }

    function deleteApiHandler() {

        setLoadingForBtn(true)
        axios.delete(`${urlVariable}/admin/user/delete`, {
            data: {
                _id: userId,
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
            }
        }).then((res) => {
            setShowToast(true)
            setToastTitle("عملیات موفق")
            setToastBody("کاربر مورد نظر با موفقیت حذف شد")
            setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
            setToastColor('var(--color-1)')
            setShowDeleteModal(false)
            setLoadingForBtn(false)
            setRefreshData(!refreshData)
        }).catch((err) => {
            handleErrors(err)
            if (err.message === "Network Error") {
                setToastTitle('عملیات ناموفق')
                setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setShowToast(true)
                setLoadingForBtn(false)
                setShowDeleteModal(false)
            }
            if (err.response && err.response.status <= 499) {
                setShowToast(true)
                setToastTitle("عملیات ناموفق")
                setToastBody(err.response.data.msg)
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowDeleteModal(false)
            } else {
                if (err.response && err.response.status >= 500) {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody("خطای در سرور رخ داده است لطفا دوباره تلاش کنید!..")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowDeleteModal(false)
                } else {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody("خطا در بارگیری اطلاعات")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowDeleteModal(false)
                }
            }

        })

    }


    function setFilterForApi() {
        setUserFilterApi(userFilter)
        setPhoneNumberFilterApi(phoneNumberFilter)
        if (phoneNumberFilter || userFilter) {
            setHasFilterShow(true)
        } else setHasFilterShow(false)
        setShowFilterModal(false)
    }

    function setRemoveAllFilter() {
        setUserFilterApi("")
        setPhoneNumberFilterApi("")
        setUserFilter("")
        setPhoneNumberFilter("")
        setHasFilterShow(false)
    }


    function Register() {

        if (switchTypeRegister === "driver")  // راننده
            registerDriver()
        if (switchTypeRegister === "freight")  // باربری
            registerfreight()
        if (switchTypeRegister === "bar owner")
            registerBarOwner()
        if (switchTypeRegister === "curator")
            registerCurator()
    }


    function registerDriver() {

        const info = {
            name: name,
            phoneNumber: phoneNumber,
            carType: typeCarID,
            city: cityID,
            codeMely: codeMely,
        }

        setLoadingForBtn(true)
        axios.post(`${urlVariable}/admin/user/driver`, info, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
            }
        }).then((res) => {
            setShowToast(true)
            setToastTitle("عملیات موفق")
            setToastBody("راننده مورد نظر با موفقیت ثبت شد")
            setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
            setToastColor('var(--color-1)')
            setShowAddModal(false)
            setLoadingForBtn(false)
            setRefreshData(!refreshData)
        }).catch((err) => {
            handleErrors(err)
            if (err.message === "Network Error") {
                setShowToast(true)
                setToastTitle('عملیات ناموفق')
                setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowAddModal(true)
            }
            if (err.response && err.response.status <= 499) {
                setShowToast(true)
                setToastTitle("عملیات ناموفق")
                setToastBody(err.response.data.msg)
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowAddModal(true)
            } else {
                if (err.response && err.response.status >= 500) {
                    setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowAddModal(true)
                } else {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody("خطا در بارگیری اطلاعات")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowAddModal(true)
                }
            }

        })

    }


    function registerfreight() {

        const info = {
            companyOwnerName: name,
            ownerCodemely: codeMely,
            companyName: companyName,
            city: cityID,
            phoneNumber: phoneNumber,
        }
        setLoadingForBtn(true)
        axios.post(`${urlVariable}/admin/user/freight`, info, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
            }
        }).then((res) => {
            setShowToast(true)
            setToastTitle("عملیات موفق")
            setToastBody("باربری مورد نظر با موفقیت ثبت شد")
            setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
            setToastColor('var(--color-1)')
            setShowAddModal(false)
            setLoadingForBtn(false)
            setRefreshData(!refreshData)
        }).catch((err) => {
            handleErrors(err)
            if (err.message === "Network Error") {
                setShowToast(true)
                setToastTitle('عملیات ناموفق')
                setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowAddModal(true)
            }
            if (err.response && err.response.status <= 499) {
                setShowToast(true)
                setToastTitle("عملیات ناموفق")
                setToastBody(err.response.data.msg)
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowAddModal(true)
            } else {
                if (err.response && err.response.status >= 500) {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowAddModal(true)
                    setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
                } else {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody("خطا در بارگیری اطلاعات")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowAddModal(true)
                }
            }

        })
    }

    function registerBarOwner() {

        const info = {
            phoneNumber: phoneNumber,
            codeMely: codeMely,
            carType: typeCarID,
            city: cityID,
            name: name,
        }

        setLoadingForBtn(true)
        axios.post(`${urlVariable}/admin/user/cargoOwner`, info, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
            }
        }).then((res) => {
            setShowToast(true)
            setToastTitle("عملیات موفق")
            setToastBody("صاحب بار مورد نظر با موفقیت ثبت شد")
            setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
            setToastColor('var(--color-1)')
            setShowAddModal(false)
            setLoadingForBtn(false)
            setRefreshData(!refreshData)
        }).catch((err) => {
            handleErrors(err)
            if (err.message === "Network Error") {
                setShowToast(true)
                setToastTitle('عملیات ناموفق')
                setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowAddModal(true)
            }
            if (err.response && err.response.status <= 499) {
                setShowToast(true)
                setToastTitle("عملیات ناموفق")
                setToastBody(err.response.data.msg)
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowAddModal(true)
            } else {
                if (err.response && err.response.status >= 500) {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowAddModal(true)
                    setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
                } else {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody("خطا در بارگیری اطلاعات")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowAddModal(true)
                }
            }

        })
    }

    function registerCurator() {

        const info = {
            phoneNumber: phoneNumber,
            name: name,
            carType: typeCarID,
            city: cityID,
            codeMely: codeMely,
        }

        setLoadingForBtn(true)
        axios.post(`${urlVariable}/admin/user/curator`, info, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
            }
        }).then((res) => {
            setShowToast(true)
            setToastTitle("عملیات موفق")
            setToastBody("متصدی مورد نظر با موفقیت ثبت شد")
            setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
            setToastColor('var(--color-1)')
            setShowAddModal(false)
            setLoadingForBtn(false)
            setRefreshData(!refreshData)
        }).catch((err) => {
            handleErrors(err)
            if (err.message === "Network Error") {
                setShowToast(true)
                setToastTitle('عملیات ناموفق')
                setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowAddModal(true)
            }
            if (err.response && err.response.status <= 499) {
                setShowToast(true)
                setToastTitle("عملیات ناموفق")
                setToastBody(err.response.data.msg)
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowAddModal(true)
            } else {
                if (err.response && err.response.status >= 500) {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowAddModal(true)
                    setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
                } else {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody("خطا در بارگیری اطلاعات")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowAddModal(true)
                }
            }

        })
    }


    return (
        <>
            <div className="info-list">

                <Breadcrumbs
                    items={[
                        {name: 'کاربران', link: '/pannel/users/all'},
                        {name: 'همه کاربران', link: '/pannel/users/all'}
                    ]}
                />

                <div className='platform-btns-container'>

                    <button
                        className={`${userTypeAPi === "" && "active"}`}
                        onClick={() => setUserTypeApi("")}
                    >همه کاربران
                    </button>
                    <button
                        className={`${userTypeAPi === "driver" && "active"}`}
                        onClick={() => setUserTypeApi("driver")}
                    >رانندگان
                    </button>

                    <button
                        className={`${userTypeAPi === "cargoOwners" && "active"}`}
                        onClick={() => setUserTypeApi("cargoOwners")}
                    >صاحبین بار
                    </button>

                    <button
                        className={`${userTypeAPi === "Curator" && "active"}`}
                        onClick={() => setUserTypeApi("Curator")}
                    >متصدیان
                    </button>

                    <button
                        className={`${userTypeAPi === "freight" && "active"}`}
                        onClick={() => setUserTypeApi("freight")}
                    >باربری
                    </button>
                </div>

                {/* Toast */}
                <ToastComp
                    title={toastTitle}
                    body={toastBody}
                    icon={toastIcon}
                    onShowToast={setShowToast}
                    showToastValue={showToast}
                    toastColor={toastColor}
                />

                <InfoListNavigation
                    btntitle='افزودن کاربر جدید'
                    onShowModal={setShowAddModal}
                    hasFilterShow={hasFilterShow}
                    hasFilter={true}
                    onShowFilter={setShowFilterModal}
                    setStatus={setStatusURl}
                    status={statusURl}
                    items={[
                        ['کاربران فعال', 'active', <FaUserCheck className='icon'/>],
                        ['کاربران غیرفعال', 'deleted', <FaUserLargeSlash className='icon'/>]
                    ]}
                />

                <div className="info-list-table-section">
                    <table className='info-list-table'>
                        <thead>
                        <tr className='info-list-tr-head'>
                            <th>
                                <FaUser style={{marginLeft: '3px', fontSize: '13px'}}/>
                                نام و نام‌خانوادگی
                            </th>
                            <th className='td-res-1'>
                                <FaPhone style={{marginLeft: '3px', fontSize: '13px'}}/>
                                شماره موبایل
                            </th>
                            <th className='td-res-1'>
                                <FaUserTie style={{marginLeft: '3px', fontSize: '13px'}}/>
                                نقش کاربر
                            </th>
                            <th className='td-res-1'>
                                <GrStatusInfo style={{marginLeft: '3px', fontSize: '13px'}}/>
                                وضعیت
                            </th>
                            <th>
                                <IoMdSettings style={{marginLeft: '3px', fontSize: '16px'}}/>
                                عملیات
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {loading ?
                            <tr>
                                <td colSpan='5'>
                                    <Loading/>

                                </td>
                            </tr>
                            :
                            (allUsers.length ?
                                    allUsers && allUsers.map((value) => {
                                        return (
                                            <tr key={value._id.$oid} className='info-list-row'>
                                                <td>{value.name || value.companyOwnerName}</td>
                                                <td className='td-res-1'>{value.phoneNumber}</td>
                                                <td className='td-res-1'>
                                                    {value.userType === 'driver' && 'راننده'}
                                                    {value.userType === 'cargoOwners' && 'صاحبین بار'}
                                                    {value.userType === 'Curator' && 'متصدی'}
                                                    {value.userType === 'freight' && 'باربری'}
                                                </td>
                                                <td className='td-res-1'>{value.status === "deleted" ?
                                                    <span style={{color: "var(--color-3)"}}> غیر فعال</span>
                                                    :
                                                    <span style={{color: "var(--color-4)"}}>فعال</span>
                                                }</td>
                                                <td>
                                                    <Link to={`/pannel/users/Details/${value._id?.$oid}`}
                                                          style={{color: 'var(--color-1)', cursor: 'pointer',}}
                                                          className="link"
                                                    >جزئیات</Link>
                                                    {value.status !== "deleted" &&
                                                        <>
                                                            <span style={{margin: '0 3px'}}>/</span>
                                                            <span style={{color: 'var(--danger-color)', cursor: 'pointer'}}
                                                                  onClick={() => deleteHandle(value)}>حذف</span>
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    hasFilterShow
                                         ?
                                            <td colSpan="6" style={{
                                                textAlign: 'center',
                                                padding: '10px 0',
                                                backgroundColor: 'var(--bg-color-2)'
                                            }}>کاربری یافت نشد!
                                            </td>
                                            :
                                            <td colSpan="6" style={{
                                                textAlign: 'center',
                                                padding: '10px 0',
                                                backgroundColor: 'var(--bg-color-2)'
                                            }}>در حال حاضر کاربری نداریم!
                                            </td>

                            )
                        }
                        </tbody>
                    </table>
                </div>

                {/* Pagination Section */}
                {totalData > limit ?
                    <div className="pagination-section">
                        <button className='pagination-btn' disabled={page <= 1}
                                style={page === 1 ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page - 1)}>قبلی
                        </button>
                        <div>صفحه {page} از {totalPage}</div>
                        <button className='pagination-btn' disabled={page >= totalPage}
                                style={page === totalPage ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page + 1)}>بعدی
                        </button>
                    </div>
                    : null}


            </div>

            {/* Toast */}
            <ToastComp
                title={toastTitle}
                body={toastBody}
                icon={toastIcon}
                onShowToast={setShowToast}
                showToastValue={showToast}
                toastColor={toastColor}
            />


            {/* Delete Modal */}
            <Modal
                show={showDeleteModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">حذف مسافر</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    آیا از حذف کاربر مورد نظر اطمینان دارید؟
                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowDeleteModal(false)}>لغو
                    </button>
                    <button className='modal-btn modal-red-btn' onClick={deleteApiHandler}>
                        {loadingForBtn ? <LoadingForBtn/> : <><FaTrash className='icon'/>
                            حذف</>}
                    </button>
                </Modal.Footer>
            </Modal>


            {/* Add Modal */}
            <Modal
                show={showAddModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">افزودن کاربر جدید</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{textAlign: 'right', fontWeight: 'normal'}}>
                        <Row>
                            <Col xs={12}>
                                <div className='modal-input-container'>
                                    <label>نقش کاربر را تعیین کنید</label>
                                    <div className='modal-role-container'>
                                        <div className={`role ${switchTypeRegister === "driver" ? "active" : null}`}
                                             onClick={() => setSwitchTypeRegister("driver")}>راننده
                                        </div>
                                        <div className={`role ${switchTypeRegister === "freight" ? "active" : null}`}
                                             onClick={() => setSwitchTypeRegister("freight")}>باربری
                                        </div>
                                        <div className={`role ${switchTypeRegister === "bar owner" ? "active" : null}`}
                                             onClick={() => setSwitchTypeRegister("bar owner")}>صاحب بار
                                        </div>
                                        <div className={`role ${switchTypeRegister === "curator" ? "active" : null}`}
                                             onClick={() => setSwitchTypeRegister("curator")}>متصدی
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>


                        {switchTypeRegister === "driver" ?
                            <RegisterDriver setName={setName} setCodeMely={setCodeMely} setPhoneNumber={setPhoneNumber}
                                            typeCar={typeCar} setTypeCar={setTypeCar} setTypeCarID={setTypeCarID}
                                            typeCarList={typeCarList}
                                            city={city} setCity={setCity} setCityID={setCityID} cityList={cityList}
                                            loadingDropdown={loadingDropdown} searchCityShow={searchCityShow}
                                            setSearchCityShow={setSearchCityShow}/>
                            :
                            switchTypeRegister === "freight" ?
                                <RegisterFreight setName={setName} setCodeMely={setCodeMely}
                                                 setPhoneNumber={setPhoneNumber}
                                                 setCompanyName={setCompanyName}
                                                 city={city} setCity={setCity} setCityID={setCityID} cityList={cityList}
                                                 loadingDropdown={loadingDropdown} searchCityShow={searchCityShow}
                                                 setSearchCityShow={setSearchCityShow}/>
                                :
                                switchTypeRegister === "bar owner" ?
                                    <RegisterBarOwner setName={setName} setCodeMely={setCodeMely}
                                                      setPhoneNumber={setPhoneNumber}
                                                      typeCar={typeCar} setTypeCar={setTypeCar}
                                                      setTypeCarID={setTypeCarID}
                                                      typeCarList={typeCarList}
                                                      city={city} setCity={setCity} setCityID={setCityID}
                                                      cityList={cityList}
                                                      loadingDropdown={loadingDropdown} searchCityShow={searchCityShow}
                                                      setSearchCityShow={setSearchCityShow}/>
                                    :
                                    switchTypeRegister === "curator" ?
                                        <RegisterCurator setName={setName} setCodeMely={setCodeMely}
                                                         setPhoneNumber={setPhoneNumber}
                                                         typeCar={typeCar} setTypeCar={setTypeCar}
                                                         setTypeCarID={setTypeCarID}
                                                         typeCarList={typeCarList}
                                                         city={city} setCity={setCity} setCityID={setCityID}
                                                         cityList={cityList}
                                                         loadingDropdown={loadingDropdown}
                                                         searchCityShow={searchCityShow}
                                                         setSearchCityShow={setSearchCityShow}
                                        />
                                        :
                                        null
                        }


                    </Container>
                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowAddModal(false)}>بازگشت
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={Register}>
                        {loadingForBtn ?
                            <LoadingForBtn/>
                            :
                            (<><FaUserPlus className='icon'/>
                                افزودن</>)
                        }

                    </button>
                </Modal.Footer>
            </Modal>


            {/*filter modal */}
            <Modal
                show={showFilterModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">فیلتر کاربران</span>
                            <span className="hover-close-model"
                                  onClick={() => setShowFilterModal(false)}><IoMdClose/></span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">


                        <div className="row">
                            <div className="col">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        نام و نام خانوادگی
                                    </label>
                                    <input value={userFilter} onChange={(e) => setUserFilter(e.target.value)}/>
                                </div>

                            </div>

                            <div className="col">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        شماره موبایل
                                    </label>
                                    <input type="number" value={phoneNumberFilter}
                                           onChange={(e) => setPhoneNumberFilter(e.target.value)}/>

                                </div>

                            </div>


                        </div>


                    </div>

                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={setRemoveAllFilter}>حذف فیلترها
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={setFilterForApi}>
                        {loadingForBtn ? <LoadingForBtn/> :
                            <>
                                <IoFilter className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>
                                اعمال فیلتر</>}

                    </button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export function RegisterDriver({
                                   setName,
                                   setCodeMely,
                                   setPhoneNumber,
                                   typeCar,
                                   setTypeCar,
                                   setTypeCarID,
                                   typeCarList,
                                   city,
                                   setCity,
                                   setCityID,
                                   cityList,
                                   loadingDropdown,
                                   searchCityShow,
                                   setSearchCityShow
                               }) {

    useEffect(() => {
        setName('')
        setCodeMely('')
        setPhoneNumber('')
        setCity('')
        setCityID('')
        setTypeCar('')
        setSearchCityShow("")
    }, [])

    // Dropdown States & Funcs
    const [showDropdown, setShowDropdown] = useState('')

    function Dropdown(value) {
        if (showDropdown === value)
            setShowDropdown('')
        else setShowDropdown(value)
        setSearchCityShow("")
    }

    function selectCity(value) {
        setCityID(value._id.$oid)
        setCity(value.name)
        setShowDropdown('')
        setSearchCityShow("")

    }

    function selectCar(value) {
        setTypeCarID(value._id.$oid)
        setTypeCar(value.name)
        setShowDropdown('')

    }

    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className='modal-input-container'>
                        <label>نام و نام ‌خانوادگی</label>
                        <input type="text" onChange={(e) => setName(e.target.value)}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className='modal-input-container'>
                        <label>شماره موبایل</label>
                        <input type="number" onChange={(e) => setPhoneNumber(e.target.value)}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className='modal-input-container'>
                        <label>کد ملی</label>
                        <input type="number" onChange={(e) => setCodeMely(e.target.value)}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="modal-dropdown-container">
                        <div className='modal-input-container'>
                            <label>شهر</label>
                            <div className='modal-dropdown-input-container' onClick={() => Dropdown("city")}>
                                <input value={city}/>
                                {showDropdown === "city" ? (<FaChevronUp className='icon'/>) :
                                    (<FaChevronDown className='icon'/>)}
                            </div>
                        </div>
                        {
                            showDropdown === "city" &&
                            <ul className="modal-dropdown-list">
                                <input className='search-dropdown-input' placeholder="جست و جو ..." autoComplete='off'
                                       value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)}/>
                                {loadingDropdown ?
                                    <LoadingForBtn/>
                                    :
                                    cityList.length ?
                                        cityList.map((item, index) =>
                                            <li key={index} onClick={() => selectCity(item)}>{item.name}</li>
                                        ) :
                                        <li>شهری یافت نشد!..</li>
                                }
                            </ul>
                        }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="modal-dropdown-container">
                        <div className='modal-input-container'>
                            <label>نوع ناوگان</label>
                            <div className='modal-dropdown-input-container' onClick={() => Dropdown("car")}>
                                <input value={typeCar}/>
                                {showDropdown === "car" ? (<FaChevronUp className='icon'/>) :
                                    (<FaChevronDown className='icon'/>)}
                            </div>
                        </div>
                        {
                            showDropdown === "car" &&
                            <ul className="modal-dropdown-list">
                                {loadingDropdown ?
                                    <LoadingForBtn/>
                                    :
                                    typeCarList && typeCarList.map((item, index) =>
                                        <li key={index} onClick={() => selectCar(item)}>{item.name}</li>
                                    )}

                            </ul>
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}


export function RegisterFreight({
                                    setName,
                                    setCodeMely,
                                    setPhoneNumber,
                                    setCompanyName,
                                    city,
                                    setCity,
                                    setCityID,
                                    cityList, searchCityShow, setSearchCityShow, loadingDropdown
                                }) {

    useEffect(() => {
        setName('')
        setCodeMely('')
        setPhoneNumber('')
        setCity('')
        setCityID('')
        setSearchCityShow("")
    }, [])

    // Dropdown States & Funcs
    const [showDropdown, setShowDropdown] = useState(false)


    function selectCity(value) {
        setCityID(value._id.$oid)
        setCity(value.name)
        setShowDropdown(false)
        setSearchCityShow("")
    }


    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className='modal-input-container'>
                        <label>نام شرکت</label>
                        <input type="text" onChange={(e) => setCompanyName(e.target.value)}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className='modal-input-container'>
                        <label> نام و نام ‌خانوادگی مالک شرکت</label>
                        <input type="text" onChange={(e) => setName(e.target.value)}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className='modal-input-container'>
                        <label> کد ملی مالک شرکت</label>
                        <input type="number" maxLength={11} onChange={(e) => setCodeMely(e.target.value)}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className='modal-input-container'>
                        <label>شماره موبایل</label>
                        <input type="number" onChange={(e) => setPhoneNumber(e.target.value)}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="modal-dropdown-container">
                        <div className='modal-input-container'>
                            <label>شهر</label>
                            <div className='modal-dropdown-input-container'
                                 onClick={() => setShowDropdown(!showDropdown)}>
                                <input type="text" value={city}/>
                                {showDropdown ? (<FaChevronUp className='icon'/>) :
                                    (<FaChevronDown className='icon'/>)}
                            </div>
                        </div>
                        {
                            showDropdown &&
                            <ul className="modal-dropdown-list">
                                <input className='search-dropdown-input' placeholder="جست و جو ..." autoComplete='off'
                                       value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)}/>
                                {loadingDropdown ?
                                    <LoadingForBtn/>
                                    :
                                    cityList.length ?
                                        cityList.map((item, index) =>
                                            <li key={index} onClick={() => selectCity(item)}>{item.name}</li>
                                        ) :
                                        <li>شهری یافت نشد!..</li>
                                }
                            </ul>
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}


export function RegisterBarOwner({
                                     setName,
                                     setCodeMely,
                                     setPhoneNumber,
                                     typeCar,
                                     setTypeCar,
                                     setTypeCarID,
                                     typeCarList,
                                     city,
                                     setCity,
                                     setCityID,
                                     cityList,
                                     loadingDropdown, searchCityShow, setSearchCityShow
                                 }) {

    useEffect(() => {
        setName('')
        setCodeMely('')
        setPhoneNumber('')
        setCity('')
        setCityID('')
        setTypeCar('')
        setTypeCarID('')
        setSearchCityShow("")
    }, [])

    // Dropdown States & Funcs
    const [showDropdown, setShowDropdown] = useState('')

    function Dropdown(value) {
        if (showDropdown === value)
            setShowDropdown('')
        else setShowDropdown(value)
        setSearchCityShow('')
    }

    function selectCity(value) {
        setCityID(value._id.$oid)
        setCity(value.name)
        setShowDropdown('')
        setSearchCityShow("")
    }

    function selectCar(value) {
        setTypeCarID(value._id.$oid)
        setTypeCar(value.name)
        setShowDropdown('')

    }


    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className='modal-input-container'>
                        <label>نام و نام خانوادگی</label>
                        <input type="text" onChange={(e) => setName(e.target.value)}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className='modal-input-container'>
                        <label>شماره موبایل</label>
                        <input type="number" onChange={(e) => setPhoneNumber(e.target.value)}/>
                    </div>
                </Col>
            </Row><Row>
            <Col xs={12}>
                <div className='modal-input-container'>
                    <label>کد ملی</label>
                    <input type="number" onChange={(e) => setCodeMely(e.target.value)}/>
                </div>
            </Col>
        </Row>

            <Row>
                <Col xs={12}>
                    <div className="modal-dropdown-container">
                        <div className='modal-input-container'>
                            <label>شهر</label>
                            <div className='modal-dropdown-input-container' onClick={() => Dropdown("city")}>
                                <input value={city}/>
                                {showDropdown === "city" ? (<FaChevronUp className='icon'/>) :
                                    (<FaChevronDown className='icon'/>)}
                            </div>
                        </div>
                        {
                            showDropdown === "city" &&
                            <ul className="modal-dropdown-list">
                                <input className='search-dropdown-input' placeholder="جست و جو ..." autoComplete='off'
                                       value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)}/>
                                {loadingDropdown ?
                                    <LoadingForBtn/>
                                    :
                                    cityList.length ?
                                        cityList.map((item, index) =>
                                            <li key={index} onClick={() => selectCity(item)}>{item.name}</li>
                                        ) :
                                        <li>شهری یافت نشد!..</li>
                                }
                            </ul>
                        }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="modal-dropdown-container">
                        <div className='modal-input-container'>
                            <label>نوع ناوگان مورد نظر</label>
                            <div className='modal-dropdown-input-container' onClick={() => Dropdown("car")}>
                                <input value={typeCar}/>
                                {showDropdown === "car" ? (<FaChevronUp className='icon'/>) :
                                    (<FaChevronDown className='icon'/>)}
                            </div>
                        </div>
                        {
                            showDropdown === "car" &&
                            <ul className="modal-dropdown-list">
                                {loadingDropdown ?
                                    <LoadingForBtn/>
                                    :
                                    typeCarList && typeCarList.map((item, index) =>
                                        <li key={index} onClick={() => selectCar(item)}>{item.name}</li>
                                    )}

                            </ul>
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}


export function RegisterCurator({
                                    setName,
                                    setCodeMely,
                                    setPhoneNumber,
                                    typeCar,
                                    setTypeCar,
                                    setTypeCarID,
                                    typeCarList,
                                    city,
                                    setCity,
                                    setCityID,
                                    cityList,
                                    loadingDropdown, searchCityShow, setSearchCityShow
                                }) {

    useEffect(() => {
        setName('')
        setCodeMely('')
        setPhoneNumber('')
        setCity('')
        setCityID('')
        setTypeCar('')
        setTypeCarID('')
        setSearchCityShow("")

    }, [])

    // Dropdown States & Funcs
    const [showDropdown, setShowDropdown] = useState('')

    function Dropdown(value) {
        if (showDropdown === value)
            setShowDropdown('')
        else setShowDropdown(value)
        setSearchCityShow("")

    }

    function selectCity(value) {
        setCityID(value._id.$oid)
        setCity(value.name)
        setShowDropdown('')
        setSearchCityShow("")

    }

    function selectCar(value) {
        setTypeCarID(value._id.$oid)
        setTypeCar(value.name)
        setShowDropdown('')
    }


    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className='modal-input-container'>
                        <label>نام و نام خانوادگی</label>
                        <input type="text" onChange={(e) => setName(e.target.value)}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className='modal-input-container'>
                        <label>شماره موبایل</label>
                        <input type="number" onChange={(e) => setPhoneNumber(e.target.value)}/>
                    </div>
                </Col>
            </Row><Row>
            <Col xs={12}>
                <div className='modal-input-container'>
                    <label>کد ملی</label>
                    <input type="number" onChange={(e) => setCodeMely(e.target.value)}/>
                </div>
            </Col>
        </Row>

            <Row>
                <Col xs={12}>
                    <div className="modal-dropdown-container">
                        <div className='modal-input-container'>
                            <label>شهر</label>
                            <div className='modal-dropdown-input-container' onClick={() => Dropdown("city")}>
                                <input value={city}/>
                                {showDropdown === "city" ? (<FaChevronUp className='icon'/>) :
                                    (<FaChevronDown className='icon'/>)}
                            </div>
                        </div>
                        {
                            showDropdown === "city" &&
                            <ul className="modal-dropdown-list">
                                <input className='search-dropdown-input' placeholder="جست و جو ..." autoComplete='off'
                                       value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)}/>
                                {loadingDropdown ?
                                    <LoadingForBtn/>
                                    :
                                    cityList.length ?
                                        cityList.map((item, index) =>
                                            <li key={index} onClick={() => selectCity(item)}>{item.name}</li>
                                        ) :
                                        <li>شهری یافت نشد!..</li>
                                }
                            </ul>
                        }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="modal-dropdown-container">
                        <div className='modal-input-container'>
                            <label>نوع ناوگان</label>
                            <div className='modal-dropdown-input-container' onClick={() => Dropdown("car")}>
                                <input value={typeCar}/>
                                {showDropdown === "car" ? (<FaChevronUp className='icon'/>) :
                                    (<FaChevronDown className='icon'/>)}
                            </div>
                        </div>
                        {
                            showDropdown === "car" &&
                            <ul className="modal-dropdown-list">
                                {loadingDropdown ?
                                    <LoadingForBtn/>
                                    :
                                    typeCarList && typeCarList.map((item, index) =>
                                        <li key={index} onClick={() => selectCar(item)}>{item.name}</li>
                                    )}

                            </ul>
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}