import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {FaDumpsterFire, FaEdit, FaPhone, FaUserTie} from 'react-icons/fa';
import axios from "axios";
import {handleErrors, urlVariable} from "../../../Axios/Axios";
import LoadingForBtn from '../../../Components/Loading/LoadingForBtn/LoadingForBtn';
import {
    FaUser, FaUserCheck, FaUserLargeSlash, FaCircleXmark, FaTrash, FaChevronDown, FaUserPlus, FaChevronUp
} from "react-icons/fa6";
import {FaCheckCircle} from "react-icons/fa";
import ToastComp from '../../../Components/Toast/ToastComp';
import {Link} from "react-router-dom";


function PersonalInformation({user, setRefreshData, refreshData}) {

    // Toast State
    const [showToast, setShowToast] = useState(false)
    const [toastTitle, setToastTitle] = useState('')
    const [toastBody, setToastBody] = useState('')
    const [toastIcon, setToastIcon] = useState()
    const [toastColor, setToastColor] = useState('')

    const [loading, setLoading] = useState(false);
    const [loadingDropdown, setLoadingDropdown] = useState(false);

    const [showDropdown, setShowDropdown] = useState('')


    // just use for get city and car list
    const [cityList, setCityList] = useState([])
    const [searchCityShow, setSearchCityShow] = useState("")
    const [searchCityApi, setSearchCityApi] = useState("")
    const [typeCarList, setTypeCarList] = useState()


    const [userID, setUserID] = useState("")
    const [status, setStatus] = useState({
        show: "",
        title: "",
        realData: "",
    })
    const [userType, setUserType] = useState("");
    const [name, setName] = useState("");
    const [codeMely, setCodeMely] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneStatus, setPhoneStatus] = useState("")
    const [city, setCity] = useState("");
    const [carType, setCarType] = useState("");
    const [registeredIn, setRegisteredIn] = useState("");
    const [lastLogin, setLastLogin] = useState("");
    const [lastModified, setLastModified] = useState("");
    const [fieldOfActivity, setFieldOfActivity] = useState("")
    const [companyName, setCompanyName] = useState("")

    const [cityID, setCityID] = useState("")
    const [carTypeID, setCarTypeID] = useState("")

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ' ';
        return Intl.DateTimeFormat('fa-IR').format(timestamp)
    };

    useEffect(() => {

        setUserID(user._id?.$oid)
        setName(user.name || user.companyOwnerName);
        if (user.status) {
            if (user.status === "profile_completed")
                setStatus({
                    show: "اطلاعات تکمیل و تایید شده",
                    title: user.status,
                    realData: user.status,
                })
            if (user.status === "profile_not_completed")
                setStatus({
                    show: "اطلاعات تکمیل نشده",
                    title: user.status,
                    realData: user.status,
                })
            if (user.status === "deleted")
                setStatus({
                    show: "کاربر حذف شده",
                    title: user.status,
                    realData: user.status,
                })
        } else setStatus({show: "-", title: "-", realData: "-"})
        setUserType(user.userType)
        setCodeMely(user.codeMely || user.codemely)
        setPhoneNumber(user.phoneNumber)
        setPhoneStatus(user.phoneStatus)
        setCity(user.city ? user.city[user.city?.length - 1]?.name : "-")
        setCarType(user.carType ? user.carType[user.carType?.length - 1]?.name : "-")
        setRegisteredIn(user.registeredIn)
        setLastLogin(user.lastLogin)
        setLastModified(user.lastModified)
        setFieldOfActivity(user.fieldOfActivity ?? "")
        setCompanyName(user.companyName ?? "")
    }, [user]);

    useEffect(() => {    // get city list api

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/groups/city`, {
            params: {
                search: searchCityApi
            }
        })
            .then((res) => {
                setCityList(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                setLoadingDropdown(true)
            })

    }, [searchCityApi])


    useEffect(() => {    // get car list api

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/car/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        })
            .then((res) => {
                setTypeCarList(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                setLoadingDropdown(true)
            })

    }, [])


    useEffect(() => {

        const delayedSetData = setTimeout(() => {
            setSearchCityApi(searchCityShow)
        }, 800)

        return () => clearTimeout(delayedSetData);

    }, [searchCityShow]);


    function Dropdown(value) {
        if (showDropdown === value)
            setShowDropdown('')
        else setShowDropdown(value)
        setSearchCityApi("")
        setSearchCityShow("")
    }

    function selectCity(value) {
        setCityID(value._id.$oid)
        setCity(value.name)
        setShowDropdown('')
        setSearchCityApi("")
        setSearchCityShow("")
    }

    function selectCar(value) {
        setCarTypeID(value._id.$oid)
        setCarType(value.name)
        setShowDropdown('')
        setSearchCityApi("")
        setSearchCityShow("")
    }

    function selectStatus(show, title) {
        setStatus({
            show: show,
            title: title
        })
        setShowDropdown("")
    }


    function editForApi() {

        let info = {}

        if (userType === "driver") {
            info = {
                user_id: userID,
                name: name,
                carType: carTypeID,
                codeMely: codeMely,
                city: cityID,
                status: status.title
            }
        }

        if (userType === "freight") {
            info = {
                user_id: userID,
                phoneNumber: phoneNumber,
                companyownername: name,
                companyname: companyName,
                codeMely: codeMely,
                city: cityID,
                status: status.title
            }
        }

        if (userType === "Curator" || userType === "cargoOwners") {
            info = {
                user_id: userID,
                name: name,
                carType: carTypeID,
                codeMely: codeMely,
                city: cityID,
                status: status.title
            }
        }

        if (phoneNumber.length === 11) {
            setLoading(true)
            axios.put(`${urlVariable}/admin/user/edit`, info, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
                }
            }).then((res) => {
                setShowToast(true)
                setToastTitle("عملیات موفق")
                setToastBody(res.data.msg)
                setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
                setToastColor('var(--color-1)')
                setLoading(false)
                setRefreshData(!refreshData)
            }).catch((err) => {
                handleErrors(err)
                if (err.message === "Network Error") {
                    setShowToast(true)
                    setToastTitle('عملیات ناموفق')
                    setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoading(false)
                }
                if (err.response && err.response.status <= 499) {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody(err.response.data.msg)
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoading(false)
                } else {
                    if (err.response && err.response.status >= 500) {
                        setShowToast(true)
                        setToastTitle("عملیات ناموفق")
                        setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                        setToastColor('var(--danger-color)')
                        setLoading(false)
                        setToastBody("خطای رخ در سرور داده لطفا دوباره تلاش کتید!..")
                    } else {
                        setShowToast(true)
                        setToastTitle("عملیات ناموفق")
                        setToastBody("خطا در بارگیری اطلاعات")
                        setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                        setToastColor('var(--danger-color)')
                        setLoading(false)
                    }
                }

            })
        }else  {

            setShowToast(true)
            setToastTitle("عملیات ناموفق")
            setToastBody("شماره را به صورت صحیح وارد کنید")
            setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
            setToastColor('var(--danger-color)')
            setLoading(false)

        }

    }


    return (

        <>

            {/* Toast */}
            <ToastComp
                title={toastTitle}
                body={toastBody}
                icon={toastIcon}
                onShowToast={setShowToast}
                showToastValue={showToast}
                toastColor={toastColor}
            />

            <div className="container" style={{marginTop: "32px"}}>

                <div style={{textAlign: "right"}}>
                    <Row>
                        <Col>
                            <div className="modal-dropdown-container">
                                <div className='modal-input-container'
                                     onClick={() => Dropdown("status")}
                                >
                                    <label style={{textAlign: "right"}}>
                                        وضعیت
                                    </label>
                                    <div className='modal-dropdown-input-container'>
                                        <input
                                            value={status.show}
                                        />
                                        {status.realData === "profile_completed" ?
                                            null
                                            :
                                            showDropdown === "status" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                    </div>
                                </div>

                                {status.realData === "profile_completed" ?
                                    null
                                    :
                                    showDropdown === "status" ?
                                        <ul className="modal-dropdown-list"
                                            style={{textAlign: "right"}}>
                                            <li onClick={() => selectStatus("تایید شده", "profile_completed")}>
                                                تایید شده
                                            </li>
                                        </ul>
                                        : null}
                            </div>

                        </Col>

                        <Col>
                            <div className="modal-dropdown-container">
                                <div className='modal-input-container'
                                    // onClick={() => Dropdown("status")}
                                >
                                    <label style={{textAlign: "right"}}>
                                        نقش کاربر
                                    </label>
                                    <div className='modal-dropdown-input-container'>
                                        <input
                                            value={
                                                (userType === "driver" && "راننده") ||
                                                (userType === "freight" && "باربری") ||
                                                (userType === "Curator" && "متصدی") ||
                                                (userType === "cargoOwners" && "صاحب بار") || "-"
                                            }/>
                                    </div>
                                </div>

                            </div>

                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <div className='modal-input-container'>
                                <label>نام و نام خانوادگی</label>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                            </div>
                        </Col>
                        <Col>
                            <div className='modal-input-container'>
                                <label>کد ملی</label>
                                <input type="number" value={codeMely} onChange={(e) => setCodeMely(e.target.value)}/>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <div className='modal-input-container'>
                                <label>شماره موبایل</label>
                                <input type="number" value={phoneNumber}
                                       onChange={(e) => setPhoneNumber(e.target.value)}/>
                            </div>
                        </Col>
                        <Col>
                            <div className='modal-input-container'>
                                <label>وضعیت شماره موبایل</label>
                                <input type="text"
                                       value={phoneStatus ? phoneStatus === "Confirmed" ? "تاییده شد" : "تایید نشده" : "-"}/>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="modal-dropdown-container">
                                <div className='modal-input-container'>
                                    <label>شهر</label>
                                    <div className='modal-dropdown-input-container'
                                         onClick={() => Dropdown("city")}
                                    >
                                        <input value={city}/>
                                        {showDropdown === "city" ? (<FaChevronUp className='icon'/>) : (
                                            <FaChevronDown className='icon'/>)}
                                    </div>
                                </div>
                                {showDropdown === "city" && <ul className="modal-dropdown-list">
                                    <input className='search-dropdown-input' placeholder="جست و جو ..."
                                           autoComplete='off'
                                           value={searchCityShow}
                                           onChange={(e) => setSearchCityShow(e.target.value)}/>
                                    {loadingDropdown ? <LoadingForBtn/> : !cityList.length ?
                                        <li>شهری یافت نشد!..</li> : cityList.map((item, index) => <li key={index}
                                                                                                      onClick={() => selectCity(item)}>{item.name}</li>)}

                                </ul>}
                            </div>
                        </Col>
                        <Col>
                            <div className="modal-dropdown-container">
                                <div className='modal-input-container'>
                                    <label>نوع ناوگان</label>
                                    <div className='modal-dropdown-input-container'
                                         onClick={() => Dropdown("car")}
                                    >
                                        <input value={carType}/>
                                        {showDropdown === "car" ? (<FaChevronUp className='icon'/>) : (
                                            <FaChevronDown className='icon'/>)}
                                    </div>
                                </div>
                                {showDropdown === "car" && <ul className="modal-dropdown-list">
                                    {loadingDropdown ?
                                        <LoadingForBtn/> : typeCarList && typeCarList.map((item, index) => <li
                                        key={index} onClick={() => selectCar(item)}>{item.name}</li>)}

                                </ul>}
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <div className='modal-input-container'>
                                <label>ایجاد شده در</label>
                                <input type="text" value={formatTimestamp(registeredIn)}/>
                            </div>
                        </Col>
                        <Col>
                            <div className='modal-input-container'>
                                <label>آخرین ورود</label>
                                <input type="text" value={formatTimestamp(lastLogin)}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='modal-input-container'>
                                <label>آخرین ویرایش</label>
                                <input type="text" value={formatTimestamp(lastModified)}/>
                            </div>
                        </Col>
                        <Col>
                            <div className='modal-input-container'>
                                <label>ذخیره بار</label>
                                <input type="text"/>
                            </div>
                        </Col>
                    </Row>
                    {(fieldOfActivity || companyName) && <Row>
                        <Col>
                            <div className='modal-input-container'>
                                <label>
                                    {fieldOfActivity ? "زمینه فعالیت" : "نام شرکت"}

                                </label>
                                <input type="text" value={fieldOfActivity || companyName}
                                       onChange={(e) => {
                                           fieldOfActivity ?
                                               setFieldOfActivity(e.target.value)
                                               :
                                               setCompanyName(e.target.value)
                                       }}
                                />
                            </div>
                        </Col>

                    </Row>}
                </div>


                <div style={{marginTop: "16px", display: "flex", justifyContent: "end", gap: "16px"}}>

                    <button className='modal-btn modal-green-btn' onClick={editForApi}>
                        {loading ?
                            <LoadingForBtn/>
                            :
                            <>
                                <FaEdit className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>
                                ویرایش
                            </>
                        }

                    </button>

                    <Link to="/pannel/users/all" className='modal-btn modal-cancle-btn link'>
                        بازگشت

                    </Link>
                </div>

            </div>
        </>

    )

}


export default PersonalInformation;